
import axios from 'axios';
import { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getRaceBackendAddress } from '../../../../../../../Functions';
import MessageStore from "../../../../../../../managers/ConsoleMessageManager";
import { useDropzone } from 'react-dropzone';

const InteractiveCompetitorImages = ({socket}) => {

    const config = useSelector(state => state.config);

    const useCompetitorImages = config.useCompetitorImages;

    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [loadedImages, setLoadedImages] = useState([]);
    const [errorMessage, setErrorMessage] = useState(null);

    const getImages = () => {
        const userId = localStorage.getItem("userId");
        if (!userId) {
            return;
        }
        axios.post(getRaceBackendAddress() + '/getImages', { userId })
            .then(response => setLoadedImages(response.data.images))
            .catch(error => console.error(error));
    };

    useEffect(() => {
        if (useCompetitorImages) {
            getImages();

        }
    }, [useCompetitorImages]);

    const setNotUploaded = () => {
        socket.emit("removeImages", (callback) => {
            MessageStore.addMessage(callback);
        });
    }

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];

        // Check if it is a .zip
        if (file.type !== 'application/zip') {
            setErrorMessage('Error: Only .zip files are accepted');
            return;
        }

        const formData = new FormData();
        formData.append('userId', localStorage.getItem("userId").toString());
        formData.append('file', file);

        setUploading(true);

        axios.post(getRaceBackendAddress() + '/upload', formData, {
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setUploadProgress(percentCompleted);
            }
        })
        .then(response => {
            MessageStore.addMessage(response.data);
            setUploading(false);
            setUploadProgress(0);
            getImages();
            socket.emit("update", {what: 5, data: ["competitorImagesUploaded", true]}, (callback) => {
                MessageStore.addMessage(callback);
            });
        })
        .catch(error => {
            console.error('Error:', error);
            setUploading(false);
            setUploadProgress(0);
        });
        setErrorMessage(null);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: '.zip'
    });

    const generateContent = () => {
        if (config?.competitorImagesUploaded) {
            return (
                <div className="competitor_images_loaded">
                    <p>{`${loadedImages.length} Images loaded`}</p>
                    <div className="competitor_images_display">
                    {loadedImages.map((image, index) => (
                        <div className="competitor_images_obj" key={`img_obj_${index}`}>
                            <div className="competitor_images_obj_img">
                                <img src={image?.url} alt="" loading="lazy"/>
                            </div>
                            <p>{image?.name}</p>
                        </div>
                    ))}
                    </div>
                    <div className="competitor_images_loaded_buttons">
                        <button onClick={() => setNotUploaded()}>Remove current</button>
                    </div>
                </div>
            );
        }
        if (uploading) {
            return (
                <div className="competitor-images-dropzone">
                    <p>Please wait for images to upload</p>
                    <progress value={uploadProgress} max="100" />
                    <h4>{uploadProgress} %</h4>
                </div>
            );
        }
        return (
            <div className="competitor-images-dropzone"{...getRootProps()}>
                <input {...getInputProps()} />
                <p>Drag 'n' drop .zip files here, or click to select .zip files</p>
                {errorMessage && <span>{errorMessage}</span>}
            </div>
        );
    }

    return (
        <div className="interactive-screen-main-competitor-images">
            { generateContent() }
        </div>
    );
};

export default InteractiveCompetitorImages;