import VerticalScoreboard from "../../components/VerticalScoreboard/VerticalScoreboard"
import WeatherWidget from "../../components/WeatherWidget/WeatherWidget";
import HorizontalScoreboard from "../../components/HorizontalScoreboard/HorizontalScoreboard";
import LeaderWidget from "../../components/LeaderWidget/LeaderWidget";
import FastestLapWidget from "../../components/FastestLapWidget/FastestLapWidget";
import TimerWidget from "../../components/TimerWidget/TimerWidget";
import RaceWinnerWidget from "../../components/RaceWinner/RaceWinner";
import ReplayWidget from "../../components/Replay/ReplayWidget";
import TrackPlan from "../../components/TrackPlan/TrackPlan";
import ResultsByClass from "../../components/ResultsByClass/ResultsByClass";
import StartGridGraphic from "../../components/StartGrid/StartGridGraphic";
import Battles from "../../components/Battles/Battles";
import Announcement from "../../components/Announcer/Announcer";
import RaceResults from "../../components/RaceResults/RaceResults";

import { CSSTransition } from "react-transition-group";
import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import { useParams } from "react-router-dom";

import "./Graphics.css"

import { getRaceBackendAddress } from "../../Functions";

import { AnimatePresence } from "framer-motion";

const transitionConfig = {
    timeout: 400,
    classNames: "transition",
    unmountOnExit: true
}

let socket;

const GRAPHICS_SERVER_ADDRESS = getRaceBackendAddress();

const Graphics = React.memo(() => {

    const { hash } = useParams();
    if (!socket) {
        socket = io.connect(GRAPHICS_SERVER_ADDRESS, {
            query: {
                userId: decodeURIComponent(hash),
                userName: "",
                userType: "Graphics Panel",
            }
        });
    }


    const [data, setData] = useState({});
    const [config, setConfig] = useState({});
    const [generalImage, setGeneralImage] = useState("");
    const raceFlag = data?.data?.raceDetails?.flag;

    useEffect(() => {
        socket.on("getApi", setData);
        socket.on("getConfig", (data) => {
            setConfig(data);
        });
    }, [socket]);

    return (

        <div className="app__graphics">
            {
                /* Weather board start */
                <AnimatePresence>
                    {
                        config && config?.buttons?.weather?.show &&
                        <WeatherWidget config={config} />
                    }
                </AnimatePresence>
            }
            {
                /* Battles start */
                <AnimatePresence>
                    {
                        config && config?.buttons?.battles?.show &&
                        <Battles config={config} data={data} />
                    }
                </AnimatePresence>
            }
             {
                /* Results start */
                <AnimatePresence>
                    {
                        config && config?.buttons?.raceResults?.show &&
                        <RaceResults config={config} data={data} />
                    }
                </AnimatePresence>
            }
            {
                /* Announcer start */
                <AnimatePresence>
                    {
                        config && config?.buttons?.announcer?.show &&
                        <Announcement config={config} />
                    }
                </AnimatePresence>
            }
            {
                /* Start grid board start */
                <AnimatePresence>
                    {
                        config && config?.buttons?.startGrid?.show &&
                        <StartGridGraphic data={data} config={config} />
                    }
                </AnimatePresence>
            }
            {
                /* Vertical scoreboard */
                <AnimatePresence>
                    {
                        config && config?.buttons?.verticalScoreboard?.show &&
                        raceFlag && raceFlag !== "None" &&
                        <VerticalScoreboard data={data} config={config} image={generalImage}/>
                    }
                </AnimatePresence>


            }
            {
                /* Horizontal scoreboard start */
                <CSSTransition
                    in={config?.buttons?.horizontalScoreboard?.show}
                    {...transitionConfig}
                    >
                    {
                        (raceFlag && raceFlag !== "None") ?
                        <HorizontalScoreboard data={data} config={config} image={generalImage}/>
                        :
                        <></>
                    }
                </CSSTransition>
            }
            {
                /* ResultsByClass scoreboard start */
                <CSSTransition
                    in={config?.buttons?.resultsByClass.show}
                    {...transitionConfig}
                    >
                    <ResultsByClass data={data} config={config} image={generalImage}/>
                </CSSTransition>
            }
            {
                /* LeaderWidget start */
                <CSSTransition
                    in={config?.buttons?.raceLeader?.show}
                    {...transitionConfig}
                    >
                    <LeaderWidget data={data?.data?.raceLeader} config={config} fromSecond={data?.data?.topTenCompetitors[1]?.diff}/>
                </CSSTransition>
            }
            {
                /* FastestLapWidget start */
                <CSSTransition
                    in={config?.buttons?.fastestLap?.show}
                    {...transitionConfig}
                    >
                    <FastestLapWidget data={data?.data?.competitorWithFastestLap} config={config}/>
                </CSSTransition>
            }
            {
                /* TimerWidget start */
                <AnimatePresence>
                    {config?.buttons?.timer?.show &&
                        <TimerWidget data={data} config={config}/>
                    }
                </AnimatePresence>
            }
            {
                /* RaceWinnerWidget start */
                <CSSTransition
                    in={config?.buttons?.winner?.show}
                    {...transitionConfig}
                    >
                    <RaceWinnerWidget data={data} config={config} winner={data?.data?.raceLeader}/>
                </CSSTransition>
            }
            {
                /* ReplayWidget start */
                <CSSTransition
                    in={config?.buttons?.replay?.show}
                    {...transitionConfig}
                    >
                    <ReplayWidget config={config} />
                </CSSTransition>
            }
            {
                /* TrackPlan start */
                <CSSTransition
                    in={config?.buttons?.trackPlan?.show}
                    {...transitionConfig}
                    >
                    <TrackPlan config={config}/>
                </CSSTransition>
            }

        </div>
    );
});
export default Graphics;