import { useSelector } from "react-redux";
import "./SelectGraphics.scss";
import { selectorGraphicsDesc, designData } from "../../../../../../../config/fields";


const GraphicsSelector = ({ socket }) => {

    const config = useSelector(state => state.config);
    const { buttons, style } = config;

    const handleButtonClick = (button) => {
        socket.emit("update", {
            what: 6,    // button
            data: [
                button[0],
                "enabled",
                true
            ]
        }, (callback) => {
            //MessageStore.addMessage(callback);
        });
    };

    return (
        <div className="selectGraphics-component">
            <header>
                <span>Hidden Graphics</span>
                <p>Here are all unused, but selectable graphics elements. Click on the graphic to activate it and it will show up in your enabled graphics row</p>
            </header>
            <div className="interactive-buttons-all">
            {
                buttons &&
                Object.entries(buttons).filter((button) => !button[1].enabled ).map((button, index) => {
                    const designs = Object.entries(designData).reduce((acc, [key, value]) => {
                        if (value.graphics.includes(button[0])) {
                            acc.push(key);
                        }
                        return acc;
                    }, []);

                    return (
                        <button
                            className="interactive-button-disabled"
                            onClick={() => {
                                if (designs.length !== 0) {
                                    handleButtonClick(button);
                                }
                            }}
                            key={`available-graphics-bttn-${index}`}
                        >
                            <h3>{button[1].name}</h3>
                            <p>{selectorGraphicsDesc[button[0]]?.description}</p>
                            <hr />
                            <p
                                style={{marginBottom: "5px"}}
                            >Available in designs</p>
                            {
                                designs.length !== 0 ? designs.map((design, index) => {
                                    return (
                                        <span key={`design-${index}`}>{designData[design].name}</span>
                                    );
                                })
                                :
                                <span
                                    style={{
                                        width: "80%",
                                        background: "repeating-linear-gradient(45deg, #ed4747, #ed4747 10px, #db2c2c 10px, #db2c2c 20px)",
                                        border: "1px solid transparent"
                                    }}
                                >Under construction</span>
                            }
                        </button>
                    );
                })
            }
            </div>
        </div>
    );
}

export default GraphicsSelector;