
export const Confirmation = ({ message, callback }) => {

    return (
        <div className="confirmator">
            <div className="confirmator-popout">
                <p>{message}</p>
                <div className="confirmator-buttons">
                    <button onClick={() => callback(true)}>Yes</button>
                    <button onClick={() => callback(false)}>No</button>
                </div>
            </div>
        </div>
    );
};