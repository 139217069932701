

// Used for graphics to display different classes.
// Changing this array will result of different colors being shown.
export const classNameColors = [
    "#00aca0",
    "#023a90",
    "#e9679e",
    "#facd06",
    "#f58300",
    "#d9030b"
];

export const classNameColorsM1 = [
    "#1ed4a9",
    "#3640cf",
    "#e9679e",
    "#facd06",
    "#f58300",
    "#d9030b"
];

const makeBackground = {
    light: "#f4f4f4",
    dark: "#050505"
}

export const makeMap = {
    "KTM": {img: "ktm.svg", background: makeBackground.dark},
    "SUZUKI": {img: "suzuki.svg", background: makeBackground.light},
    "HONDA": {img: "honda.svg", background:  makeBackground.dark},
    "KAWASAKI": {img: "kawasaki.jpg", background:  makeBackground.dark},
    "YAMAHA": {img: "yamaha.svg", background:  makeBackground.dark},
    "HUSQVARNA": {img: "husqvarna.png", background:  makeBackground.dark},
    "GAS GAS": {img: "gasgas.png", background:  makeBackground.dark},
    "HUSABERG": {img: "husaberg.png", background: makeBackground.dark}
};

export const colorSelectorDummyData = () => {
    const data = {
        remainingCompetitors: [],
        topTenCompetitors: [
            {position: 1, nr: 2, state: "NL", firstname: "AL", lastname: "HEATON", status: "+", joker: true, class: "X2"},
            {position: 2, nr: 3, state: "LT", firstname: "TE", lastname: "LATA", status: "-", joker: true, class: "X2"},
            {position: 3, nr: 44, state: "EE", firstname: "AF", lastname: "KARLITO", status: "=", joker: false, class: "X2"},
            {position: 4, nr: 65, state: "US", firstname: "OO", lastname: "JOHAN", status: "=", joker: true, class: "X2", best_lap_time: "01:45:23"},
            {position: 5, nr: 777, state: "GE", firstname: "ME", lastname: "STRAUSS", status: "+", joker: false, class: "X2"},
            {position: 6, nr: 85, state: "NL", firstname: "NI", lastname: "HAARLAN", status: "-", joker: false, class: "X2"},
            {position: 7, nr: 30, state: "NL", firstname: "JO", lastname: "LEYFOLD", status: "-", joker: false, class: "X2"},
            {position: 8, nr: 4, state: "FR", firstname: "AR", lastname: "BONERO", status: "=", joker: true, class: "X2"},
        ],
        raceDetails: {flag: "Green", race_type_id: 2, name: "Test color race"},
        raceTime: {left_time: "03:55:46", elapsed_time: "10:05:13"},
        raceTimer: {data: "03:55:46", name: "TO GO "}
    };
    data.allCompetitors = data.topTenCompetitors;
    data.competitorWithFastestLap = data.topTenCompetitors[3];
    data.competitorsByClass = {X2: data.topTenCompetitors};
    return {data: data};
};
