import { IoIosWarning } from "react-icons/io";
import { useSelector } from "react-redux";

// Configuration on how to display data in table.
const order = ["status", "position", "nr", "competitor", "class", "team", "lap", "gap", "diff"];
const translatedOrder = ["status", "pos.", "start no.", "competitor", "class", "team/club", "laps", "gap", "diff"];
const translatedOrderWidths = [5,5,6,25,17,17,5,10,10];

const InteractiveLiveResults = () => {

    const data = useSelector(state => state.data?.data?.allCompetitors);

    const ret = {
        nodata:
            <div
            className="interactive-screen-main-live-results-nodata"
            style={{backgroundImage: "url(/svg/bubbles.svg)"}}
        >
            <p><IoIosWarning color="#f7d00a" size={"25px"}/>Awaiting competitor data</p>
        </div>,
        ok:
        <div className="interactive-screen-main-live-results">
            <div className="interactive-screen-main-live-results-legend">
                { translatedOrder.map((item, index) => <li style={{width: `${translatedOrderWidths[index]}%`}} key={`${index}_legend_order`}>{item}</li>)}
            </div>
            <div className="interactive-screen-main-live-results-data">
                {
                    data?.map((competitor, index) =>
                        <div className="interactive-screen-main-live-results-data-row" style={{background: index % 2 === 0 ? "#151f31" : "#19243b"}} key={`${index}_competitor`}>
                            { order.map((item, index2) => <p key={`res_item_${index}_${index2}`} style={{width: `${translatedOrderWidths[index2]}%`}}>{competitor?.[item]}</p> )}
                        </div>
                    )
                }
            </div>
        </div>
    };

    return (!data || Object.keys(data).length === 0) ? ret.nodata : ret.ok;
};

export default InteractiveLiveResults;