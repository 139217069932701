
import { useEffect, useState } from "react";
import MessageStore from "../../../../../../../managers/ConsoleMessageManager"
import PopoutManager from "../../../../../../../managers/PopoutManager";
import { useSelector } from "react-redux";

// Icons
import { MdOutlineSearchOff } from "react-icons/md";

const InteractiveDesignSave = ({data, socket}) => {
    const saveDesign = () => {
        socket.emit("design", {what: 1, data: {
            allowOthers: document.getElementById("allow_other_checkbox").checked,
            designName: document.getElementById("design_name").value,
            designData: data
        }}, (callback) => MessageStore.addMessage(callback));
        PopoutManager.removeWindow(`graphicsDesignSave`);
    };
    return (
        <div className="popout_design_save">
            <p>Choose a name for your design</p>
            <input type="text" maxLength={25} required placeholder="Enter name" id="design_name"/>
            <div className="popout_design_allow_others">
                <span>Allow other users to use this design</span>
                <input type="checkbox" id="allow_other_checkbox"/>
            </div>
            <button onClick={() => saveDesign()}>Save</button>
        </div>
    );
};

const ColorDesignItem = ({design, index, cb, selectedDesign}) => {
    return (
        <div
            className="interactive-screen-main-all-designs-list-item" key={`design_item_all_${index}`}
            onClick={() => cb()}
            style={{background: selectedDesign?.id === design?.id ? "#3446eb" : "#19243b"}}
        >
            <div className="interactive-screen-main-all-designs-list-item-cols">
            {
                Object.entries(design?.data)?.slice(0, 5).map(([key, designSection]) =>
                    Object.values(designSection).map((colorObject) =>
                        <div className="design_col_element" key={"col_elem_"+colorObject.name+design?.uid} style={{background: colorObject?.color}}/>
                    )
                )
            }
            </div>
            <p>{design?.name}</p>
            <span>{`Created by ${design?.creator}`}</span>
        </div>
    );
};

const InteractiveMyDesigns = ({socket}) => {

    const config = useSelector(state => state.config);

    const [myDesigns, setMyDesigns] = useState([]);
    const [allDesigns, setAllDesigns] = useState([]);
    const [selectedDesign, setSelectedDesign] = useState(null);

    const getDesigns = async (which) => {
        await socket.emit("design", {what: which}, (callback) => {
            if (callback.status === 200) {
                if (which === 2) {
                    setMyDesigns(callback.data);
                } else {
                    setAllDesigns(callback.data);
                }
            }
        });
    };

    useEffect(() => {
        getDesigns(2); getDesigns(3);
    }, []);
    const saveDesign = () => {
        const designs = {};
        Object.entries(config?.buttons)?.forEach(([key, value]) => {
            designs[key] = value?.colors;
        });
        PopoutManager.addWindow({
            id: `graphicsDesignSave`,
            name: `Save new design`,
            el: <InteractiveDesignSave socket={socket} data={designs}/>,
            size: {w: 350, h: 150}
        });
    };
    const deleteDesign = () => {
        // TODO: finish this?
    };
    const applyDesign = () => {
        if (!selectedDesign) return;
        socket.emit("update", {what: 9, data: selectedDesign?.data}, (callback) => {
            MessageStore.addMessage(callback);
        });
    }


    return (
        <div className="interactive-screen-main-my-designs">
            <div className="interactive-screen-main-my-saved-designs">
                <header>My designs</header>
                <div className="interactive-screen-main-my-saved-designs-list">
                {
                    myDesigns?.length ?
                    <>
                    {
                        myDesigns.map((design, index) =>
                        <ColorDesignItem design={design} key={"design_item_"+index} index={index} cb={() => setSelectedDesign(design)} selectedDesign={selectedDesign}/>
                        )
                    }
                    </>
                    :
                    <div className="no-designs-placeholder">
                        <MdOutlineSearchOff size={"30%"}/>
                        <p>No designs found :( Save a design to display</p>
                        <button>Reload</button>
                    </div>
                }
                </div>
                <div className="interactive-screen-main-my-saved-designs-buttons">
                    <button onClick={() => {saveDesign(); getDesigns(2); getDesigns(3);}}>Save current design</button>
                    <button>Delete selected design</button>
                    <button onClick={() => applyDesign()}>Use selected design</button>
                </div>
            </div>
            <div className="interactive-screen-main-all-designs">
                <header>Public designs</header>
                <div className="interactive-screen-main-all-designs-list">
                {
                    allDesigns?.length ?
                    <>
                    {
                        allDesigns.map((design, index) =>
                        <ColorDesignItem design={design} key={"design_item2_"+index} index={index} cb={() => setSelectedDesign(design)} selectedDesign={selectedDesign}/>
                        )
                    }
                    </>
                    :
                    <div className="no-designs-placeholder">
                        <MdOutlineSearchOff size={"30%"}/>
                        <p>No user designs found :( Save a public design to display</p>
                        <button>Reload</button>
                    </div>
                }
                </div>
            </div>
        </div>
    );
};

export default InteractiveMyDesigns;