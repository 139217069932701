
import DesignItem from "./DesignItem";
import MessageStore from "../../../../../../../managers/ConsoleMessageManager";
import { useSelector } from "react-redux";

import v2Image from "../../resources/V2.png";
import kartingImage from "../../resources/Karting.png";
import motoImage from "../../resources/Moto.png";

const createDesignElement = (name, description, tags, designImage, styleName) => ({
    name,
    description,
    tags,
    designImage,
    styleName
});

const designTags = {
    jokerSupport: { name: "Joker lap", background: "#faca46", color: "black" },
    multiDriver: { name: "Supports sidecars", background: "#bf4366", color: "white" },
    sponsorSupper: { name: "Supports sponsors", background: "#ff7da9", color: "white" },
    trackSupport: { name: "Supports track display", background: "#6642f5", color: "white" },
    allGraphics: { name: "Full graphics", background: "#eb2a5a", color: "white" },
    animations: { name: "Animations", background: "#3172cc", color: "white" },
    startGrid: { name: "Supports start grid", background: "#bf5841", color: "white" },
    battles: { name: "Battles", background: "#2b364a", color: "white" }
};


// Configuration for all available styles
const designElements = [
    createDesignElement(
        "Original",
        `Our first design for auto or moto events. This design includes most existing and more features +
        animations, such as battles and announcer`,
        [
            designTags.jokerSupport,
            designTags.trackSupport,
            designTags.animations,
            designTags.startGrid,
            designTags.battles
        ],
        "v2_design",
        "v2"
    ),
    createDesignElement(
        "Karting",
        `Graphics design made specific for karting. Does not support sidecar functionality, joker lap and only suppports single grid
        style and no Horizontal scoreboard.`,
        [
            designTags.animations,
            designTags.startGrid,
            designTags.battles
        ],
        "k1_design",
        "k1"
    ),
    createDesignElement(
        "Motocross",
        `Design made for motocross as it supports sidecar functionality and Horizintal scoreboard as it's main way of displaying results.`,
        [
            designTags.multiDriver,
            designTags.animations,
            designTags.startGrid,
        ],
        "m1_design",
        "m1"
    )
];

const InteractiveGraphicsDesign = (props) => {

    const config = useSelector(state => state.config);

    const setSelectedDesign = (design) => {
        props.socket.emit("update", {what: 5, data: ["style", design]}, (response) => {
            MessageStore.addMessage(response);
        });
    };

    const currentDesign = config?.style;

    return (
        <div className="interactive-screen-main-graphics-design">
            { designElements.map((design, index) =>
                <DesignItem key={`design_elem_${index}`} design={design} currentDesign={currentDesign} setSelectedDesign={setSelectedDesign} index={index} max={designElements.length}/>
            )}
        </div>
    );
};

export default InteractiveGraphicsDesign;