
class Timestamps {
    constructor() {
        this.listeners = [];
        this.timestamps = [];
    }
    addTimestamp(timestamp) {
        if (this.timestamps.length >= 10) {
            this.timestamps.shift();
        }
        this.timestamps.push(timestamp);
        this.notifyListeners();
    }
    getTimestamps() {
        return this.timestamps;
    }
    addListener(listener) {
        this.listeners.push(listener);
    }
    removeListener(listenerToRemove) {
        this.listeners = this.listeners.filter(listener => listener !== listenerToRemove);
    }
    notifyListeners() {
        this.listeners.forEach(listener => listener());
    }
};

export default new Timestamps();