import "./WeatherWidget.scss";
import { weatherNumberToData } from "../../Functions";
import { WiStrongWind, WiWindDeg } from "weather-icons-react";
import { motion } from "framer-motion";

const WeatherWidget = (props) => {
    var data = props.config ? {
        city: props.config.weatherData.city,
        code: props.config.weatherData.weatherCode,
        temperature: props.config.weatherData.temperature,
        windDirection: props.config.weatherData.windDirection,
        windSpeed: props.config.weatherData.windSpeed
    } : {
        city: "",
        code: "",
        temperature: "",
        windDirection: "",
        windSpeed: ""
    };

    const weatherData = weatherNumberToData(data?.code);

    switch (props?.config?.style) {
        case "v2":
            return (
                <motion.div
                    className="app__weather-v2-main"
                    style={{
                        top: props?.preview ? "30px" : props?.config?.buttons?.weather?.top,
                        left: props?.preview ? "30px" : props?.config?.buttons?.weather?.left,
                    }}
                    initial={{opacity: 0, scaleX: 0}}
                    animate={{opacity: 1, scaleX: 1}}
                    exit={{opacity: 0, scaleX: 0, scaleY: 0}}
                    transition={{bounce: false}}
                >
                    <motion.div
                        className="app__weather-v2-top"
                        initial={{opacity: 0, y: -100}}
                        animate={{opacity: 1, y: 0, transition: {delay: 0.2, bounce: false}}}
                    >
                        <span>WEATHER TODAY</span>
                    </motion.div>
                    <motion.div
                        className="app__weather-v2-btm"
                    >
                        <div
                            className="app__weather-v2-temp"
                            style={{background: props?.config?.colors?.primary}}
                        >
                            <div className="app__weather-v2-temp-top">
                                <h1>{data?.temperature}</h1>
                                <h3>°C</h3>
                            </div>
                            <h5>{data?.city}</h5>
                        </div>
                        <div className="app__weather-v2-misc">
                            <div
                                className="app__weather-v2-misc-item"
                                style={{background: props?.config?.colors?.secondary}}
                            >
                                { weatherData.component }
                                { weatherData.name }
                                <h4>Forecast</h4>
                            </div>
                            <div
                                className="app__weather-v2-misc-item"
                                style={{background: props?.config?.colors?.secondary}}
                            >
                                <WiWindDeg size={60} color={'#FFF'}/>
                                {`${data.windDirection}°`}
                                <h4>Direction</h4>
                            </div>
                            <div
                                className="app__weather-v2-misc-item"
                                style={{background: props?.config?.colors?.secondary}}
                            >
                                <WiStrongWind size={60} color={'#FFF'}/>
                                {`${data.windSpeed} km/h`}
                                <h4>Speed</h4>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            );
        case "k1":
            return (
                <motion.div
                    className="k1_weather"
                    style={{
                        top: props?.preview ? "30px" : props?.config?.buttons?.weather?.top,
                        left: props?.preview ? "30px" : props?.config?.buttons?.weather?.left,
                    }}
                >
                    <motion.div
                        className="k1_weather_top"
                        initial={{scaleX: 0}}
                        animate={{scaleX: 1}}
                        exit={{scaleX: 0, transition: {delay: 1.4}}}
                    >
                        <p>WEATHER TODAY</p>
                    </motion.div>
                    <motion.div
                        className="k1_weather_city"
                        initial={{clipPath: 'inset(0 0 100% 0)', backgroundColor: "#191921"}}
                        animate={{clipPath: 'inset(0 0 0% 0)', backgroundColor: "#124fc1", transition: {delay: 0.3}}}
                        exit={{clipPath: 'inset(0 0 100% 0)', backgroundColor: "#191921", transition: {delay: 1.2}}}
                    >
                        <p>{data?.city}</p>
                    </motion.div>
                    <motion.div
                        className="k1_weather_btm"
                        initial={{clipPath: 'inset(0 0 100% 0)', backgroundColor: "#124fc1"}}
                        animate={{clipPath: 'inset(0 0 0% 0)', backgroundColor: "#191921c3", transition: {delay: 0.5}}}
                        exit={{clipPath: 'inset(0 0 100% 0)', backgroundColor: "#124fc1", transition: {delay: 0.8}}}
                    >
                        <motion.div
                            className="k1_weather_btm_item"
                            initial={{clipPath: 'inset(0 0 100% 0)', backgroundColor: "#124fc1"}}
                            animate={{clipPath: 'inset(0 0 0% 0)', backgroundColor: "#191921", transition: {delay: 1}}}
                            exit={{clipPath: 'inset(0 0 100% 0)', backgroundColor: "#124fc1", transition: {delay: 0.4}}}
                        >
                            { weatherData.component }
                            { weatherData.name }
                            <p>Forecast</p>
                        </motion.div>
                        <motion.div
                            className="k1_weather_btm_item"
                            initial={{clipPath: 'inset(0 0 100% 0)', backgroundColor: "#124fc1"}}
                            animate={{clipPath: 'inset(0 0 0% 0)', backgroundColor: "#191921", transition: {delay: 1.2}}}
                            exit={{clipPath: 'inset(0 0 100% 0)', backgroundColor: "#124fc1", transition: {delay: 0.2}}}
                        >
                            <WiWindDeg size={60} color={'#FFF'}/>
                            {`${data.windDirection}°`}
                            <p>Direction</p>
                        </motion.div>
                        <motion.div
                            className="k1_weather_btm_item"
                            initial={{clipPath: 'inset(0 0 100% 0)', backgroundColor: "#124fc1"}}
                            animate={{clipPath: 'inset(0 0 0% 0)', backgroundColor: "#191921", transition: {delay: 1.4}}}
                            exit={{clipPath: 'inset(0 0 100% 0)', backgroundColor: "#124fc1", transition: {delay: 0}}}
                        >
                            <WiStrongWind size={60} color={'#FFF'}/>
                            {`${data.windSpeed} km/h`}
                            <p>Speed</p>
                        </motion.div>
                    </motion.div>
                </motion.div>
            );
            // TODO add style for k1 maybe add weather animations ?
        default: return (<div></div>);
    }
}

export default WeatherWidget;