
import "./Console.scss";

import { PiEraserFill } from "react-icons/pi";
import MessageStore from "../../../../../managers/ConsoleMessageManager";
import { Green, Red } from "../../../../../Constants";

import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";

const ConsoleInteractiveHeader = () => {
    return (
        <header>
            <div className="console-header-title">
                <p>Console</p>
            </div>
            <div className="console-header-buttons">
                <button
                    className="console-button"
                    onClick={() => MessageStore.clearAllMessages()}
                >
                    <PiEraserFill size={"18px"}/>
                </button>
            </div>
        </header>
    );
};

const messageCreatorData = {
    1: {name: "GraphicsServer", color: "#3d22d4"},
    2: {name: "ControlPanel", color: "#bf1f47"},
    3: {name: "GraphicsConfigManager", color: "#3d22d4"},
    4: {name: "DataFetcher", color: "#de720d"},
    5: {name: "API", color: "#1b4958"},
    6: {name: "ConsoleCommands", color: "#056947"},
    100: {name: "Logger", color: "#000000"}
};

const MessageCreator = (message, index) => {
    const msg = message.message;
    const status = msg.status;
    const longMessage = msg?.parent === 6;

    const isOk = status === 200;

    return (
        <div className={`message-creator ${longMessage && "message-long"}`} key={`message_${index}`}>
            <div
                className="message-creator-head"
                style={{background: isOk ? "#3446eb" : Red}}
            >
                <p>{isOk ? "OK" : "ERROR"}</p>
            </div>
            <div className="message-creator-body">
                {
                    msg.msgtime &&
                    <div className="message-creator-timestamp">
                        <p>{new Date(msg.msgtime).toLocaleString()}</p>
                    </div>
                }
                {
                    msg.parent &&
                    <div
                        className="message-creator-parent"
                        style={{background: messageCreatorData[msg.parent]?.color}}
                    >
                        {messageCreatorData[msg.parent].name}
                    </div>
                }
                <p>{longMessage ? ` > ${msg.message}` : ` : ${msg.message}`}</p>
            </div>
        </div>
    );
};


const ConsoleDataOutput = () => {

    const [messages, setMessages] = useState([]);
    const consoleOutputRef = useRef(null);

    useEffect(() => {
        // Function to call when a new message is added
        const handleNewMessage = () => {
            setMessages(MessageStore.getMessages());
        };

        handleNewMessage();

        // Listen for new messages
        MessageStore.addListener(handleNewMessage);

        // Clean up the listener when the component unmounts
        return () => {
            MessageStore.removeListener(handleNewMessage);
        };
    }, []);

    // Scroll to the bottom whenever the messages state changes
    useEffect(() => {
        consoleOutputRef.current.scrollTop = consoleOutputRef.current.scrollHeight;
    }, [messages]);

    return (
        <div className="console-data-output" ref={consoleOutputRef}>
            {
                messages.map((message, index) =>
                    <MessageCreator message={message} index={index} />
                )
            }
        </div>
    );
}

// Define available commands
const commands = ['getData', 'getConfig', 'ping', 'getJokerData', 'version', 'clear', 'reload', 'commands', 'whoami'];

const ConsoleCommandInput = ({ socket }) => {

    const [input, setInput] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [activeSuggestion, setActiveSuggestion] = useState(0);

    // Handle input change
    const handleChange = (value) => {
        setInput(value);
        // Generate autocomplete suggestions
        const newSuggestions = commands.filter(command => command.startsWith(value) && value);
        setSuggestions(newSuggestions);
    };

    // Handle form submission
    const handleSubmit = (e) => {
        e.preventDefault();
        if (commands.includes(input)) {
            switch (input) {
                case 'clear': {
                    MessageStore.clearAllMessages();
                    break;
                }
                case 'whoami': {
                    MessageStore.addMessage({status: 200, message: '-', parent: 6, msgtime: Date.now()});
                    break;
                }
                case 'commands': {
                    MessageStore.addMessage({status: 200, message: `Available commands: [${commands.join(', ')}]`, parent: 6, msgtime: Date.now()});
                    break;
                }
                case 'reload': {
                    window.location.reload(); break;
                }
                default: {
                    socket.emit('command', {command: input}, (response) => {
                        MessageStore.addMessage(response);
                    });
                }
            }
        } else {
            MessageStore.addMessage({status: 404, message: `Command ${input} not found!`});
        }
        handleChange('');
    };
    // Handle key down event
    const handleKeyDown = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            if (suggestions.length && suggestions.length > activeSuggestion) {
                setInput(suggestions[activeSuggestion]);
                setActiveSuggestion(prev => prev + 1);
            } else {
                setActiveSuggestion(0);
            }
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="console-data-input">
                <p>{`>`}</p>
                <input
                    type="text"
                    value={input}
                    onChange={(e) => handleChange(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Enter command or type 'commands' for help"
                    onInput={(e) => {e.target.setAttribute('size', e.target.value.length)}}
                    style={{minWidth: !input ? "50%" : "150px"}}
                />
                <div
                    className="console-data-input-suggestions"
                    style={{paddingInline: suggestions.length ? "20px" : "0px"}}
                >
                    {suggestions.map((suggestion, index) => (
                        <div key={index} className={index === activeSuggestion ? 'active_suggestion' : ''}>
                            {suggestion}
                        </div>
                    ))}
                </div>
            </div>
        </form>
    );
}

const ConsoleComponentChild = ({socket}) => {

    const config = useSelector(state => state.config);
    const [activity, addActivity] = useState([]);

    const activityEndRef = useRef(null);

    useEffect(() => {
        activityEndRef.current.scrollTop =  activityEndRef.current.scrollHeight;
    }, [activity]);

    useEffect(() => {
        socket.on("jokerLapCallback", (callback) => {
            addActivity(prevActivity => {
                let newActivity = [...prevActivity, callback];
                if (newActivity.length > 8) {
                    newActivity.shift(); // Remove the first item if there are more than 8 activities
                }
                return newActivity;
            });
        })
        return () => socket.off("jokerLapCallback");
    }, []);

    return (<div className="console-child-data">
        <header>Marshall activity</header>
        <p>{config?.isMarshallConnected ? "Connected" : "Disconnected"}</p>
        <div className="console-child-data-activity" ref={activityEndRef}>
            {
                activity.map((item, index) =>
                    <div
                        className="console-child-data-activity-item"
                        key={`activity_${index}`}
                        style={{background: item?.action === "-" ? "#c72b4d" : "#1b4958"}}
                    >
                        <p>{item?.message}</p>
                    </div>
                )
            }
        </div>
    </div>);
};

const ConsoleComponent = ({ socket }) => {
    return (
        <div className="console-wrapper">
            <div className="console-component">
                <ConsoleInteractiveHeader />
                <ConsoleDataOutput />
                <ConsoleCommandInput socket={socket}/>
            </div>
            <div className="console-component-child">
                <ConsoleComponentChild socket={socket} />
            </div>
        </div>
    );
};

export default ConsoleComponent;