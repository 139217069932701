import { getRaceTime, getPositionArrow, getJoker, getParsedRaceTime } from "../../Functions";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { BiStopwatch } from "react-icons/bi";
import { getRaceBackendAddress } from "../../Functions";
import { classNameColorsM1 } from "../../config/const";

import { TiArrowDownThick, TiArrowUpThick, TiEquals } from "react-icons/ti";

import "./HorizontalScoreboard.scss";

const ITEMS_PER_PAGE = 4;

const horizontalPosArrowCustom = (arrow) => {
    switch (arrow) {
        case "+": return <TiArrowUpThick color="#3db035"/>
        case "-": return <TiArrowDownThick color="#ff2605"/>
        case "F": return <></>
        default: return <TiEquals color="#d15f1d"/>
    }
};
export const getDetailsBySidecar = (item) => {
    if (item?.isSidecar) {
        return (
        <>
            <div className="h1_item_sidecar">
                <p>{item?.firstname.slice(0, -1)}</p>
                <div className="h1_sidecar_img">
                    {item?.state && <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />}
                </div>
            </div>
            <div className="h1_item_sidecar">
                <p>{item?.lastname}</p>
                <div className="h1_sidecar_img">
                    {item?.state2 && <img src={`https://flagcdn.com/${item.state2.toLowerCase()}.svg`} alt="-" />}
                </div>
            </div>
        </>
        );
    }
    return (
        <div className="h1_item_data_details_wrapper">
            <p>{item?.firstname}</p>
            <span>{item?.lastname}</span>
            <div className="h1_single_img">
                {item?.state && <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />}
            </div>
        </div>
    );
};

const CreateM1Item = ({config, item, isLeader}) => {
    return (
        <motion.li
            className="h1_item"
            key={`h1_key_${item?.position}`}
        >
            {
                config?.useCompetitorImages &&
                <motion.div className="h1_item_img">
                    <motion.img
                        src={
                        `${getRaceBackendAddress()}/competitor_images/${config?.userId}/${item?.firstname?.toLowerCase()}${item?.lastname?.toLowerCase()}.png`}
                        onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "/competitors/none.png";
                        }}
                        initial={{clipPath: 'inset(100% 0 0 0)', filter: "brightness(0)"}}
                        animate={{clipPath: 'inset(0% 0 0 0)', filter: "brightness(1)", transition: { duration: 0.8, delay: 0.8}}}
                    />
                </motion.div>
            }
            <motion.div className="h1_item_data">
                <div className="h1_item_data_top_wrapper">
                    <motion.div
                        className="h1_item_data_top"
                        initial={{scaleX: 0, backgroundColor: "#c81111"}}
                        animate={{scaleX: 1, backgroundColor: "#0c0a0a", transition: { delay: 0.35, duration: 0.2}}}

                    >
                        <motion.div
                            className="h1_item_data_pos"
                            style={{
                                backgroundImage: item?.status === "F" ? 'url(/flags/finish.gif)' : "",
                            }}
                        >
                            <p
                                style={{
                                    color: item?.status !== "F" ? "#000" : "#fa9600",
                                    fontSize: item?.status !== "F" ? "1.5rem" : "2rem",
                                    textShadow: item?.status !== "F" ? "0 0 0 #000" : "-2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000"
                                }}
                            >{item?.position}</p>
                            {horizontalPosArrowCustom(item?.status)}
                        </motion.div>
                        <motion.div className="h1_item_data_details">
                        {
                            getDetailsBySidecar(item)
                        }
                        </motion.div>
                    </motion.div>
                </div>
                <div className="h1_item_data_nr_wrapper">
                    <motion.div
                        className="h1_item_data_nr"
                        initial={{opacity: 0, scaleY: 0}}
                        animate={{opacity: 1, scaleY: 1, transition: { duration: 0.2, delay: 0.1 }}}
                    >
                        <p>{item?.nr}</p>
                    </motion.div>
                </div>
                <div className="h1_item_data_bottom_wrapper">
                    <motion.div
                        className="h1_item_data_bottom"
                        initial={{scaleX: 0, backgroundColor: "#c81111"}}
                        animate={{scaleX: 1, backgroundColor: "#c0c0c0", transition: { delay: 0.65, duration: 0.2}}}
                    >
                        {
                            isLeader ? <span>{item?.class}</span> : <p>{item?.diff ? `+${item?.diff}` : ""}</p>
                        }
                    </motion.div>
                    { !isLeader &&
                        <motion.div
                            className="h1_item_data_bottom_class"
                            initial={{right: "180px", opacity: 0}}
                            animate={{right: "10px", opacity: 1, transition: { delay: 1, duration: 0.2}}}
                        >
                            <p>{item?.class}</p>
                        </motion.div>
                    }
                </div>
            </motion.div>
        </motion.li>
    );
}


const HorizontalScoreboard = (props) => {

    const [currentPage, setCurrentPage] = useState(1);
    const leader = props.data.data?.allCompetitors?.[0];
    const competitors = props.data.data?.allCompetitors?.slice(1);
    const totalPages = Math.ceil(
        competitors?.length / ITEMS_PER_PAGE
    );

    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const pageItems = competitors?.slice(
        startIndex,
        endIndex
    );

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentPage((currentPage) => (currentPage % totalPages) + 1);
        }, 10000);
        return () => clearInterval(interval);
    }, [currentPage, totalPages]);

    const raceFlag = props.data?.data?.raceDetails?.flag;

    if (raceFlag === "None") {
        return <></>
    }

    switch (props.config?.style) {
        case "v2":
        case "m1":
        case "k1": {
            return (
                <motion.div className="m1_horizontal">
                    <motion.div
                        className="m1_horizontal_competitors"
                        animate={{transition: {staggerChildren: 0.4}}}
                    >
                    {
                        leader &&
                        <CreateM1Item config={props?.config} item={leader} isLeader={true}/>
                    }
                    {
                        pageItems?.map((item, index) =>
                            <CreateM1Item config={props?.config} item={item} isLeader={false}/>
                        )
                    }
                    </motion.div>
                </motion.div>
            );
        }
    }

};
export default HorizontalScoreboard;