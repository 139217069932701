
import { AiOutlineUser, AiFillLock } from "react-icons/ai";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import io from "socket.io-client";
import CryptoJS from "crypto-js";

import { getRaceBackendAddress } from "../../Functions";

import "./Login.scss";

//secret key for encryption
const secretKey = "_35@ac:vII!lxx";

const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [socket, setSocket] = useState(null);
    const [errorMessages, setErrorMessages] = useState([]);
    const [code, setCode] = useState(Array(6).fill('')); // Create a state variable for the code
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Initialize socket connection when component mounts
    useEffect(() => {
        const newSocket = io(getRaceBackendAddress());
        setSocket(newSocket);

        const jwt = localStorage.getItem("jwt");
        if (jwt) {
            newSocket.emit("auth", { token: jwt }, (response) => {
                if (response.status === 200) {
                    navigate("/user/controlPanel");
                }
            });
        }
        // Disconnect the socket when the component unmounts
        return () => {
            newSocket.disconnect();
        };
    }, []);

    const checkErrorMessageLength = (message) => {
        let newErrorMessages = [...errorMessages, message];
        if (newErrorMessages.length > 2) {
            newErrorMessages.shift(); // Remove the oldest error message
        }
        setErrorMessages(newErrorMessages);
    };

    const handleInputChange = (elementIndex, event) => {
        setCode([...code.slice(0, elementIndex), event.target.value, ...code.slice(elementIndex + 1)]); // Update the code

        // If a digit is entered, focus the next input
        if (event.target.value !== '') {
          const nextElement = document.getElementById(`code-${elementIndex + 2}`);
          if (nextElement) {
              nextElement.focus();
          }
        }
        // If a digit is deleted, focus the previous input
        else if (event.target.value === '') {
            const prevElement = document.getElementById(`code-${elementIndex}`);
            if (prevElement) {
                prevElement.focus();
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true);
        const encrypted = CryptoJS.AES.encrypt(password, secretKey).toString();
        socket.emit('login', { username, encrypted, parent: true }, (response) => {
            if (response.status !== 200 ) {
                checkErrorMessageLength(response.message);
                setLoading(false);
                setPassword('');
            } else {
                localStorage.setItem("jwt", response.data.token);
                localStorage.setItem("userId", response.data.userId);
                if (response.data.admin_token) {
                    localStorage.setItem("jwt_a", response.data.admin_token);
                }
                navigate("/user/controlPanel");
            }
        });
        // Add a timeout
        setTimeout(() => {
            if (loading) {
                setLoading(false);
                checkErrorMessageLength('Login timeout');
            }
        }, 5000); // 5 seconds
    };

    const handleSubmitChild = (event) => {
        event.preventDefault();
        setLoading(true);
        const codeString = code.join('');
        const encrypted = password2;//CryptoJS.AES.encrypt(password2, secretKey).toString();
        socket.emit('login', { code: codeString, encrypted, parent: false }, (response) => {
            if (response.status !== 200) {
                checkErrorMessageLength(response.message);
                setLoading(false);
                setPassword('');
            } else {
                localStorage.setItem("jwt-child", response.data.token);
                localStorage.setItem("userID-child", response.data.userId);
                navigate("/marshall/Joker");
            }
        });
        // Add a timeout
        setTimeout(() => {
            if (loading) {
                setLoading(false);
                checkErrorMessageLength('Login timeout');
            }
        }, 5000); // 5 seconds
    };

    return (
        <div className="login-wrapper" style={{backgroundImage: `url(/login/fluid.svg)`}}>
            <div className="login-logo">
                <img src="/logo/RGLogo-new.png" alt="logo" />
            </div>
            {
                loading ?
                <div className="loading-spinner">
                    <div className="spinner"/>
                </div>
                :
                <div className="login-all">
                    <p>Login as</p>
                    <div className="login-container">
                        <header>Streamer</header>
                        <form onSubmit={handleSubmit}>
                            <span>
                                <header>Username:</header>
                                <input type="text" value={username} onChange={(e) => setUsername(e.target.value)}
                                    required spellCheck={false} />
                            </span>
                            <span>
                                Password:
                                <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}
                                    required/>
                            </span>
                            <button type="submit">Login</button>
                            {loading && <span>Loading...</span>}
                        </form>
                    </div>
                    <p>Or</p>
                    <div className="login-container">
                        <header>Marshall</header>
                        <form onSubmit={handleSubmitChild}>
                        <span className="number-input">
                                User ID:
                                {Array(6).fill('').map((_, index) => (
                                    <input
                                        key={index}
                                        id={`code-${index + 1}`}
                                        type="text"
                                        value={code[index]}
                                        onChange={(e) => handleInputChange(index, e)}
                                        maxLength="1"
                                        required
                                    />
                                ))}
                            </span>
                            <span>
                                Password:
                                <input type="password" value={password2} onChange={(e) => setPassword2(e.target.value)}
                                    required />
                            </span>
                            <button type="submit">Login</button>
                            {loading && <span>Loading...</span>}
                        </form>
                    </div>
                </div>
            }
        <div className="login-errors">
            { errorMessages?.map((message, index) =>
                <p key={index}>{message}</p>
            )}
        </div>
      </div>
    );
};
export default Login;