import { useState, useEffect } from "react";
import axios from "axios";
import { getRaceBackendAddress } from "../../../../../Functions";
import { Doughnut, Bar } from 'react-chartjs-2';
import {
    Chart,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

Chart.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const bToMb = (b) => {
    return b / 1024 / 1024 / 1024;
};

function convertUnixTime(unixTime) {
    const seconds = unixTime % 60;
    const minutes = Math.floor(unixTime / 60) % 60;
    const hours = Math.floor(unixTime / 3600) % 24;
    const days = Math.floor(unixTime / 86400);

    return `${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`;
}

const Activity = () => {

    const [activityData, setActivityData] = useState({});
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${getRaceBackendAddress()}/getServerSpecifications`)
        .then((resp) => {
            if (resp.request.status === 200) {
                setActivityData(resp.data);
                console.log(resp.data)
                setLoading(false);
            }
        })
        .catch((err) => {
            console.log(err);
        });
    }, []);

    // Plugins
    const doughnutLabel = {
        id: "doughnutLabel",
        beforeDatasetsDraw(chart) {
            const { ctx } = chart;
            ctx.save();
            const xcoor = (chart.chartArea.left + chart.chartArea.right) / 2;
            const ycoor = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.font = 'bold 20px sans-serif';
            ctx.fillStyle = '#ffffff';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const activityData = chart.options.activityData;
            console.log(activityData.currentLoad)
            if (activityData && activityData.currentLoad && activityData.currentLoad.currentLoad) {
                ctx.fillText(`${activityData.currentLoad.currentLoad.toFixed(2)}%`, xcoor, ycoor);
            }
            ctx.restore();
        }
    };

    const barOptions = {
        responsive: true,
        indexAxis: 'y',
    };
    const barOptionsX = {
        responsive: true,
        plugins: {
            legend: {
                position: "top",
                display: false
            },
        },
        scales: {
            x: {
              stacked: true,
            },
            y: {
              stacked: true,
            },
        },
    }

    const doughnutOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false,
            position: "bottom",
            labels: {
                fontSize: 18,
                fontColor: "#ffffff"
            }
        },
        plugins: [doughnutLabel],
        cutout: "70%",
        activityData: activityData
    }

    if (loading) {
        return (
            <div className="server_activity">
                <p>Loading data...</p>
            </div>
        );
    }

    return (
        <div className="server_activity">
            <div className="server_activity_header">
                <p>{activityData?.osInfo?.distro}</p>
                <span>{activityData?.osInfo?.kernel}</span>
                <span>{activityData?.osInfo?.hostname}</span>
                <div className="server_activity_header_timers">
                    <p>{`Server time: ${new Date(activityData?.server_time)}`}</p>
                    <p>{`Server uptime: ${convertUnixTime(activityData?.uptime)}`}</p>
                </div>
            </div>
            <div
                className="server_activity_item"
                style={{backgroundImage: "url(/svg/topo2.svg)"}}
            >
                <header><p>Current server load</p></header>
                <span>Server load in percentage</span>
                <div className="server_activity_item_wrapper">
                    <Doughnut
                        data={{
                            labels: [],
                            datasets: [{
                                data: [activityData?.currentLoad?.currentLoad, 100],
                                backgroundColor: ["#2840f0", "#17243b"],

                                borderColor: ["#2840f0", "#17243b"],
                                borderWidth: 1,
                            }],
                            text: `${activityData?.currentLoad?.currentLoad}%`
                        }}
                        options={doughnutOptions}
                    />
                </div>
            </div>
            <div
                className="server_activity_item_memory"
                style={{backgroundImage: "url(/svg/topo2.svg)"}}
            >
                <header><p>Memory usage</p></header>
                <div className="server_activity_item_wrapper">
                    <Bar options={barOptions} data={{
                        labels: ["Memory"],
                        datasets: [{
                            label: "Total",
                            data: [bToMb(activityData?.memory?.total)],
                            backgroundColor: "#2840f0",
                            borderColor: "#17243b"
                        },
                        {
                            label: "Available",
                            data: [bToMb(activityData?.memory?.available)],
                            backgroundColor: "#47fcab",
                            borderColor: "#47fcab"
                        },
                        {
                            label: "Used",
                            data: [bToMb(activityData?.memory?.used)],
                            backgroundColor: "#6a00eb",
                            borderColor: "#6a00eb"
                        }
                    ],
                    }} />
                </div>
            </div>
            <div className="server_activity_item_log">
                <header><p>PM2 Log</p></header>
            </div>
            <div
                className="server_activity_item_disk"
                style={{backgroundImage: "url(/svg/topo2.svg)"}}
            >
                <header><p>Disk capacity</p></header>
                {
                    activityData?.diskUsage?.map((item, index) =>
                    <div
                        className="server_activity_item_disk_item"
                        key={"disk_use_"+index}
                    >
                        <div className="server_activity_item_disk_item_bar_wrapper">
                            <Bar
                                options={barOptionsX}
                                data={{
                                    labels: [item?.fs],
                                    datasets: [{
                                        label: "Used",
                                        data: [bToMb(item?.used), bToMb(item?.size)],
                                        backgroundColor: "#2840f0",
                                        borderColor: "#2840f0"
                                    },
                                    {
                                        label: "Available",
                                        data: [bToMb(item?.size), bToMb(item?.size + 1)],
                                        backgroundColor: "#17243b",
                                        borderColor: "#17243b"
                                    }]
                                }}
                            />
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default Activity;