
import { useEffect, useState, useCallback } from "react";
import MessageStore from "../../../../../../../managers/ConsoleMessageManager";
import PopoutManager from "../../../../../../../managers/PopoutManager";
import { useSelector } from "react-redux";

// Add new / edit current track popout window

const InteractiveTrackAdd = ({socket, currentTrack, editMode = false, cb}) => {
    const inputs = [
        { label: 'Track name', key: "name", type: 'text', placeholder: 'Enter name...', value: currentTrack?.name },
        { label: 'Track type', key: "type", type: 'select', options: [
            { value: 1, text: 'Motocross' }, { value: 2, text: 'Car racing' }, { value: 3, text: 'Karting' }
        ], value: currentTrack?.type },
        { label: 'Track image', key: "image", type: 'file', accept: 'image/png, image/jpeg', value: currentTrack?.image },
        { label: 'Track length', key: "length", type: 'text', placeholder: 'Enter length...', value: currentTrack?.length },
        { label: 'Track location', key: "location", type: 'text', placeholder: 'Enter location...', value: currentTrack?.location },
        { label: 'Track surface', key: "surface", type: 'text', placeholder: 'Enter surface...', value: currentTrack?.surface },
        { label: 'Track minimum width', key:"min_width", type: 'text', placeholder: 'Enter minimum width...', value: "" },
        { label: 'Track maximum width', key: "max_width", type: 'text', placeholder: 'Enter maximum width...', value: "" },
        { label: 'Track average lap time', key: "average_lap", type: 'text', placeholder: 'Enter lap time...', value: "" }
    ];

    const [values, setValues] = useState({});

    const handleChange = (e, key) => {
        setValues({ ...values, [key]: e.target.value });
    };

    const saveTrack = () => {
        socket.emit("tracks", {what: 5, data: values}, (callback) => {
            MessageStore.addMessage(callback);
            if (callback.status === 200) {
                PopoutManager.removeWindow(`graphicsTracksCreator`);
                cb();
            }
        });
    };

    return (
        <div className="interactiveTrackAdd-container">
            <div className="interactiveTrackAdd-items">
                {inputs.map((input, index) => (
                    <div className="interactiveTrackAdd-item" key={index}>
                        <p>{input.label}</p>
                        {input.type === 'select' ? (
                            <select onChange={(e) => handleChange(e, input.key)} defaultValue={editMode ? input.value : 0}>
                                {input.options.map((option, index) => (
                                    <option value={option.value} key={"option_value_"+index}>{option.text}</option>
                                ))}
                            </select>
                        ) : (
                            <input type={input.type} placeholder={input.placeholder} accept={input.accept}
                            onChange={(e) => handleChange(e, input.key)} required={input.required}
                            defaultValue={input.type !== 'file' && editMode ? input.value : ""}
                            />
                        )}
                    </div>
                ))}
            </div>
            <div className="interactiveTrackAdd-buttons">
                <button onClick={() => saveTrack()}>Save track</button>
                <button onClick={() => PopoutManager.removeWindow(`graphicsTracksCreator`)}>Exit without saving</button>
            </div>
        </div>
    );
};

const InteractiveTrackSelector = (props) => {

    const config = useSelector(state => state.config);

    const {socket} = props;
    const [tracks, setTracks] = useState(null);
    const [selectedTracks, setSelectedTracks] = useState([]);
    const [trackToShow, setTrackToShow] = useState(null);
    const [searchString, setSearchString] = useState("");

    const populateTrackList = useCallback(() => {
        socket.emit("tracks", {what: 2, data: []}, (callback) => {
            if (callback.status === 200) {
                setTracks(callback.data);
            }
        });
    }, [socket]);

    useEffect(() => {
        populateTrackList();
    }, []);

    const deleteTrack = (tracks) => {
        setTrackToShow(null);
        socket.emit("tracks", {what: 3, data: tracks}, (callback) => {
            MessageStore.addMessage(callback);
            setSelectedTracks([]);
            if (callback.status === 200) {
                populateTrackList();
            }
        });
    };
    const setCurrentTrack = () => {
        socket.emit("update", {what: 5, data: ["selectedTrack", trackToShow]}, (callback) => {
            MessageStore.addMessage(callback);
        });
    };
    const handleButtonClick = (id, condition, action, message) => {
        if (condition) {
            action();
        } else {
            MessageStore.addMessage({message: `[${message}]: No track selected`, status: 400, parent: 2});
            document.getElementById(id).classList.add("flash");
            setTimeout(() => document.getElementById(id).classList.remove("flash"), 1000);
        }
    };
    const KeyValue = ({ keyName, value }) => (
        <p>
            <span className="key">{keyName}</span>
            <span className="value">{value}</span>
        </p>
    );
    const keys = ['name', 'location', 'length', 'maxWidth', 'minWidth', 'surface', 'average_lap'];

    return (
        <div className="interactive-screen-main-tracks">
            <div className="interactive-screen-main-tracks-list">
                <header>Available tracks</header>
                <div className="interactive-screen-main-tracks-search">
                    <input type="text" id="tracks-search" placeholder="Search..." defaultValue={searchString} onChange={(e) => setSearchString(e.target.value)}/>
                    <button onClick={() => {setSearchString(""); document.getElementById("tracks-search").value = ""}}>Clear</button>
                </div>
                <div className="interactive-screen-main-tracks-list-tracks">
                {
                    tracks && tracks.filter(i => i.track.name?.toLowerCase().includes(searchString?.toLowerCase())).map((track, index) =>
                        <div className="interactive-screen-main-tracks-list-item"
                            key={`track_item_${index}`}
                            onClick={() => setTrackToShow(track.track)}
                            style={{
                                background: track.track.id === config?.selectedTrack?.id ? "#0067cd" :
                                    track.track.id === trackToShow?.id ? "#233356" : ""
                            }}
                        >
                            <li>{track.track.name}</li>
                            <li>{track.track.location}</li>
                            <li>{`${track.track.length} km`}</li>
                            <input
                                type="checkbox"
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        setSelectedTracks(prevTracks => [...prevTracks, track.track.id]);
                                    } else {
                                        setSelectedTracks(prevTracks => prevTracks.filter(id => id !== track.track.id));
                                    }
                                }}
                            />
                        </div>
                    )
                }
                </div>
            </div>
            <div className="interactive-screen-main-tracks-buttons">
                <header>Options</header>
                <div className="interactive-screen-main-tracks-buttons-item">
                    <button id="btn_del_all_selected" onClick={() => handleButtonClick("btn_del_all_selected", selectedTracks.length, () => deleteTrack(selectedTracks), "Delete")}>Delete selected</button>
                    <button id="btn_del_selected" onClick={() => handleButtonClick("btn_del_selected", trackToShow, () => deleteTrack([trackToShow?.id]), "Delete")}>Delete current</button>
                    <button onClick={() => PopoutManager.addWindow({
                        id: `graphicsTracksCreator`,
                        name: `Create a new track`,
                        el: <InteractiveTrackAdd socket={socket} currentTrack={{}} cb={() => populateTrackList()}/>,
                        size: {w: 500, h: 650}
                    })}>Add new</button>
                    <button id="btn_edit_selected" onClick={() => handleButtonClick("btn_edit_selected", trackToShow, () => PopoutManager.addWindow({
                        id: `graphicsTracksCreator`,
                        name: `Edit track ${trackToShow?.name}`,
                        el: <InteractiveTrackAdd socket={socket} currentTrack={trackToShow} editMode={true} cb={() => populateTrackList()}/>,
                        size: {w: 500, h: 650}
                    }), "Edit")}>Edit current</button>
                    <button id="btn_use_track" onClick={() => handleButtonClick("btn_use_track", trackToShow, () => setCurrentTrack(), "Use")}>Use track</button>
                </div>
            </div>
            <div className="interactive-screen-main-tracks-preview">
                <header>Track preview</header>
                {trackToShow && (
                    <div className="interactive-screen-main-tracks-preview-render">
                        <img src={trackToShow.image ? `/tracks/${trackToShow.image}` : `/images/not_available.png`} />
                        {keys.map(key => (
                            <KeyValue key={key} keyName={key} value={trackToShow[key]} />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default InteractiveTrackSelector;