import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import PopoutManager from '../managers/PopoutManager';

import { getRaceBackendAddress } from '../Functions';


export function usePopoupCount(initialCount) {
    return 0;
};

export function useAuthCheck(socket) {
    const navigate = useNavigate();

    useEffect(() => {
        const checkJwt = () => {
            socket.emit('auth', { token: localStorage.getItem('jwt') }, (response) => {
                if (response.status !== 200) {
                    localStorage.clear();
                    navigate("/login");
                }
            });
        };
        // Check JWT on page load
        checkJwt();
        // Check JWT every 5 minutes
        const intervalId = setInterval(checkJwt, 5 * 60 * 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [socket]);
};

export function useChangeLogFetch() {
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        axios.get(`${getRaceBackendAddress()}/getChangeLog`).then((res) => {
            if (res.data?.status === 200) {
                setLogs(res.data?.data?.reverse());
            }
        })
        .catch((err) => {
            console.log("ChangeLog fetch error: " + err.message);
        });
    }, []);

    return logs;
};