import "./HorizontalBar.scss";
import MessageStore from "../../../../../managers/ConsoleMessageManager";
import { FaCircle, FaListUl, FaFlag } from "react-icons/fa";
import { IoTime } from "react-icons/io5";
import { IoMdSettings, IoMdHelp } from "react-icons/io";
import { FiServer } from "react-icons/fi";
import { Green, Red } from "../../../../../Constants";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Line } from 'react-chartjs-2';
import {CategoryScale} from 'chart.js';
import Chart from 'chart.js/auto';
import Timestamps from "./GraphTimestamps";
import {IoRefreshCircleSharp} from "react-icons/io5";
import { FaCircleInfo } from "react-icons/fa6";
import { MdAdminPanelSettings } from "react-icons/md";
import { BsStopwatchFill } from "react-icons/bs"

import { pageNames } from "../../../../../config/namespaces";

import { getRaceBackendAddress, flagToColor } from "../../../../../Functions";
import axios from "axios";
import { motion, AnimatePresence } from 'framer-motion';

Chart.register(CategoryScale);

const HorizontalAvailableRacesList = ({ raceList, callback }) => {
    return (
        <div className="horizontalBar-component-race-selector-data">
            <header>Available races</header>
            <div className="horizontalBar-component-races-list">
                {
                    raceList?.map((race, index) =>
                        <div
                            className="horizontalBar-component-race-item"
                            key={`race-hor-item${index}`}
                            onClick={() => callback(race?.token)}
                        >
                            <header>{race?.race_name}</header>
                            {race?.track_name}
                            {race?.flag}
                            {race?.elapsed_time}
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export const HorizontalAvailableRaces = ({ config, socket }) => {
    const [openRaceSelector, setOpenRaceSelector] = useState(false);
    const [raceList, setRaceList] = useState([]);
    const [selectedEvent, setSelectedEvent] = useState(null);

    const dispatch = useDispatch();
    const raceData = useSelector(state => state.data);

    const setSelectedRace = (token) => {
        socket.emit("setApiKey", token, (callback) => {
            MessageStore.addMessage(callback);
            if (callback.status === 200) {
                raceList?.map((race) => {
                    if (race.token === token) {
                        setSelectedEvent(race);
                    }
                });
            }
        });
    }

    const fetchData = async () => {
        setTimeout(async () => {
            await socket.emit("getActiveRaceList", (callback) => {
                MessageStore.addMessage(callback);
                if (callback.status === 200) {
                    setRaceList(callback.data);
                }
            });
        }, 1000)
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="horizontalBar-component-race-selector-inner">
            <header>
                <p>Races</p>
                <button>
                    <IoRefreshCircleSharp size="20px" onClick={() => fetchData()}/>
                </button>
            </header>
            <div className="horizontalBar-component-race-selector-selected">
                {
                    raceData && raceData?.status !== 400 ?
                    <>
                        <p>{raceData?.data?.raceDetails?.name}</p>
                        <p style={{color: "#1fd5fc"}}>{raceData?.data?.raceDetails?.track_name}</p>
                    </>
                    :
                    <>
                        <span>Authentification error</span>
                    </>
                }
                <div className="horizontalBar-component-race-selector-available">
                    <FaCircle color={raceList?.length ? Green : Red} size="12px"/>
                    <p>{`Races available: ${raceList?.length}`}</p>
                </div>
                {
                    config?.apiKey &&
                    <button onClick={() => {
                        setSelectedRace("None");
                        window.location.reload();
                    }}>
                        Disconnect from race
                    </button>
                }
                <button onClick={() => {
                    dispatch({type: "SET_PAGE", payload: pageNames.AvailableRaces});
                }}>
                    View available
                </button>
            </div>
            { openRaceSelector &&
              <HorizontalAvailableRacesList raceList={raceList} callback={setSelectedRace} />
            }
        </div>
    );
};

const TimeGraph = ({ timestamps }) => {
    // Calculate the time differences

    const timeDifferences = timestamps.slice(1).map((timestamp, index) => timestamp - timestamps[index]);
    const data = {
        labels: timestamps.map((_, index) => `- ${index + 1}f`).reverse(),
        datasets: [
            {
                label: 'Time difference',
                data: timeDifferences,
                fill: false,
                backgroundColor: '#eaf140',
                borderColor: '#eaf140',
                spanGaps: true,
            },
        ],
    };
    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: 10, // Set the font size to 10
                    },
                },
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    font: {
                        size: 10, // Set the font size to 10
                    },
                },
            },
        },
        plugins: {
            legend: {
                display: false,
            },
        },
    };

    const getTimeDiffColor = (timeDiff) => {
        const optimalTimeDiff = 1000;
        const diff = Math.abs(timeDiff - optimalTimeDiff);
        const maxDiff = 1000;

        // Calculate the red and green components of the color
        const red = Math.min(255, Math.round((diff / maxDiff) * 255));
        const green = Math.min(255, Math.round(((maxDiff - diff) / maxDiff) * 255));
        return (
            <span style={{color: `rgb(${red}, ${green}, 0)`}}>{timeDiff || 0}</span>
        );
    };

    return (
        <div className="time-differences-graph-wrapper">
            <div className="time-differences-graph">
            {
                timeDifferences.length ?
                <Line data={data} options={options}/>
                :
                <span>No race selected</span>
            }
            </div>
            <div className="time-differences-graph-info-wrapper">
                <div className="time-differences-graph-info">
                    <p>Last fetch</p>
                    <div className="time-differences-graph-fetch">
                        {getTimeDiffColor(timeDifferences[timeDifferences.length - 1])}
                    </div>
                </div>
            </div>
        </div>
    );
};

export const HorizontalDataGraph = () => {

    const [timestamps, setTimestamps] = useState(Timestamps.getTimestamps());

    useEffect(() => {
        const updateTimestamps = () => {
            setTimestamps([...Timestamps.getTimestamps()]);
        };

        Timestamps.addListener(updateTimestamps);
        return () => {
            Timestamps.removeListener(updateTimestamps);
        };
    }, []);

    return <TimeGraph timestamps={timestamps} />;
};

export const Switch = ({lvalue, rvalue, onChange, defaultValue}) => {
    const [isToggled, setIsToggled] = useState(defaultValue);

    useEffect(() => {
        setIsToggled(defaultValue);
    }, [defaultValue]);

    const onToggle = () => {
        setIsToggled(!isToggled);
        onChange(!isToggled);
    };

    return (
        <div
            className={`switch ${isToggled ? 'switch--right' : 'switch--left'}`}
            onClick={onToggle}
        >
            <div className="switch-text" style={{color: isToggled ? "#8f8f8f" : "#fff"}}>{lvalue}</div>
            <div className="switch-toggle"></div>
            <div className="switch-text" style={{color: isToggled ? "#fff" : "#8f8f8f"}}>{rvalue}</div>
        </div>
    );
};

export const KeyboardPressListener = ({config, socket}) => {
    const [isListening, setIsListening] = useState(false);

    const checkKeyEvent = (event) => {
        if (config?.buttons === undefined) {
            return;
        }
        Object.keys(config?.buttons).map(async (item) => {
            if (config?.buttons?.[item]?.key === event.key && config?.buttons?.[item]?.enabled) {
                const value = config?.buttons?.[item]?.show;
                socket.emit("update", {what: 6, data: [item, "show", !value]}, (callback) => {
                    MessageStore.addMessage(callback);
                });
            }
        })
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (isListening) { checkKeyEvent(event); }
        }
        window.addEventListener('keyup', handleKeyDown);
        return () => {
            window.removeEventListener('keyup', handleKeyDown);
        }
    }, [isListening, config?.buttons]);
    return (
        <div className="keyboardPressListener-component">
            <p>Keypress</p>
            <Switch
                lvalue={"Standby"}
                rvalue={"Listening"}
                onChange={(value) => {setIsListening(value)}}
                defaultValue={false}
            />
        </div>
    );
};

const HorizontalUserData = ({socket}) => {

    const [userData, setUserData] = useState(null);
    const dispatch = useDispatch();
    //const socket = null;//useSelector(state => state.socket);

    useEffect(() => {
        // Set up the listener
        const handleUserDataCallback = (response) => {
            setUserData(response);
        };
        setTimeout(async () => {
            await socket.emit("getUserData", handleUserDataCallback);
        }, 1000);

    }, [socket]);

    const renderOnUserDataState = () => {
        if (userData) {
            return (
                <div className="horizontalBar-component-user-data-info">
                    <div className="horizontalBar-component-user-data-info-name">
                        <h4>{userData?.name}</h4>
                    </div>
                    <div className="horizontalBar-component-user-data-info-time-left">
                        <span><IoTime size={"20px"}/><p>{userData?.exp_date}</p></span>
                    </div>
                </div>
            );
        }
        return (
            <div className="horizontalBar-component-user-data-waiting">
                <p>Loading user info<span className="dots"><span>.</span><span>.</span><span>.</span></span></p>
            </div>
        );
    }

    const logout = () => {
        localStorage.clear();
        window.location.reload();
    };
    return (
        <>
            {renderOnUserDataState()}
            <div className="horizontalBar-component-user-data-buttons">
                <p
                    title="Help"
                    onClick={() => {
                        dispatch({type: "SET_PAGE", payload: pageNames.Help})
                    }}
                >
                    <IoMdHelp size="20px"/>
                </p>
                <p
                    title="Admin panel"
                    onClick={() => window.open("https://racegraphics.eu/user/administrate", "_blank")}
                >
                    <MdAdminPanelSettings size="20px"/>
                </p>
                <p
                    title="View changelog"
                    onClick={() => {
                        dispatch({type: "SET_PAGE", payload: pageNames.ChangeLog})
                    }}
                >
                    <FaListUl size="20px"/>
                </p>
                <p
                    onClick={() => window.open("https://stream.racegraphics.eu", '_blank')}
                    title="Open data server"
                >
                    <FiServer size="20px"/>
                </p>
                {/* <ConsoleComponentSupport /> */}
                <p
                    title="Settings"
                >
                    <IoMdSettings size="25px"/>
                </p>
                <button onClick={() => logout()}>Logout</button>
            </div>
        </>
    );
};

const msgTypeMap = {
    "info": <FaCircleInfo size="20px"/>,
};

const HorizontalMessages = () => {
    const [messages, setMessages] = useState([]);
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);

    useEffect(() => {
        axios.get(`${getRaceBackendAddress()}/getMessages`)
            .then((response) => {
                if (response?.data?.status === 200) {
                    setMessages(response?.data?.data);
                }
            })
            .catch((error) => {

            });
    }, []);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentMessageIndex((currentMessageIndex + 1) % messages.length);
        }, 6000);

        return () => clearInterval(timer); // Clean up the timer when the component unmounts
    }, [currentMessageIndex, messages]);

    return (
        <div className="horizontalBar-messages">
            {messages.length > 0 && (
                <motion.div
                    className="horizontalBar-messages-item"
                    style={{background: messages[currentMessageIndex]?.color}}
                    initial={{ opacity: 0, y: -50 }} // Start from above and invisible
                    animate={{ opacity: 1, y: 0 }} // Animate to fully visible and in position
                    exit={{ opacity: 0, y: 50 }} // Exit to below and invisible
                    transition={{ duration: 0.5 }} // Duration of the animation
                >
                    {msgTypeMap[messages[currentMessageIndex]?.type] || ""}
                    <p>{messages[currentMessageIndex]?.text}</p>
                </motion.div>
            )}
        </div>
    );
};

const HorizontalRaceInfo = () => {
    const data = useSelector(state => state.data);
    const config = useSelector(state => state.config);

    if (!data || data?.status === 400) {
        return <div className="horizontalBar-raceInfo-box">Authentification error</div>
    }
    if (!config || !config?.apiKey) {
        return (
            <div className="horizontalBar-raceInfo-box">
                <p>Waiting for race..</p>
            </div>
        );
    }
    return (
        <div className="horizontalBar-raceInfo-box">
            <p>{data?.data?.raceDetails?.name}</p>
            <p>{`type: ${data?.data?.raceDetails?.race_type}`}</p>
            <p><FaFlag color={flagToColor(data?.data?.raceDetails?.flag)} size={"15px"}/></p>
            <p>
                <BsStopwatchFill size={"15px"} color="#f7d00a"/>
                <span>{data?.data?.raceTimer?.name}</span>
                <span>{data?.data?.raceTimer?.data}</span>
            </p>
        </div>
    );
};

const HorizontalBarComponent = ({socket}) => {

    return (
        <div className="horizontalBar-component">
            <div className="horizontalBar-logo">
                <img src="/logo/RGLogo-new.png" alt="logo" />
            </div>
            <AnimatePresence>
                <HorizontalMessages />
            </AnimatePresence>
            <HorizontalRaceInfo />
            <div className="horizontalBar-component-user-data">
                <HorizontalUserData socket={socket}/>
            </div>
        </div>
    );
};

export default HorizontalBarComponent;