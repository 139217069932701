
import "./Buttons.scss";
import CustomButtonsConfig from "./CustomButtonsConfig";
import { Red } from "../../../../../Constants";

import { MdOutlineStar } from "react-icons/md";
import { BsFillGrid3X3GapFill } from "react-icons/bs";
import { MdDisabledByDefault } from "react-icons/md";
import { FaKey } from "react-icons/fa6";
import { IoIosColorPalette } from "react-icons/io";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";

// for console loggin
import MessageStore from "../../../../../managers/ConsoleMessageManager";
import PopoutManager from "../../../../../managers/PopoutManager";

import { GraphicsPreviewElement } from "../InteractiveButtons/CustomButtonsComponents";

import { designData } from "../../../../../config/fields";
import { useButtonValidator } from "./stateValidator";

import { VscError } from "react-icons/vsc";
import { IoMdWarning } from "react-icons/io";

import styled, { keyframes, css } from 'styled-components';
import { Confirmation } from "../ConfirmAction";
import { pageNames } from "../../../../../config/namespaces";


const warnFlashAnimation = keyframes`
    0% { background-color: #2b364a; }
    50% { background-color: Orange; }
    100% { background-color: #2b364a; }
`;

const errorFlashAnimation = keyframes`
    0% { background-color: #2b364a; }
    50% { background-color: ${Red}; }
    100% { background-color: #2b364a; }
`;

const InteractiveButtonStyled = styled.div`
    background: ${props => !props.show ? "#2b364a" : "#3446eb"};
    animation: ${props => props.errorLevel === "ERROR" ? css`${errorFlashAnimation} 1s infinite` :
                props.errorLevel === "WARN" ? css`${warnFlashAnimation} 3s infinite` :
                'none'};
    `;

const InteractiveButtonKeyAssigner = ({buttonConfig, buttonName, socket, cb}) => {
    const [waiting, setWaiting] = useState(false);
    const [flash, setFlash] = useState(false);
    const [currentKey, setCurrentKey] = useState("-");

    const config = useSelector(state => state.config);

    useEffect(() => {
        setCurrentKey(buttonConfig?.key || "-");
    }, [buttonConfig]);

    const isDuplicateKey = (newKey) => {
        if (!config || !config.buttons) {
            return false;
        }
        const keys = Object.entries(config?.buttons)
        .filter(([key, button]) => key !== buttonName)
        .map(([key, button]) => button?.key);
        return keys.includes(newKey);
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (isDuplicateKey(event?.key)) {
                MessageStore.addMessage({
                    status: 0,
                    message: `[${buttonName}] : Key already assigned to another button`
                });
                setFlash(true);
                setTimeout(() => { setFlash(false) }, 2000);
            }
            setCurrentKey(event.key);
            setWaiting(false);
        };

        if (waiting) {
            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [waiting]);

    const setButtonKey = () => {
        socket.emit("update", {what: 6, data: [buttonName, "key", currentKey]}, (callback) => {
            MessageStore.addMessage(callback);
        });
    };

    return (
        <div className="assignButtonKey">
            <header>Click to assign</header>
            <div
                className={
                    `assignButtonKey-currentKey
                    ${waiting ? "waiting" : ""}
                    ${flash ? "flash" : ""}
                `}
                onClick={(event) => {event.stopPropagation(); setWaiting(true);}}
            ><p>{currentKey}</p></div>
            <button onClick={(event) => { event.stopPropagation(); setButtonKey(); cb();}}>Save</button>
        </div>
    );
}


const InteractiveButton = ({buttonData, socket, config, errors}) => {

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [pendingAction, setPendingAction] = useState(null);

    // used to assign a keybind for button
    const [buttonKey, setButtonKey] = useState(false);

    const buttonName = buttonData[0];
    const buttonConfig = buttonData[1];
    const buttonCustomData = CustomButtonsConfig[buttonName];

    const errorsMap = {
        "ERROR": <VscError size={"20px"}/>,
        "WARN": <IoMdWarning size={"20px"}/>
    };

    const dispatch = useDispatch();

    const buttonHeaderElements = [
        {
            onClick: () => { handleOnClickEvent("favorite") },
            element: <MdOutlineStar color={buttonConfig.favorite ? "yellow" : "white"} size={"80%"}/>
        },
        {
            onClick: () => {
                dispatch({type: "SET_PROPS", payload: buttonData});
                dispatch({type: "SET_PAGE", payload: pageNames.GraphicsPosition});
            },
            element: <BsFillGrid3X3GapFill size={"80%"}/>
        },
        {
            onClick: () => {
                setButtonKey(true);
            },
            element: <FaKey size={"65%"}/>
        },
        {
            onClick: () => { handleOnClickEvent("enabled") },
            element: <MdDisabledByDefault size={"80%"} color={Red}/>
        }
    ];

    // Do not add possibility to change colors if currently set design doesnt support this feature
    if (designData[config.style]?.areColorsAvailable) {
        buttonHeaderElements.push({
            onClick: () => {
                dispatch({type: "SET_PROPS", payload: buttonData});
                dispatch({type: "SET_PAGE", payload: pageNames.GraphicsColors});

            },
            element: <IoIosColorPalette size={"80%"} color="#f7d00a"/>
        });
    }

    const handleOnClickEvent = (name) => {
        if (name === "enabled" && buttonConfig.show) {
            MessageStore.addMessage({
                status: 0, message: `[${buttonName}] : Cannot disable a button that is currently being shown`
            });
            return;
        }

        if (name === "enabled") {
            setPendingAction({ name, value: !buttonConfig[name] });
            setShowConfirmation(true);
        } else {
            // TODO: Allows errors to be overridden by user, needs fixing
            // if (errors[buttonName]?.message && !config?.allowErrorOverride) {
            //     return;
            // }

            socket.emit("update", {
                what: 6,    // button
                data: [
                    buttonName,
                    name,
                    !buttonConfig[name]
                ]
            }, (callback) => {
                MessageStore.addMessage(callback);
            });
        }
    };

    const handleConfirmation = (confirmed) => {
        if (confirmed && pendingAction) {

            socket.emit("update", {
                what: 6,    // button
                data: [
                    buttonName,
                    pendingAction.name,
                    pendingAction.value
                ]
            }, (callback) => {
                MessageStore.addMessage(callback);
            });
        }

        setPendingAction(null);
        setShowConfirmation(false);
    };

    const showButtonState = () => {
        if (errors[buttonName]) {
            return (
                <div
                    className="button-error-container"
                    style={{justifyContent: errors[buttonName]?.message.length > 22 ? "flex-start" : "center"}}
                >
                    <p
                        className={errors[buttonName]?.message.length > 22 ? "scroll" : ""}
                        key={`error_${buttonName}`}
                    >{errors[buttonName]?.message}</p>
                </div>

            );
        }
        return (
            <span
                style={{background: buttonConfig.show ? "#3446eb" : "#2b364a"}}
            >{buttonConfig.show ? "Activated" : ""}</span>
        );
    }

    const setNewComponent = (key, props = {}) => {
        Object.keys(CustomButtonsConfig).forEach((parentKey) => {
            const id = CustomButtonsConfig[parentKey].find(item => item.key === key)?.id;
            id && dispatch({type: "SET_PAGE", payload: id});
        });
    }

    return (
        <InteractiveButtonStyled
            className="interactive-button"
            id={`interactive_button_${buttonName}`}
            show={buttonConfig.show}
            errorLevel={errors[buttonName]?.level}
        >
            {showConfirmation && (
                <Confirmation
                    message={`Are you sure you want to hide ${buttonConfig.name} graphic?`}
                    callback={handleConfirmation}
                />
            )}
            <header>
                {
                    buttonHeaderElements.map((entry, index) =>
                        <div
                            className="interactive-button-header-item"
                            key={`header_item_${index}_${buttonName}`}
                            onClick={() => entry.onClick()}
                        >{entry.element}</div>
                    )
                }
            </header>
            <div className="interactive-button-screen">
                <div className="interactive-button-screen-side">
                    <div
                        className="interactive-button-screen-side-inner"
                        style={{
                            marginRight: "0", marginLeft: "3px",
                            borderTopRightRadius: "0px",
                            borderTopLeftRadius: "4px"
                        }}
                    />
                </div>
                <div
                    className="interactive-button-screen-center"
                    onClick={() => handleOnClickEvent("show")}
                >
                    {
                        errors[buttonName] &&
                        <div
                            className="interactive-button-error"
                            title={errors[buttonName]?.message}
                            style={{
                                color: errors[buttonName]?.level === "ERROR" ? Red : "orange"
                            }}
                        >{errorsMap[errors[buttonName]?.level]}</div>
                    }
                    {
                        buttonKey &&
                        <InteractiveButtonKeyAssigner
                            buttonConfig={buttonConfig}
                            buttonName={buttonName}
                            socket={socket}
                            cb={() => setButtonKey(false)}
                        />
                    }
                    <div className="interactive-button-screen-center-keybind">
                        {buttonConfig.key || "-"}
                    </div>
                    <div className="interactive-button-screen-center-name">
                        {buttonConfig.name}
                    </div>
                </div>
                <div
                    className="interactive-button-screen-side"
                >
                    <div className="interactive-button-screen-side-inner">
                    {
                        buttonCustomData && !buttonKey &&
                        buttonCustomData.map((buttonObject, index) =>
                            <div
                                className="interactive-button-side-item"
                                key={`header_item_favorite_${index}`}
                                onClick={() => setNewComponent(buttonObject.key, {socket: socket, config: config})}
                            >
                                {buttonObject.icon}
                            </div>
                        )
                    }
                    </div>
                </div>
            </div>
            <div
                className="interactive-button-bottom"
            >
                {
                    showButtonState()
                }
            </div>
        </InteractiveButtonStyled>
    );
};

const SkeletonButton = () => {
    return (
        <div className="interactive-button btn-skeleton" />
    );
}

const FavoriteButtons = ({ config, socket, errors }) => {

    const { buttons } = config;
    const anyFavorites = buttons ? Object.values(buttons)?.some((button) => button?.favorite && button?.enabled) : {};

    return (
        <div className="interactive-buttons-favorite" id="interactive_b_f">
            <div className="interactive-buttons-favorite-name">{
                anyFavorites &&  <><MdOutlineStar color="yellow" size={"13px"}/>Favorite Graphics</>
            }
            </div>
            {
                buttons ?
                anyFavorites &&
                Object.entries(buttons).filter((button) =>
                    button[1].favorite && button[1].enabled &&
                    designData[config.style].graphics.includes(button[0])
                ).map((button, index) =>
                    <InteractiveButton
                        buttonData={button}
                        socket={socket}
                        config={config}
                        errors={errors}
                        key={`interactive-bttn-fav-${index}`}
                    />
                )
                : Array(5).fill().map((_, index) =>
                    <SkeletonButton key={`skeleton-bttn-${index}`} />
                )
            }
        </div>
    );
};

const AvailableButtons = ({ config, socket, errors }) => {
    const { buttons } = config;
    const el = document.getElementById("interactive_b_f");

    return (
        <div className="interactive-buttons-available">
            <div
                className="interactive-buttons-available-name"
                style={{left: el?.style.left}}
            >
                Enabled Graphics
            </div>
            {
                buttons ?
                Object.entries(buttons).filter((button) =>
                    !button[1].favorite && button[1].enabled &&
                    designData[config.style].graphics.includes(button[0])
                ).map((button, index) =>
                    <InteractiveButton
                        buttonData={button}
                        socket={socket}
                        config={config}
                        errors={errors}
                        key={`interactive-bttn-enabled-${index}`}
                    />
                )
                :
                Array(6).fill().map((_, index) =>
                    <SkeletonButton key={`skeleton-bttn-${index}`} />
                )
            }
        </div>
    );
};


const InteractiveButtons = ({ config, socket }) => {

    const errors = useButtonValidator();

    return (
        <div className="interactive-buttons-wrapper">
            <div className="interactive-buttons-component">
                <div className="interactive-buttons-content">
                    <FavoriteButtons config={config} socket={socket} errors={errors}/>
                    <hr />
                    <AvailableButtons config={config} socket={socket} errors={errors}/>
                </div>
            </div>
        </div>
    );
}

export default InteractiveButtons;