
export const pageNames = {
    LiveFeed: "LIVE_FEED",
    ViewEnabled: "ENABLED",
    StartGrid: "START_GRID",
    PositionBattles: "POS_BATTLES",
    GraphicsStyles: "GRAPHICS_STYLES",
    MyDesigns: "DESIGNS",
    CompetitorImages: "COMPETITOR_IMG",
    TrackSelector: "TRACK_SELECT",
    ChangeLog: "CHANGE_LOG",
    Help: "HELP",
    AvailableRaces: "AVAIL_RACES",
    VerticalScoreboardDataType: "SET_VS_DATA_TYPE",
    ResultsByClass: "SET_RESULTS_BY_CLASS",
    AnnouncerSelector: "SET_ANNOUNCER",
    WeatherSelector: "SET_WEATHER",
    GraphicsSelector: "SET_AVAIL_GRAPHICS",
    GraphicsPosition: "SET_GRAPHICS_POS",
    GraphicsColors: "SET_GRAPHICS_COL"
};
