
import "./ResultsByClass.scss";
import React, { useState, useEffect } from "react";
import { classNameColors,classNameColorsM1 } from "../../config/const";

import { AnimatePresence, motion } from "framer-motion";

const ITEMS_PER_PAGE = 10;

const ResultsByClass = (props) => {

    const [currentPage, setCurrentPage] = useState(1);

    const selectedClass = props.config?.selectedClass;


    const totalPages = Math.ceil(
        props.data.data?.competitorsByClass[selectedClass]?.length / ITEMS_PER_PAGE
    );
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const pageItems =  props.data.data?.competitorsByClass[selectedClass]?.slice(
        startIndex,
        endIndex
    );

    useEffect(() => {
        const interval = setInterval(() => {
        setCurrentPage((currentPage) => (currentPage % totalPages) + 1);
        }, 4000);
        return () => clearInterval(interval);
    }, [currentPage, totalPages]);

    if (!selectedClass) {
        return null;
    }

    switch (props.config?.style) {
        case "v2":
            return (
                <motion.div
                    className="app__class-v2-main"
                    style={{
                        top: props?.preview ? "30px" : props?.config?.buttons?.resultsByClass?.top,
                        left: props?.preview ? "30px" : props?.config?.buttons?.resultsByClass?.left
                    }}
                >
                    <span
                        style={{
                            background: props?.config?.buttons?.resultsByClass?.colors?.resultsByClassHead?.color,
                            color: props?.config?.buttons?.resultsByClass?.colors?.resultsByClassHeadFont?.color
                        }}
                    >RESULTS BY CLASS</span>
                    <p
                        style={{
                            background: props?.config?.buttons?.resultsByClass?.colors?.classNameColor?.color,
                            color: props?.config?.buttons?.resultsByClass?.colors?.classNameFont?.color
                        }}
                    >{selectedClass}</p>
                    {
                        pageItems && pageItems.map((item, index) =>
                        <AnimatePresence>
                            <div
                                className="app__class_v2_item"
                                key={"results_by_class_v2_item"+index}
                                style={{
                                    background: props?.config?.buttons?.resultsByClass?.colors?.competitorBox?.color,
                                    color: props?.config?.buttons?.resultsByClass?.colors?.competitorBoxFont?.color
                                }}
                            >
                                <div className="app__class_v2_idx">
                                {(index + 1) + ((currentPage - 1) * 10)}
                                </div>
                                <div className="app__class_v2_pos">
                                    P{item.position}
                                </div>
                                <div className="app__class_v2_flag">
                                {item.state ?
                                    <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                    : <div className="img_placeholder" />
                                }
                                </div>
                                <div className="app__class_v2_name">
                                    {item.lastname}
                                </div>
                            </div>
                        </AnimatePresence>
                        )
                    }
                </motion.div>
            );
        case "k1":
            return (
                <motion.div
                    className="k1_class_results"
                    style={{
                        top: props?.preview ? "30px" : props?.config?.buttons?.resultsByClass?.top,
                        left: props?.preview ? "30px" : props?.config?.buttons?.resultsByClass?.left
                    }}
                >
                    <motion.div className="k1_class_results_title">RESULTS BY CLASS</motion.div>
                    <motion.div className="k1_class_results_class" style={{background: classNameColors?.[props?.config?.competitorClasses?.indexOf(selectedClass)]}}>{selectedClass}</motion.div>
                    {
                        pageItems && pageItems.map((item, index) =>
                            <div className="k1_class_results_item" key={"results_by_class_k1_item"+index}>
                                <div className="k1_class_item_left">
                                    <div className="k1_battle_pos">
                                        {(index + 1) + ((currentPage - 1) * 10)}
                                    </div>
                                    <div className="k1_class_item_pos2">
                                        P{item.position}
                                    </div>
                                </div>
                                <div className="k1_battle_name">
                                    {item.lastname}
                                </div>
                            </div>
                        )
                    }
                </motion.div>
            );
        case "m1":
            return (
                <motion.div
                    className="m1_class_results"
                    style={{
                        top: props?.preview ? "30px" : props?.config?.buttons?.resultsByClass?.top,
                        left: props?.preview ? "30px" : props?.config?.buttons?.resultsByClass?.left
                    }}
                >
                    <motion.div className="m1_class_head">
                        <div className="m1_class_head_back_2"/>
                        <motion.div
                            className="m1_class_head_back"
                            style={{background: classNameColorsM1?.[props?.config?.competitorClasses?.indexOf(selectedClass)]}}
                        />
                        <motion.div className="m1_class_head_front">
                            <motion.div className="m1_class_head_front_title">RESULTS BY CLASS</motion.div>
                            <motion.div
                                className="m1_class_head_front_class"
                            >{selectedClass}</motion.div>
                        </motion.div>
                    </motion.div>
                    {
                        pageItems && pageItems.map((item, index) =>
                            <div className="m1_class_results_item" key={"results_by_class_m1_item"+item?.nr}>
                                <div className="m1_battle_pos">
                                    {(index + 1) + ((currentPage - 1) * 10)}
                                </div>
                                <div className="m1_class_item_pos2">
                                    P{item?.position}
                                </div>
                                <div className="m1_battle_name">
                                    <p>{item?.isSidecar ? `${item?.firstname}${item?.lastname}` : item?.lastname}</p>
                                </div>
                            </div>
                        )
                    }
                </motion.div>
            );
    }
};
export default ResultsByClass;