
import "./StartGridGraphic.scss";
import React, { useState, useEffect, useMemo } from 'react';
import { motion, AnimatePresence } from "framer-motion";
import { getSuffix } from "../../Functions";
import { getRaceBackendAddress } from "../../Functions";
import { classNameColorsM1 } from "../../config/const";
// Framer configuration

const container = {
    hidden: {opacity: 0},
    show: {
        opacity: 1,
    },
    leave: {
        opacity: 0
    }
}

const containerChilds = {
    hidden: {
        opacity: 0, x: -500, display: "none"
    },
    show: {
        opacity: 1, x: 0, display: "flex",transition: {delay: 0.3, bounce: false}
    },
    leave: {
        opacity: 0, x: 1000, transition: {duration: 0.001}
    }
};

const containerLine = {
    hidden: {opacity: 0},
    show: {
        opacity: 1,
        transition: {staggerChildren: 0.5, when: "beforeChildren", delay: 2}
    },
    leave: {
        opacity: 0,
        transition: {staggerChildren: 0.1, duration: 1.2}
    }
}

const containerChildsLine = {
    hidden: {
        opacity: 0, y: -500
    },
    show: {
        opacity: 1, y: 0,
    },
    leave: {
        y: -500
    }
};

const container_k1 = {
    hidden: {opacity: 0},
    show: {opacity: 1, transition: {staggerChildren: 0.6, delayChildren: 0.8}},
    leave: {opacity: 0, transition: {duration: 0.4}}
};
const container_k1_2 = {
    hidden: {opacity: 0},
    show: {opacity: 1, transition: {staggerChildren: 0.6, delayChildren: 1.1}},
    leave: {opacity: 0, transition: {duration: 0.4}}
};
const container_k1_childs = {
    hidden: {clipPath: 'inset(0 100% 0 0)', backgroundColor: "#124fc1", transition: {delay: 0.2}},
    show: {clipPath: 'inset(0 0% 0 0)', backgroundColor: "transparent", transition: {delayChildren: 0.5}},
    leave: {clipPath: 'inset(0 100% 0 0)', transition: {duration: 0.001}}
};

const GraphicItemColumn = ({item, index, competitorColumns, top, style, side}) => {
    switch (style) {
        case "v2":
            return (
                <AnimatePresence>
                    <motion.li
                        className="app__v2-item-col"
                        key={"v2_start_item" + item?.nr}
                        style={{marginBottom: "30px"}}
                        initial={{opacity: 0, scale: 0, display: "none"}}
                        animate={{display: "flex", opacity: [0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1], scale: [0,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1], transition: {duration: 8}}}
                        exit={{display: "none"}}
                    >
                        <div className="app__v2-item-col-top">
                            <div className="app_v2-item-col-nr">
                                {item?.position}
                            </div>
                            <div className="app__v2-item-col-flag">
                                {item?.state ?
                                    <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                    : <div className="img_placeholder" />
                                }
                            </div>
                            <div className="app__v2-item-col-name">
                                <p>{item?.firstname?.toLowerCase()}</p> {` ${item?.lastname}`}
                            </div>
                            <div className="app__v2-item-col-class">
                                {item?.nr}
                            </div>
                        </div>
                        <div className="app__v2-item-col-bottom">
                            <p>{item?.team}</p>
                            <span>{item?.class}</span>
                        </div>

                    </motion.li>
                </AnimatePresence>
            );
    }
}
const GraphicItemRow = ({perRow, item, style, config, imageError, setImageError, index, rowNumber}) => {
    switch (style) {
        case "v2":
        return (
            <AnimatePresence>
                <motion.li
                    className="app__v2-item-col index"
                    key={item?.nr}
                    style={{width: `${100 / Number(perRow) - 1}%`}}
                    initial={{opacity: 0, scale: 0}}
                    animate={{opacity: 1, scale: 1, transition: {delay: (rowNumber + 1 * 1.2) + index * 0.3, duration: 0.3}}}
                    exit={{opacity: 0, scale: 0}}
                >
                    {
                        config?.useCompetitorImages &&
                        <motion.div
                            className="k1_competitor_image"
                            initial={{background: "transparent"}}
                            animate={{background: "radial-gradient(at bottom,#8d8d8d,transparent 70%)", transition: {delay: .1 + (rowNumber + 1 * 1.2) + index * 0.3}}}
                        >
                            <motion.img
                                key={"k1_col_item_image_" + item?.nr}
                                src={imageError[item?.nr] ? "/competitors/none.png" : `${getRaceBackendAddress()}/competitor_images/${config?.userId}/${item?.firstname?.toLowerCase()}${item?.lastname?.toLowerCase()}.png`}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    setImageError(prevState => ({...prevState, [item?.nr]: true}));
                                }}
                                initial={{clipPath: 'inset(100% 0 0 0)', filter: "brightness(0)"}}
                                animate={{clipPath: 'inset(0% 0 0 0)', filter: "brightness(1)", transition: {delay: .4 + (rowNumber + 1 * 1.2) + index * 0.3, duration: 0.8}}}
                            />
                        </motion.div>
                    }
                    <div className="app__v2-item-col-top">
                        <div className="app_v2-item-col-nr-small">
                            {item?.position}
                        </div>
                        <div className="app__v2-item-col-flag-small">
                            {item?.state ?
                                <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                : <div className="img_placeholder" />
                            }
                        </div>
                        <div className="app__v2-item-col-name-small">
                            {`${item?.firstname} ${item?.lastname}`}
                        </div>
                        <div className="app__v2-item-col-class">
                            {item?.nr}
                        </div>
                    </div>
                    <div className="app__v2-item-col-bottom">
                        <p>{item?.team}</p>
                    </div>

                </motion.li>
            </AnimatePresence>
        );
    }
}

const GraphicItemLine = ({item}) => {
    return (
        <AnimatePresence>
            <motion.li
                className="app__graphics-start-grid-graphic-line-item"
                key={item.nr}
                variants={containerChildsLine}
                exit={containerChildsLine.leave}
            >
                <div className="app__graphics-start-grid-graphic-line-item-pos">
                    {item.position}
                </div>
                <div className="app__graphics-start-grid-graphic-line-item-nr">
                </div>
                <div className="app__graphics-start-grid-graphic-line-item-content">
                    <img src={"/images/helmet2.png"}  alt="" />
                    <div className="app__graphics-start-grid-graphic-line-item-content-bottom">
                        <p>{item.team}</p>
                        <h4>{item.make}</h4>
                    </div>
                    <div className="app__graphics-start-grid-graphic-line-name">{item.firstname}</div>
                    <div className="app__graphics-start-grid-graphic-line-surname">{item.lastname}</div>
                    <div className="app__graphics-start-grid-graphic-line-startnr">#{item.nr}</div>
                    <div className="app__graphics-start-grid-graphic-line-flag">{
                        item.state ?
                        <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                        : <div className="img_placeholder" />
                    }</div>
                </div>
            </motion.li>
        </AnimatePresence>
    );
};

const GraphicItemBoard = ({makeMap, item, style, idx, config}) => {
    switch(style) {
        case "v2":
            return (
                <AnimatePresence>
                    <motion.li
                        className="app__graphics-start-grid-v2-board-item"
                        key={"grid_v2_motocross" + item?.nr}
                        variants={containerChilds}
                        exit={containerChilds.leave}
                    >
                        <div className="app__graphics-start-grid-v2-board-item-front">
                            <div className="app__graphics-start-grid-v2-board-item-pos">
                                {item?.position}
                            </div>
                            <div className="app__graphics-start-grid-v2-board-item-nr">
                                {item?.nr}
                            </div>
                        </div>
                        <div className="app__graphics-start-grid-v2-board-item-mid">
                            {
                                item?.isSidecar ?
                                <div className="app__graphics-start-grid-v2-board-item-sidecars">
                                    <div className="app__graphics-start-grid-v2-board-item-sidecars-comp">
                                        {item.state ?
                                        <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                        : <div className="img_placeholder" />
                                        }
                                        <p>{item?.firstname?.slice(0, -1).substring(2, item?.firstname?.length)}</p>
                                    </div>
                                    <seperator>/</seperator>
                                    <div className="app__graphics-start-grid-v2-board-item-sidecars-comp">
                                        {item.state2 ?
                                        <img src={`https://flagcdn.com/${item.state2.toLowerCase()}.svg`} alt="-" />
                                        : <div className="img_placeholder" />
                                        }
                                        <p>{item?.lastname?.substring(2, item?.lastname?.length)}</p>
                                    </div>
                                </div>
                                :
                                <div className="app__graphics-start-grid-v2-board-item-regular">
                                    {item.state ?
                                        <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                        : <div className="img_placeholder" />
                                    }
                                    <p>{item?.firstname} </p>
                                    {item?.lastname}
                                </div>
                            }
                        </div>
                        <span>{item?.class}</span>
                        <div
                            className="app__graphics-start-grid-v2-board-item-end"
                            style={{background: item?.make && item?.make in makeMap ? makeMap[item.make].background : "#050505"}}
                        >
                            {
                                item?.make && item?.make in makeMap ?
                                <img src={`/makes/${makeMap[item.make].img}`}></img>
                                : null
                            }
                        </div>
                    </motion.li>
                </AnimatePresence>
            );
        case "m1": {
            return (
                <AnimatePresence>
                    <motion.li
                        layout
                        className="m1_start_grid_item"
                        key={"m1_start_grid_item"+item?.nr}
                        initial={{opacity: 0}}
                        animate={{opacity: 1, transition: {delay: 0.2 * idx, duration: 0.2}}}
                    >
                        <motion.div
                            className="m1_end_grid_item_content_class"
                        >
                            <p>{item?.class}</p>
                        </motion.div>
                        <motion.div
                            className="m1_end_grid_item_content_class_color"
                            style={{background: classNameColorsM1?.[config?.competitorClasses?.indexOf(item?.class)]}}
                        />
                        <motion.div className="m1_start_grid_item_pos"><p>{item?.position}</p></motion.div>
                        <motion.div className="m1_start_grid_nr">{item?.nr}</motion.div>
                        <motion.div
                            className="m1_end_grid_item_content"
                        >
                            <motion.div className="m1_end_grid_item_content_top">
                            {
                                item?.isSidecar ?
                                <div className="m1_end_grid_item_content_top_sidecar">
                                    <div className="m1_end_grid_item_content_top_sidecar_row">
                                        <p>{`${item?.firstname.slice(0, -1)} / ${item?.lastname}`}</p>
                                        <div className="m1_end_grid_item_content_top_sidecar_flags">
                                        {item.state ?
                                            <div className="m1_end_grid_item_content_top_sidecar_flag">
                                                <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                            </div>
                                            : <div className="img_placeholder" />
                                        }
                                        {item.state2 ?
                                            <div className="m1_end_grid_item_content_top_sidecar_flag">
                                                <img src={`https://flagcdn.com/${item.state2.toLowerCase()}.svg`} alt="-" />
                                            </div>
                                            : <div className="img_placeholder" />
                                        }
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className="m1_end_grid_item_content_top_regular">
                                    <div className="m1_end_grid_item_content_top_regular_name">
                                        <p>{item?.firstname}</p>
                                        <span>{item?.lastname}</span>
                                    </div>
                                    <div className="m1_end_grid_item_content_top_regular_flag">
                                    {item.state ?
                                        <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                        : <div className="img_placeholder" />
                                    }
                                    </div>
                                </div>
                            }
                            </motion.div>
                        </motion.div>
                    </motion.li>
                </AnimatePresence>
            );
        }
    }
};

const GenerateK1Grid = React.memo(({side = "left", data, competitorIndex, config, counter}) => {

    const [imageError, setImageError] = useState({});

    const competitorColumns = Array.from({length: 2}, () => []);
    data?.data?.allCompetitors?.forEach((competitor, i) => {
        const columnIndex = i % 2;
        competitorColumns[columnIndex]?.push(competitor);
    });

    const displayCompetitors = data?.data?.allCompetitors?.length;
    const currentIndex = competitorIndex + 4;
    const classNameColors = ["#00aca0", "#023a90", "#e9679e", "#facd06", "#f58300", "#d9030b"];

    const generateColumn = useMemo(() => {
        return (column, i) => (
                <motion.ol
                    className="k1_column"
                    key={"k1_column_" + i}
                    variants={i === 0 ? container_k1 : container_k1_2}
                    initial="hidden"
                    animate="show"
                    exit="leave"
                    layout
                >
                    <AnimatePresence>
                    {
                        column.filter((item, index) => {
                            const isIncluded = item.position <= displayCompetitors && index > competitorIndex && index <= currentIndex;
                            return isIncluded;
                        }).map((item, idx) =>
                                <motion.li
                                    className="k1_column_item"
                                    key={"k1_col_item" + item?.nr + "_" + idx + counter}
                                    style={{marginTop: `${(() => {
                                        if (idx === 0) {
                                            return ("30");
                                        } else {
                                            return ("20");
                                        }
                                    })()}px`, marginBottom: config?.useCompetitorImages ? "5px" : "30px"
                                    }}
                                    variants={container_k1_childs}
                                    layout
                                >
                                    {
                                        config?.useCompetitorImages &&
                                        <motion.div
                                            className="k1_competitor_image"
                                            initial={{background: "transparent"}}
                                            animate={{background: "radial-gradient(at bottom,#8d8d8d,transparent 70%)", transition: {delay: 1.2 + (idx * 0.6) + (i * 0.3)}}}
                                        >
                                            <motion.img
                                                key={"k1_col_item_image_" + idx}
                                                src={imageError[item?.nr] ? "/competitors/none.png" : `${getRaceBackendAddress()}/competitor_images/${config?.userId}/${item?.firstname?.toLowerCase()}${item?.lastname?.toLowerCase()}.png`}
                                                onError={(e) => {
                                                    e.target.onerror = null;
                                                    setImageError(prevState => ({...prevState, [item?.nr]: true}));
                                                }}
                                                initial={{clipPath: 'inset(100% 0 0 0)', filter: "brightness(0)"}}
                                                animate={{clipPath: 'inset(0% 0 0 0)', filter: "brightness(1)", transition: {delay: 1.9 + (idx * 0.6) + (i * 0.3), duration: 0.8}}}
                                            />
                                            <motion.p
                                                initial={{opacity: 0}}
                                                animate={{opacity: 1, transition: {delay: 1.5 + (idx * 0.6) + (i * 0.3)}}}
                                            >{item?.position}<span>{getSuffix(item?.position)}</span></motion.p>
                                        </motion.div>
                                    }
                                    <motion.div
                                        className="k1_column_item_top"
                                        initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#124fc1"}}
                                        animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: "#0f0f14", transition: {delay: 1.2 + (idx * 0.6) + (i * 0.3)}}}
                                    >
                                        <div className="k1_battle_pos">
                                            {config?.useCompetitorImages ? item?.nr : item?.position}
                                        </div>
                                        <div className="k1_battle_className" style={{background: classNameColors?.[config?.competitorClasses?.indexOf(item?.class)]}}/>
                                        <div className="k1_battle_flag">
                                            {item?.state ?
                                                <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                                : <div className="img_placeholder" />
                                            }
                                        </div>
                                        <div className="k1_battle_name">
                                            <p>{item?.firstname}</p> {` ${item?.lastname}`}
                                        </div>

                                    </motion.div>
                                    <motion.div
                                        className="k1_column_item_bottom"
                                        initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#124fc1"}}
                                        animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: "#44445e", transition: {delay: 1.6 + (idx * 0.6) + (i * 0.3)}}}
                                    >
                                        <p>{item?.team}</p>
                                    </motion.div>

                                </motion.li>
                        )
                    }
                    </AnimatePresence>
                </motion.ol>
        );
    }, [competitorColumns, displayCompetitors, competitorIndex, config]);
    return side === "right" ? competitorColumns.map(generateColumn) : competitorColumns.reverse().map(generateColumn);
});

const columnMarginGen = (side, idx, max) => {
    if (side === "left") {
        return (idx * 30).toString() + "px";
    }
    return ((max - idx) * 30).toString() + "px";
}


const StartGridGraphic = ({data, config, preview}) => {

    const [competitorIndex, setCompetitorIndex] = useState(-1);
    const [counterIndex, setCounterIndex] = useState(1);
    const [counterIndexBoard, setCounterIndexBoard] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const competitors = data?.data?.allCompetitors?.length;
            const maxLoops = competitors / (config?.buttons?.startGrid?.configuration?.columns * 4);
            const maxLoopsBoard = competitors;

            setCounterIndex(counterIndex + 1);

            if (counterIndexBoard + 15 >= maxLoopsBoard) {
                setCounterIndexBoard(0);
            } else {
                setCounterIndexBoard(counterIndexBoard + 15);
            }

            if (counterIndex >= maxLoops) {
                setCompetitorIndex(-1);
                setCounterIndex(1);
            } else {
                setCompetitorIndex(competitorIndex + 4);
            }
        }, 10000);

        return () => {
            clearInterval(intervalId);
        };
    }, [competitorIndex]);

    const generateStartGridColumn = (columns = 2, side = "left", displayCompetitors = 100) => {
        const competitorColumns = Array.from({length: columns}, () => []);

        data?.data?.allCompetitors?.forEach((competitor, i) => {
            const columnIndex = i % columns;
            competitorColumns[columnIndex].push(competitor);
        });

        displayCompetitors = data?.data?.allCompetitors?.length;
        const currentIndex = competitorIndex + 4;

        const generateColumn = (column, i) => (
            <AnimatePresence>
                <motion.ol className="app__admin-start-grid-graphic-column"
                    variants={container}
                    initial="hidden"
                    animate="show"
                    exit="leave"
                    style={{marginTop: columnMarginGen(side, i, competitorColumns.length)}}
                >
                    {
                        column.filter((item, index) => {
                            const isIncluded = item.position <= displayCompetitors && index > competitorIndex && index <= currentIndex;
                            return isIncluded;
                        }).map((item, idx) =>
                                <GraphicItemColumn key={`start_grid_col_${idx}`} item={item} index={i} competitorColumns={competitorColumns} side={side} top={idx === 0} style={config?.style}/>
                        )
                    }
                </motion.ol>
            </AnimatePresence>
        );

        return side === "left" ? competitorColumns.map(generateColumn) : competitorColumns.reverse().map(generateColumn);
    };

    const GenerateStartGridRows = (perRow, side = "right", displayCompetitors = 17) => {

        const [imageError, setImageError] = useState({});

        const rowArray = [];
        let currentRow = [];
        let rows = 0;

        displayCompetitors = data?.data?.allCompetitors?.length;

        for (let i = 0; i < data?.data?.allCompetitors?.length; i++) {
            currentRow.push(data?.data?.allCompetitors?.[i]);
            if (currentRow.length === Number(perRow) || i === data?.data?.allCompetitors?.length - 1) {
                rows++;
                if (rows > 4) { rows = 0; }
                if (side === "right") {
                    currentRow.reverse();
                }
                let rowStyle = {width: "86%", marginBottom: config?.useCompetitorImages ? "5px" : "30px"};
                if (side === "right") {
                    if (rowArray.length % 2 !== 0) {
                        rowStyle = {...rowStyle, marginLeft: `70px`, justifyContent: "flex-end"}
                    } else {
                        rowStyle = {...rowStyle, marginLeft: `140px`}
                    }
                } else {
                    if (rowArray.length % 2 !== 0) {
                        rowStyle = {...rowStyle,  marginLeft: `140px`}
                    } else {
                        rowStyle = {...rowStyle, marginLeft: `70px`}
                    }
                }

                rowArray.push(
                    <AnimatePresence>
                        <motion.ol className="app__admin-start-grid-graphic-row"
                            style={rowStyle}
                            variants={container}
                            initial="hidden"
                            key={"start-grid-row"+rows}
                            animate={{opacity: 1, transition: {staggerChildren: 0, when: "beforeChildren", delay: rows * 1}}}
                            layout
                        >
                            {
                                currentRow.filter(e => e.position <= displayCompetitors).map((item, index) =>
                                    <GraphicItemRow perRow={perRow} item={item} style={config?.style} config={config}
                                        imageError={imageError} setImageError={setImageError} index={index} rowNumber={rows}
                                    />
                                )
                            }
                        </motion.ol>
                        <hr/>
                    </AnimatePresence>
                );
                currentRow = [];
            }
        }
        const slicedArray = rowArray.slice(competitorIndex + 1, competitorIndex + 5);
        return slicedArray;
    };

    const generateStartGridLine = (side = "right", displayCompetitors = 17) => {
        let competitorArray = [];
        for (let i = 0; i < data?.data?.allCompetitors?.length; i++) {
            competitorArray.push(data?.data?.allCompetitors?.[i]);
        }
        if (side === "right") { competitorArray.reverse(); }
        return [
            <motion.div className="app__graphics-start-grid-graphic-line">
                <motion.div
                    className="app__graphics-start-grid-graphic-line-title"
                    initial={{opacity: 0, x: -1000}}
                    animate={{opacity: 1, x: 0}}
                    transition={{delay: 1}}
                >
                    QUALIFICATION
                </motion.div>
                <div className="app__graphics-start-grid-graphic-start-line" />
                <AnimatePresence>
                    <motion.ol
                        className="app__graphics-start-grid-graphic-line-content"
                        variants={containerLine}
                        initial="hidden"
                        animate="show"
                        exit="leave"
                    >
                    {
                        competitorArray.filter(e => e.position <= displayCompetitors && e.position <= 6).map((item, index) =>
                            <GraphicItemLine item={item} />
                        )
                    }
                    </motion.ol>
                </AnimatePresence>
            </motion.div>
        ];
    };

    const generateStartGridBoard = (displayCompetitors) => {
        let competitorArray = [];
        for (let i = 0; i < data?.data?.allCompetitors?.length; i++) {
            competitorArray.push(data?.data?.allCompetitors?.[i]);
        }
        const makeBackground = {
            light: "#f4f4f4",
            dark: "#050505"
        }

        const makeMap = {
            "KTM": {img: "ktm.svg", background: makeBackground.dark},
            "SUZUKI": {img: "suzuki.svg", background: makeBackground.light},
            "HONDA": {img: "honda.svg", background:  makeBackground.dark},
            "KAWASAKI": {img: "kawasaki.jpg", background:  makeBackground.dark},
            "YAMAHA": {img: "yamaha.svg", background:  makeBackground.dark},
            "HUSQVARNA": {img: "husqvarna.png", background:  makeBackground.dark},
            "GAS GAS": {img: "gasgas.png", background:  makeBackground.dark},
            "HUSABERG": {img: "husaberg.png", background: makeBackground.dark}
        };

        return [
            <AnimatePresence>
                <motion.ol
                    className="app__graphics-start-grid-graphic-board"
                >
                {

                    competitorArray.filter((e, idx) => e.position <= displayCompetitors
                        && idx >= counterIndexBoard && counterIndexBoard + 15 > idx
                    ).map((item, index) =>
                        <GraphicItemBoard makeMap={makeMap} item={item} style={config?.style} idx={index} config={config}/>
                    )
                }
                </motion.ol>
            </AnimatePresence>
        ];
    };

    const functionMap = {
        1: generateStartGridColumn(config?.buttons?.startGrid?.configuration?.columns,
            config?.buttons?.startGrid?.configuration?.side,
            config?.buttons?.startGrid?.configuration?.displayCompetitors
        ),
        2: GenerateStartGridRows(config?.buttons?.startGrid?.configuration?.lines,
            config?.buttons?.startGrid?.configuration?.side,
            config?.buttons?.startGrid?.configuration?.displayCompetitors
        ),
        3: generateStartGridLine(
            config?.buttons?.startGrid?.configuration?.side,
            config?.buttons?.startGrid?.configuration?.displayCompetitors
        ),
        5: generateStartGridBoard(
            config?.buttons?.startGrid?.configuration?.displayCompetitors
        )
    };

    const frameStyle = {
        flexDirection: config?.buttons?.startGrid?.configuration?.mode === "1" ? "row" : "column",
        height: config?.buttons?.startGrid?.configuration?.mode === "1" ? `fit-content` : "fit-content",
        paddingBottom: config?.buttons?.startGrid?.configuration?.mode === "1" ? "30px" : "0px"
    };

    switch (config?.style) {
        case "v2":
            return (
                <motion.div
                    className="app__v2-start-grid-main"
                    style={{ top: preview ? "30px" : config?.buttons?.startGrid?.top,
                            left: preview ? "30px" : config?.buttons?.startGrid?.left
                        }}
                    exit={{opacity: 0, scale: 0}}
                >
                    <motion.div
                        className="app__v2-start-grid-title"
                        initial={{opacity: 0, scaleX: 0}}
                        animate={{opacity: 1, scaleX: 1}}
                        transition={{bounce: false, delay: 0.3, duration: 0.2}}
                        style={{color: config?.buttons?.startGrid?.colors?.startGridName?.color}}
                    >
                        <hr style={{background: config?.buttons?.startGrid?.colors?.startGridName?.color}}/>
                        STARTING GRID
                        <hr style={{background: config?.buttons?.startGrid?.colors?.startGridName?.color}}/>
                    </motion.div>
                    <motion.div
                        className="app__v2-start-grid-name"
                        initial={{opacity: 0, scaleX: 0}}
                        animate={{opacity: 1, scaleX: 1}}
                        transition={{bounce: false, delay: 0.7, duration: 0.2}}
                        style={{
                            background: config?.buttons?.startGrid?.colors?.startGridRunName?.color,
                            color: config?.buttons?.startGrid?.colors?.startGridRunFont?.color
                        }}
                    >
                        {data?.data?.raceDetails?.name}
                    </motion.div>
                    <motion.ol
                        className="app__v2-start-grid-content"
                        style={frameStyle}
                        initial={{opacity: 0, scale: 0}}
                        animate={{opacity: 1, scale: 1}}
                        transition={{bounce: false}}
                    >
                    {
                        functionMap[config?.buttons?.startGrid?.configuration?.mode]
                    }
                    </motion.ol>
                </motion.div>
            );
        case "k1":
            return (
                <motion.div
                    className="k1_start_grid"
                    style={{ top: preview ? "30px" : config?.buttons?.startGrid?.top,
                        left: preview ? "30px" : config?.buttons?.startGrid?.left
                    }}
                >
                    <motion.div
                        className="k1_start_grid_title"
                        initial={{scaleX: 0, backgroundColor: "#124fc1"}}
                        animate={{scaleX: 1, backgroundColor: "#1b1e23f9", transition: {duration: 0.25}}}
                    >
                        <p>STARTING GRID</p>
                    </motion.div>
                    <motion.div
                        className="k1_start_grid_name"
                        initial={{clipPath: 'inset(0 0 100% 0)'}}
                        animate={{clipPath: 'inset(0 0 0% 0)', transition: {delay: 0.3, duration: 0.2}}}
                    >
                        <p>{data?.data?.raceDetails?.name}</p>
                    </motion.div>
                    <motion.div
                        className="k1_start_grid_line"
                        initial={{clipPath: 'inset(0 0 100% 0)'}}
                        animate={{clipPath: 'inset(0 0 0% 0)', transition: {delay: 0.5, duration: 0.1}}}
                    />
                    <motion.div
                        key={counterIndexBoard}
                        className="k1_start_grid_content"
                        initial={{clipPath: 'inset(0 0 100% 0)'}}
                        animate={{clipPath: 'inset(0 0 0% 0)', transition: {delay: 0.7, duration: 0.3}}}
                        exit={{clipPath: 'inset(0 0 100% 0)'}}
                        layout
                    >
                        <GenerateK1Grid
                            side={config?.buttons?.startGrid?.configuration?.side}
                            data={data}
                            config={config}
                            competitorIndex={competitorIndex}
                            counter={counterIndexBoard}
                        />
                    </motion.div>
                </motion.div>
            );
        case "m1": {
            return (
                <motion.div
                    className="m1_start_grid"
                    style={{
                        top: preview ? "30px" : config?.buttons?.startGrid?.top,
                        left: preview ? "30px" : config?.buttons?.startGrid?.left
                    }}
                >
                    <motion.div className="m1_start_grid_top">
                        <motion.div
                            className="m1_start_grid_top_background"
                            initial={{ scaleX: 0, background: "#b13434" }}
                            animate={{ scaleX: 1, background: "#ffffff" }}
                            transition={{ duration: 0.3, ease: "linear" }}
                        />
                        <motion.div
                            className="m1_start_grid_top_items"
                            initial={{ scaleX: 0 }}
                            animate={{ scaleX: 1 }}
                            transition={{ duration: 0.3, ease: "linear", delay: 0.05}}
                        >
                            <motion.div className="m1_start_grid_title">
                                <p>STARTING GRID</p>
                            </motion.div>
                            <motion.div className="m1_start_grid_name">
                                <p>{data?.data?.raceDetails?.name}</p>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                    <motion.ol
                        className="m1_start_grid_content"
                        style={frameStyle}
                        initial={{opacity: 0, scale: 0}}
                        animate={{opacity: 1, scale: 1}}
                        transition={{bounce: false}}
                    >
                    {
                        generateStartGridBoard(100)
                    }
                    </motion.ol>
                </motion.div>
            );
        }
        }
};

export default StartGridGraphic;