
import "./RaceWinner.scss";
import { BsFillTrophyFill } from "react-icons/bs";
import { motion } from "framer-motion";
import { getRaceBackendAddress } from "../../Functions";

const RaceWinnerWidget = (props) => {
    switch (props.config?.style) {
        case "v2":
            return (
                <motion.div
                    className="app__winner-v2-main"
                    style={{
                        top: props?.config?.buttons?.winner?.top,
                        left: props?.config?.buttons?.winner?.left
                    }}
                >
                    <motion.div className="app__winner-v2-top">
                        <hr />
                        <BsFillTrophyFill className="ml3" size={25} color={'#ffda05'} />
                        {`RACE WINNER`}
                        <hr />
                    </motion.div>
                    <motion.div className="app__winner-v2-bottom">
                        <div className="app__winner-v2-bottom-t">
                            <div className="app__winner-v2-nr">
                                <p>{props.winner?.nr}</p>
                            </div>
                            {
                                props.winner?.isSidecar ?
                                <div className="app__winner-v2-flag-sidecar">
                                    {props.winner?.state ?
                                        <img src={`https://flagcdn.com/${props.winner?.state?.toLowerCase()}.svg`} alt="-" />
                                        : <div className="img_placeholder" />
                                    }
                                    {props.winner?.state2 ?
                                        <img src={`https://flagcdn.com/${props.winner?.state2?.toLowerCase()}.svg`} alt="-" />
                                        : <div className="img_placeholder" />
                                    }
                                </div>
                                :
                                <div className="app__winner-v2-flag">
                                    {props.winner?.state ?
                                        <img src={`https://flagcdn.com/${props.winner?.state?.toLowerCase()}.svg`} alt="-" />
                                        : <div className="img_placeholder" />
                                    }
                                </div>
                            }

                            <div className="app__winner-v2-name">
                                {`${props.winner?.firstname} ${props.winner?.lastname}`}
                            </div>
                            <div className="app__winner-v2-class">
                                {props.winner?.class}
                            </div>
                        </div>
                        <div className="app__winner-v2-bottom-b">
                            {`BEST TIME: ${props.winner?.best_lap_time}`}
                        </div>
                    </motion.div>
                </motion.div>
            );
        case "k1":
            const classNameColors = ["#00aca0", "#023a90", "#e9679e", "#facd06", "#f58300", "#d9030b"];
            return (
                <motion.div
                    className="k1_winner"
                    style={{
                        top: props?.config?.buttons?.winner?.top,
                        left: props?.config?.buttons?.winner?.left
                    }}
                >
                    {
                        props?.config?.useCompetitorImages &&
                        <motion.div
                            className="k1_winner_img"
                        >
                            <motion.img
                                key={"k1_winner_img"}
                                src={
                                `${getRaceBackendAddress()}/competitor_images/${props?.config?.userId}/${props.winner?.firstname?.toLowerCase()}${props.winner?.lastname?.toLowerCase()}.png`}
                                onError={(e) => {
                                    e.target.onerror = null;
                                    e.target.src = "/competitors/none.png";
                                }}
                                initial={{clipPath: 'inset(100% 0 0 0)', filter: "grayscale(100%)"}}
                                animate={{
                                    clipPath: 'inset(0% 0 0 0)',
                                    filter: "grayscale(0%)",
                                    transition: {
                                        clipPath: {delay: 0.5, duration: 0.8},
                                        filter: {delay: 1.3, duration: 0.8}
                                    }
                                }}
                            />
                        </motion.div>
                    }
                    <motion.div className="k1_winner_title">
                        <p><BsFillTrophyFill size={25} color={'#ffda05'} /></p>
                        <p>RACE WINNER</p>
                    </motion.div>
                    <motion.div className="k1_winner_name">
                        <div className="k1_battle_pos">
                            {props.winner?.nr}
                        </div>
                        <div className="k1_battle_className" style={{background: classNameColors?.[props?.config?.competitorClasses?.indexOf(props.winner?.class)]}}/>
                        <div className="k1_battle_flag">
                            {props.winner?.state ?
                                <img src={`https://flagcdn.com/${props.winner?.state.toLowerCase()}.svg`} alt="-" />
                                : <div className="img_placeholder" />
                            }
                        </div>
                        <div className="k1_battle_col">
                            <div className="k1_battle_row">
                                <div className="k1_battle_name">
                                    <p>{props.winner?.firstname}</p><span>{props.winner?.lastname}</span>
                                </div>
                            </div>
                            <div className="k1_battle_row">
                                <div className="k1_fastest_team">
                                    {props.winner?.team}
                                </div>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            );
    }
};
export default RaceWinnerWidget;