

class MessageStore {
    constructor() {
        this.messages = [];
        this.listeners = [];
    }

    addMessage(message) {
        if (this.messages.length >= 20) {
            this.messages.shift();
        }
        this.messages = [...this.messages, message];
        this.notifyListeners();
    }

    log(message) {
        this.messages = [...this.messages, {status: 200, message: message, parent: 100, msgtime: Date.now()}];
        this.notifyListeners();
    }

    getMessages() {
        return this.messages;
    }

    clearAllMessages() {
        this.messages = [];
        this.notifyListeners();
    }

    addListener(listener) {
        this.listeners.push(listener);
    }

    removeListener(listenerToRemove) {
        this.listeners = this.listeners.filter(listener => listener !== listenerToRemove);
    }

    notifyListeners() {
        this.listeners.forEach(listener => listener());
    }
}

// Export a singleton instance
export default new MessageStore();