import { useState, useEffect } from "react";
import Users from "./Components/Users";
import Activity from "./Components/Activity";
import { IoLogOut } from "react-icons/io5";

const AdministrateMain = ({ socket }) => {
    const [page, setPage] = useState(0);

    const pages = [
        {name: "Server activity"},
        {name: "All users"},
        {name: "Create user"}
    ];
    const components = [
        <Activity />,
        <Users />
    ];

    return (
        <div className="admin_panel_wrapper">
            <div className="admin_panel">
                <header><p>Administrative panel</p><button><IoLogOut size={"20px"}/></button></header>
                <hr/>
                <div className="admin_panel_screen">
                    <div className="admin_panel_menu">
                    {
                        pages.map((p, i) => {
                            return (
                                <div key={i} className="admin_panel_menu_item" onClick={() => setPage(i)}><p>{p.name}</p></div>
                            )
                        })
                    }
                    </div>
                    <div className="admin_panel_components">
                        { components[page] }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdministrateMain;