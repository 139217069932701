import "./RaceResults.scss";
import { AnimatePresence, motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { addPlusPrefixIfNeeded } from "../../Functions";
import { classNameColors, classNameColorsM1 } from "../../config/const";

const MAX_COMPETITORS_PER_PAGE = 15;

const container_k1 = {
    hidden: {clipPath: 'inset(0 0 100% 0)'},
    show: {
        clipPath: 'inset(0 0 0% 0)',
        transition: {
            staggerChildren: 0.07,
            delayChildren: 0.85
        }
    },
    exit: {
        opacity: 1,
        clipPath: 'inset(0 0 100% 0)',
        transition: {
            staggerChildren: 0.02,
            staggerDirection: -1,
            duration: 0.2,
            delay: 0.5
        }}
};
const containerChildren_k1 = {
    hidden: { opacity: 0 , clipPath: 'inset(0 100% 0 0)', backgroundColor: "#124fc1f1", transition: {delay: 0.5}},
    show: { opacity: 1, clipPath: 'inset(0 0% 0 0)', backgroundColor: "#191921"},
    exit: { opacity: 1, clipPath: 'inset(0 100% 0 0)', backgroundColor: "#124fc1f1", transition: {duration: 0.3}}
};


const RaceResults = ({config, data}) => {

    const [currentPage, setCurrentPage] = useState(1);
    const totalPages = Math.ceil(
        data.data?.allCompetitors?.length / MAX_COMPETITORS_PER_PAGE
    );
    const startIndex = (currentPage - 1) * MAX_COMPETITORS_PER_PAGE;
    const endIndex = startIndex + MAX_COMPETITORS_PER_PAGE;
    const pageItems = data.data?.allCompetitors?.slice(startIndex, endIndex);

    const colorArray = [
        config?.buttons?.raceResults?.colors?.className1?.color,
        config?.buttons?.raceResults?.colors?.className2?.color,
        config?.buttons?.raceResults?.colors?.className3?.color,
        config?.buttons?.raceResults?.colors?.className4?.color,
        config?.buttons?.raceResults?.colors?.className5?.color,
        config?.buttons?.raceResults?.colors?.className6?.color
    ];
    const overflowColor = config?.buttons?.raceResults?.colors?.classNameOverflow?.color;

    const colorMap = {};
    if (config?.competitorClasses) {
        config.competitorClasses.forEach((value, index) => {
            const color = index < colorArray.length ? colorArray[index] : overflowColor;
            colorMap[value] = color;
        });
    }

    useEffect(() => {
        const interval = setInterval(() => {
        setCurrentPage((currentPage) => (currentPage % totalPages) + 1);
        }, 13000);
        return () => clearInterval(interval);
    }, [currentPage, totalPages]);

    const [key, setKey] = useState(0);

    useEffect(() => {
        setKey(prevKey => prevKey + 1); // increment key to trigger re-render
    }, [currentPage]);

    switch(config?.style) {
        case "v2":
            return (
                <motion.div
                    className="app__v2-race-results-main"
                    initial={{opacity: 0, scaleY: 0, transform: "translateX(-50%)"}}
                    animate={{opacity: 1, scaleY: 1, transform: "translateX(-50%)", transition: {duration: 0.3}}}
                    exit={{opacity: 0, scaleY: 0}}
                >
                    <motion.div
                        className="app__v2-race-results-end"
                        style={{
                            background: config?.buttons?.raceResults?.colors?.endOfRaceResults?.color,
                            color: config?.buttons?.raceResults?.colors?.endOfRaceResultsFont?.color
                        }}
                    >
                        END OF RACE RESULTS
                    </motion.div>
                    <motion.div
                        className="app__v2-race-results-name"
                        style={{
                            background: config?.buttons?.raceResults?.colors?.raceNameBox?.color,
                            color: config?.buttons?.raceResults?.colors?.raceNameBoxFont?.color
                        }}
                    >
                        {data?.data?.raceDetails?.name}
                    </motion.div>
                    <motion.ol className="app__v2-race-results-content">
                        {
                            pageItems && pageItems.map((item, index) =>
                                <motion.li
                                    className="app__v2-race-results-item"
                                    key={`race-results-item-${index}`}
                                    style={{
                                        background: config?.buttons?.raceResults?.colors?.competitorBox?.color,
                                        color: config?.buttons?.raceResults?.colors?.competitorBoxFont?.color
                                    }}
                                >
                                    <div className="app__v2-race-results-item-pos">
                                        {item?.position}
                                    </div>
                                    <span className="app__v2-race-results-item-nr">{item?.nr}</span>
                                    {
                                        item?.isSidecar ?
                                        <div className="app__v2-race-results-item-state-sidecar">
                                            {item.state ?
                                                <img src={`https://flagcdn.com/${item.state?.toLowerCase()}.svg`} alt="-" />
                                                : <div className="img_placeholder" />
                                            }
                                            {item.state2 ?
                                                <img src={`https://flagcdn.com/${item.state2?.toLowerCase()}.svg`} alt="-" />
                                                : <div className="img_placeholder" />
                                            }
                                        </div>
                                        :
                                        <div className="app__v2-race-results-item-state">
                                            {item.state ?
                                                <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                                : <div className="img_placeholder" />
                                            }
                                        </div>
                                    }
                                    {
                                        item?.isSidecar ?
                                        <div className="app__v2-race-results-item-name">
                                            <p>{item?.firstname?.slice(2).slice(0, -1)}</p> / <p>{item?.lastname?.slice(2)}</p>
                                        </div>
                                        :
                                        <div className="app__v2-race-results-item-name">
                                            {item?.firstname}<p>{item?.lastname}</p>
                                        </div>
                                    }
                                    <div
                                        className="app__v2-race-results-item-class"
                                        style={{background: colorMap[item?.class]}}
                                    >
                                        {item?.class}
                                    </div>

                                </motion.li>
                            )
                        }
                    </motion.ol>
                </motion.div>
            );
        case "k1":
            return (
                <motion.div
                    className="k1_race_results"
                >
                    <motion.div
                        className="k1_race_results_title"
                        initial={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#124fc1f1"}}
                        animate={{clipPath: 'inset(0 0% 0 0)', backgroundColor: "#191921f1", transition: {duration: 0.5}}}
                        exit={{clipPath: 'inset(0 100% 0 0)', backgroundColor: "#124fc1f1", transition: {delay: 1}}}
                    >
                        <motion.p
                            initial={{opacity: 0}} animate={{opacity: 1, transition: {delay: 0.5}}}
                        >END OF RACE RESULTS</motion.p></motion.div>
                    <motion.div
                        className="k1_race_results_name"
                        initial={{clipPath: 'inset(0 0 100% 0)'}}
                        animate={{clipPath: 'inset(0 0 0% 0)', transition: {duration: 0.3, delay: 0.55}}}
                        exit={{clipPath: 'inset(0 0 100% 0)', transition: {duration: 0.3, delay: 0.7}}}
                    >{data?.data?.raceDetails?.name}</motion.div>
                    <motion.ol
                        key={key}
                        className="k1_race_results_items"
                        variants={container_k1}
                        initial="hidden"
                        animate="show"
                        exit="exit"
                    >
                    {
                        pageItems && pageItems.map((item) =>
                        <AnimatePresence>
                            <motion.li className="k1_race_results_item" key={`race-results-item-${item?.position}`}
                                variants={containerChildren_k1}
                            >
                                <div className="k1_battle_pos">{item?.position}</div>
                                <div className="k1_battle_nr">{item?.nr}</div>
                                <div className="k1_battle_className" style={{background: classNameColors?.[config?.competitorClasses?.indexOf(item?.class)]}}/>
                                <div className="k1_battle_flag">{item.state ?
                                    <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                    : <div className="img_placeholder" />
                                }</div>
                                <div className="k1_battle_name"><p>{item?.firstname}</p><span>{item?.lastname}</span></div>
                                <div className="k1_race_results_gap">{item?.diff || "WINNER"}</div>
                            </motion.li>
                        </AnimatePresence>
                        )
                    }
                    </motion.ol>
                </motion.div>
            );
        case "m1":
            return (
                <motion.div className="m1_race_results">
                    <motion.div className="m1_start_grid_top">
                        <motion.div
                            className="m1_start_grid_top_background"
                            initial={{ scaleX: 0, background: "#b13434" }}
                            animate={{ scaleX: 1, background: "#ffffff" }}
                            transition={{ duration: 0.3, ease: "linear" }}
                        />
                        <motion.div
                            className="m1_start_grid_top_items"
                            initial={{ scaleX: 0 }}
                            animate={{ scaleX: 1 }}
                            transition={{ duration: 0.3, ease: "linear", delay: 0.05}}
                        >
                            <motion.div className="m1_start_grid_title">
                                <p>END OF RACE RESULTS</p>
                            </motion.div>
                            <motion.div className="m1_start_grid_name">
                                <p>{data?.data?.raceDetails?.name}</p>
                            </motion.div>
                        </motion.div>
                    </motion.div>
                        <motion.ol
                            key={key}
                            className="m1_race_results_items"
                            variants={container_k1}
                            initial="hidden"
                            animate="show"
                            exit="exit"
                        >
                        {
                            pageItems && pageItems.map((item) =>
                            <AnimatePresence>
                                <motion.li
                                    className="m1_start_grid_item"
                                    key={"m1_start_grid_item"+item?.nr}
                                >
                                    <motion.div className="m1_start_grid_item_time">{item?.diff ? addPlusPrefixIfNeeded(item?.diff) : item?.position === "1" ? "WINNER" : "-"}</motion.div>
                                    <motion.div
                                        className="m1_end_grid_item_content_class_color"
                                        style={{background: classNameColorsM1?.[config?.competitorClasses?.indexOf(item?.class)]}}
                                    />
                                    <motion.div className="m1_start_grid_item_pos"><p>{item?.position}</p></motion.div>
                                    <motion.div className="m1_start_grid_nr">{item?.nr}</motion.div>
                                    <motion.div className="m1_end_grid_item_content">
                                        <motion.div className="m1_end_grid_item_content_top">
                                        {
                                            item?.isSidecar ?
                                            <div className="m1_end_grid_item_content_top_sidecar">
                                                <div className="m1_end_grid_item_content_top_sidecar_row">
                                                    <p>{`${item?.firstname.slice(0, -1)} / ${item?.lastname}`}</p>
                                                    <div className="m1_end_grid_item_content_top_sidecar_flags">
                                                    {item.state ?
                                                        <div className="m1_end_grid_item_content_top_sidecar_flag">
                                                            <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                                        </div>
                                                        : <div className="img_placeholder" />
                                                    }
                                                    {item.state2 ?
                                                        <div className="m1_end_grid_item_content_top_sidecar_flag">
                                                            <img src={`https://flagcdn.com/${item.state2.toLowerCase()}.svg`} alt="-" />
                                                        </div>
                                                        : <div className="img_placeholder" />
                                                    }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="m1_end_grid_item_content_top_regular">
                                                <div className="m1_end_grid_item_content_top_regular_name">
                                                    <p>{item?.firstname}</p>
                                                    <span>{item?.lastname}</span>
                                                </div>
                                                <div className="m1_end_grid_item_content_top_regular_flag">
                                                {item.state ?
                                                    <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                                    : <div className="img_placeholder" />
                                                }
                                                </div>
                                            </div>
                                        }
                                        </motion.div>
                                    </motion.div>
                                </motion.li>
                            </AnimatePresence>

                            )
                        }
                        </motion.ol>
                </motion.div>
            );
    }

};
export default RaceResults;

{/* <AnimatePresence>
    <motion.li className="k1_race_results_item" key={`race-results-item-${item?.position}`}
        variants={containerChildren_k1}
    >
        <div className="k1_battle_pos">{item?.position}</div>
        <div className="k1_battle_nr">{item?.nr}</div>
        <div className="k1_battle_flag">{item.state ?
            <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
            : <div className="img_placeholder" />
        }</div>
        <div className="k1_battle_name"><p>{item?.firstname}</p><span>{item?.lastname}</span></div>
        <div className="k1_race_results_gap">{item?.diff || "WINNER"}</div>
    </motion.li>
</AnimatePresence> */}