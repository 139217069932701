import { useEffect, useState } from "react";
import MessageStore from "../../../../../../../managers/ConsoleMessageManager";
import { useSelector } from "react-redux";

// Icons
import { MdAddBox } from "react-icons/md";

// Extra components
import { Switch } from "../../../HorizontalBar/HorizontalBar";


const battleHeaderOrder = ["position", "nr", "competitor", "gap", "add"];

const InteractiveBattles = (props) => {

    const data = useSelector(state => state.data);
    const config = useSelector(state => state.config);

    const [enabledCompetitors, setEnabledCompetitors] = useState([]);
    const [battlesSuggestions, setBattlesSuggestions] = useState(false);
    const [competitorSuggestion, setCompetitorSuggestions] = useState([]);

    const [gapThreshold, setGapThreshold] = useState(1);
    const [gapThresholdValue, setGapThresholdValue] = useState(gapThreshold);

    useEffect(() => {
        const enabledCompetitors = config?.battleCompetitors;
        setEnabledCompetitors(enabledCompetitors);
    }, []);

    const raceData = data?.data;

    useEffect(() => {
        if (battlesSuggestions && raceData?.allCompetitors?.length > 0) {
            const newSuggestions = [...competitorSuggestion];
            for (let i = 1; i < raceData?.allCompetitors?.length; i++) {
                const currentCompetitor = raceData?.allCompetitors[i];
                const previousCompetitor = raceData?.allCompetitors[i - 1];
                if (currentCompetitor.gap === undefined ||
                    currentCompetitor.gap === null ||
                    currentCompetitor.gap === "-") { continue; }
                if (Number(currentCompetitor.gap) <= gapThreshold) {
                    if (!newSuggestions.includes(previousCompetitor.nr)) {
                        newSuggestions.push(previousCompetitor.nr);
                    }
                    if (!newSuggestions.includes(currentCompetitor.nr)) {
                        newSuggestions.push(currentCompetitor.nr);
                    }
                } else {
                    const currentIndex = newSuggestions.indexOf(currentCompetitor.nr);
                    const previousIndex = newSuggestions.indexOf(previousCompetitor.nr);
                    if (previousIndex > -1) {
                        newSuggestions.splice(previousIndex, 1);
                    }
                    if (currentIndex > -1) {
                        newSuggestions.splice(currentIndex, 1);
                    }
                }
            }
            if (raceData?.raceDetails?.flag === "None") {
                setCompetitorSuggestions([]);
            } else {
                setCompetitorSuggestions(newSuggestions);
            }
        }
    }, [raceData?.allCompetitors]);

    const addBattleCompetitor = (nr) => {
        enabledCompetitors.includes(nr) ?
        setEnabledCompetitors(prev => prev.filter(item => item !== nr))
        :
        setEnabledCompetitors(prev => [...prev, nr])
    };
    const saveBattleCompetitors = () => {
        const updatedCompetitors = enabledCompetitors.map(nr => {
            const competitor = raceData?.allCompetitors?.find(comp => comp.nr === nr);
            return competitor?.position;
        });
        props.socket.emit("update", {what: 3, data: {remove: false, data: updatedCompetitors}}, (response) => {
            MessageStore.addMessage(response);
        });
    };
    const resetBattleCompetitors = () => {
        setEnabledCompetitors([]);
        props.socket.emit("update", {what: 3, data: {remove: true}}, (response) => {
            MessageStore.addMessage(response);
        });
    };
    const updateGapThreshold = () => {
        const newThreshold = document.getElementById("threshold_range").value;
        MessageStore.addMessage({message: `Setting new gap threshold: ${newThreshold}s`, status: 200, parent: 2});
        setBattlesSuggestions([]);
        setGapThreshold(newThreshold);
    };
    const changeGapThreshold = (value) => {
        setGapThresholdValue(value);
    }

    return (
        <div className="interactive-screen-main-battles">
            <div className="interactive-screen-main-battles-competitors">
                <header>
                    { battleHeaderOrder?.map((item) => <div className="battle_header" key={`battle_header_${item}`}>{item}</div>) }
                </header>
                <div className="interactive-screen-main-battles-competitors-list">
                    {
                        raceData && raceData?.allCompetitors?.map((competitor, index) =>
                            <div className="interactive-screen-main-battles-competitors-list-item" key={`battle_competitor_${index}${competitor.nr}`}>
                                {
                                    battleHeaderOrder?.map((key, index2) => {
                                        const value = competitor[key];
                                        return value !== undefined &&
                                            <p
                                                style={{background: enabledCompetitors.includes(competitor.nr) ? "#3446eb" :
                                                        competitorSuggestion.includes(competitor.nr) ? "#bf1f47" :
                                                        index % 2 === 0 ? "#151f31" : "#19243b"}}
                                                key={`competitor_${key}${index}${index2}`}
                                            >{value || "-"}</p>
                                    })
                                }
                                <p
                                    style={{background: enabledCompetitors.includes(competitor.nr) ? "#3446eb" :
                                            competitorSuggestion.includes(competitor.nr) ? "#bf1f47"
                                            : index % 2 === 0 ? "#151f31" : "#19243b"}}
                                    key={`competitor_${index}_add`}
                                    onClick={() => addBattleCompetitor(competitor.nr)}
                                ><MdAddBox size={"28px"} color={(enabledCompetitors.includes(competitor.nr) || competitorSuggestion.includes(competitor.nr)) ? "#0f1623" : "#3446eb" }/></p>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="interactive-screen-main-battles-settings">
                <div className="interactive-screen-main-battles-settings-data">
                    <header>Selected battle competitors</header>
                    <div className="interactive-screen-main-battles-settings-data-list">
                        { enabledCompetitors?.map((competitor, index) => <div className="enabled-competitor" key={`comp_enabled_${competitor}`}>{competitor}</div>)}
                    </div>
                </div>
                <hr/>
                <div className="interactive-screen-main-battles-settings-item" key="battles_item_max_gap">
                    <p>Select max battle gap</p>
                    <div className="interactive-screen-main-battles-settings-item-input">
                        <input type="range" id="threshold_range" min={0.1} max={3} step={0.1} value={gapThresholdValue}
                            onChange={(e) => changeGapThreshold(e.target.value)}
                        />
                        <span>{`${gapThresholdValue}s`}</span>
                        <button onClick={() => updateGapThreshold()}>Set</button>
                    </div>
                </div>
                <hr />
                <div className="interactive-screen-main-battles-settings-item" key="battles_item_sugg">
                    <p>Suggestions</p>
                    <div className="keyboardPressListener-component">
                        <p>Use</p>
                        <Switch lvalue={"Disabled"} rvalue={"Enabled"} onChange={(value) => {
                            setBattlesSuggestions(prev => !prev);
                            setCompetitorSuggestions([]);
                        }} defaultValue={battlesSuggestions} />
                    </div>
                </div>
                <hr />
                <div className="battles-save-buttons">
                    <button className="battles-save-bttn" onClick={() => saveBattleCompetitors()}>Save</button>
                    <button className="battles-save-bttn" onClick={() => resetBattleCompetitors()} >Reset</button>
                </div>
            </div>
        </div>
    );
};

export default InteractiveBattles;