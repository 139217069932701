import "./Admin.css";

import { Route, Routes, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import io from "socket.io-client";
import { useDispatch } from "react-redux";

// Components for admin panel
import ControlPanelComponent from "./AdminPanelComponents/ControlPanel/ControlPanel";
import AdministrateComponent from "./AdminPanelComponents/Admin/Admin";
import PageNotFound from "./AdminPanelComponents/NotFound/NotFound";
import { getRaceBackendAddress } from "../../Functions";

import Timestamps from "./AdminPanelComponents/ControlPanel/HorizontalBar/GraphTimestamps";

const GRAPHICS_SERVER_ADDRESS = getRaceBackendAddress();

const Admin = React.memo(() => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [socket, setSocket] = useState(null);
    const [loading, setLoading] = useState(true);
    const [config, setConfig] = useState({});

    useEffect(() => {
        const jwt = localStorage.getItem("jwt");
        if (!jwt) {
            navigate("/login");
            return;
        }

        const authSocket = io.connect(GRAPHICS_SERVER_ADDRESS);
        authSocket.emit("auth", { token: jwt }, (response) => {
            if (response.status !== 200) {
                localStorage.clear();
                navigate("/login");
            } else {
                const newSocket = io.connect(GRAPHICS_SERVER_ADDRESS, {
                    query: {
                        userType: "Admin Panel",
                        userId: localStorage.getItem("userId"),
                        userName: JSON.parse(localStorage.getItem("userName")),
                    },
                });
                //dispatch({ type: "SET_SOCKET", payload: newSocket });
                setSocket(newSocket);
                setLoading(false);
            }
        });
    }, []);

    useEffect(() => {
        if (socket) {
            socket.on("getApi", (data) => {
                dispatch({ type: "SET_DATA", payload: data });
                Timestamps.addTimestamp(Date.now());
            });
            socket.on("getConfig", (data) => {
                dispatch({ type: "SET_CONFIG", payload: data });
                setConfig(data);
            });
            socket.on("sendErrors", (data) => {
                dispatch({ type: "SET_ERROR", payload: data });
            });
        }
    }, [socket]);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div className="app__admin">
            <Routes>
                <Route path={"/controlPanel"} element={ <ControlPanelComponent socket={socket} config={config} /> } />
                <Route path={"/administrate"} element={ <AdministrateComponent socket={socket} /> } />
                <Route path={"/*"} element={<PageNotFound socket={socket}/>} />
            </Routes>
        </div>
    );
});
export default Admin;