import "./Help.scss";
import { useState } from "react";

const HelpComponent = () => {

    const [page, setPage] = useState(0);
    const [searchString ,setSearchString] = useState("");

    // configuration
    const data = [
        {q: "How to upload competitor images", a: `You can upload competitor images by putting all images in a folder and zipping it.
            Then you can upload the zip file in the Competitor images tab.
            Also please make sure that .zip file contains only images and no folders or other files.
        `},
        {
            q: "How to name competitor images so they get displayed in graphics",
            a: `To name competitor images correctly for them to display in graphics you need to:
            <ul>
                <li>Use <strong>.png</strong> format for the image with transparent background.</li>
                <li>Make sure your image is a perfect square with minimum size of <strong>200 by 200 pixels</strong>.</li>
                <li>Make sure you name your images correctly. Name should be competitors <strong>first name and last name combined, all in lowercase</strong>.
                    <ul><li>Example: <code>johndevis.png</code></li></ul>
                </li>
                <li>Make sure not to use special charaters in the name. Graphics look for matching images by comparing data from timekeeper to your uploaded images, so for best
                results it's best to consult with timekeepers before the race to check for any mistakes in competitor naming</li>
                <li>For best results make sure your competitor is perfectly in the center of the image, otherwise in some graphics it wont look as good.</li>
            </ul>
            `
        },
        {
            q: "How to enable competitor images for graphics after uploading the .zip file",
            a: `In order to toggle between displaying competitor images in your graphics you need to use the Enabled/Disabled competitor images switch located
                in the right side of the panel under Competitor images tab.
            `
        },
        {
            q: "How can I get my graphics to show in stream",
            a: `To get your graphics to show in stream you need to copy the graphics link located in the right side of the panel and paste it in your streaming software.
                In your software ( for example: OBS ) you need to add a new <strong><i>Browser source</i></strong> and paste the copied link in the URL field. Also make sure to <strong>set width to 1920px and height to 1080px</strong> if it's not set by those vales by default.
            `
        },
        {
            q: "What is a keypress listener and how can I use it",
            a: `Keypress listener ( located in the right menu bar ) allows you to control your graphics using set keyboard shortcuts. In order to make it work you need to bind a key to graphic and also
                make sure that keypress listener is enabled for it to work. Keep in mind that due to browser limitations you need to make sure that your browser window is in focus for keypress listener to work.
            `

        },
        {
            q: "Can I use one graphics style for all types of races",
            a: `Sadly no. Some graphics styles are made specifically for certain types of races, such as karting, where there are no side passengers or joker laps to deal with.
                In order to understand if a graphics style is suitable for your race you need to check description and tags for each style to determine if this style will suit your needs.
                For best practice you can switch between the styles before race to determine which one will best suit your needs.
            `
        },
        {
            q: "There is a glitch in the graphics or control panel. How can i report this",
            a: `You can report any glitches or bugs by contacting us on our email <a href="mailto:racegraphicseu@gmail.com">by clicking this link</a> or emailing us at
                <strong>racegrapihcseu@gmail.com</strong>. Please make sure to include as much information as possible, such as:
                <ul><li>Screenshots</li><li>Steps to reproduce the bug</li><li>What browser are you using</li><li>What operating system are you using</li><li>What device are you using</li></ul>
                Any feedback or reported bugs is greatly appreciated and will help us improve our service.
                `
        },
        {
            q: "What browser should I use to access graphics control panel",
            a: `We recommend using <strong>Google Chrome</strong> for best experience. We also support Firefox, Edge and Safari, but for best experience we recommend using Google Chrome, as graphics control panel was specifically tested on Chrome.`
        },
        {
            q: "I have a great idea for a new feature or improvements, how can I share it",
            a: `Great! We are always open to new ideas and improvements. You can contact us on our email <a href="mailto:racegraphicseu@gmail.com">by clicking this link</a> or
                <strong>racegrapihcseu@gmail.com</strong>. Your feedback will be looked at by a developer and it might be implemented in the future.`
        }
    ];

    return (
        <div
            className="help_component"
            style={{backgroundImage: "url(/svg/topo2.svg)"}}
        >
            <header><p>FAQ & Documentation</p></header>
            <div className="help_component_content">
                <div className="help_component_content_left_tower">
                    <div className="help_component_content_left_tower_search">
                        <input type="text" placeholder="Search..." onChange={(e) => setSearchString(e.target.value)}/>
                        <button onClick={() => setSearchString("")}>Clear</button>
                    </div>
                {
                    data?.filter((a) => a?.q?.toLowerCase().includes(searchString.toLowerCase()))?.map((item, index) =>
                        <div
                            className="help_component_content_left_tower_item"
                            key={"help_item_"+index}
                            style={{background: page === index ? "#1555d3" : "#121b2b"}}
                            onClick={() => setPage(index)}
                        >
                            <p>{item?.q}?</p>
                        </div>
                    )
                }
                </div>
                <div className="help_component_content_screen">
                {
                    <div className="help_component_content_screen_item">
                        <header>{data[page]?.q}?</header>
                        <hr/>
                        <div className="help_component_item_text" dangerouslySetInnerHTML={{ __html: data[page]?.a}} />
                    </div>
                }
                </div>
            </div>
        </div>
    );
};

export default HelpComponent;