import { motion } from "framer-motion";
import "./ChangeLog.scss";

import { useChangeLogFetch } from "../../../../../hooks/hooks";

const ChangeLog = () => {
    const logs = useChangeLogFetch();

    return (
        <motion.div
            className="changeLog-main"
            style={{backgroundImage: "url(/svg/topo2.svg)"}}
            initial={{ clipPath: 'inset(0% 0% 100% 0%)' }} // Start from top
            animate={{ clipPath: 'inset(0% 0% 0% 0%)' }} // Animate to full view
            exit={{ clipPath: 'inset(100% 0% 0% 0%)' }} // Exit to bottom
            layout
        >
            <header><p>Changelog</p></header>
            <div className="changeLog-content">
            {
                logs?.map((item, index) =>
                    <div className="changeLog-item" key={"ChangeLog-item-"+index}>
                        <div className="changeLog-item-header">
                            <p>{item?.date}</p>
                        </div>
                        <div className="changeLog-item-content">
                            <div className="changeLog-item-content-header">
                                {item?.desc}
                            </div>
                            <p>{item?.name}</p>
                            <span>{item?.text}</span>
                        </div>
                    </div>
                )
            }
            </div>
        </motion.div>
    );
};

export default ChangeLog;