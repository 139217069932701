import { useState } from "react";
import CryptoJS from "crypto-js";

const AdministrateLogin = ({ socket, callback }) => {

    //secret key for encryption TODO: hide this?????!?!??!
    const secretKey = "_35@ac:vII!lxx";

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleLogin = async () => {
        if (!username) { return setError("Username is required"); }
        if (!password) { return setError("Password is required"); }

        setLoading(true);
        const encrypted = CryptoJS.AES.encrypt(password, secretKey).toString();
        socket.emit("loginAdmin", { username, encrypted, parent: true }, (response) => {
            if (response.status === 200) {
                callback(true);
            } else {
                setError(response.message);
                setLoading(false);
                setPassword("");
            }
        });
    }

    return (
        <div className="admin_login_panel">
            <div className="admin_login_panel_form">
                <h3>Administrator Login</h3>
                <input type="text" className="admin_login_user_input" required placeholder="Username" onChange={(usr) => setUsername(usr.target.value)}/>
                <input type="password" className="admin_login_pass_input" required placeholder="Password" onChange={(pwd) => setPassword(pwd.target.value)}/>
                <button className="admin_login_btn" onClick={() => handleLogin()}>Login</button>
                {
                    loading && <div className="admin_login_loading">Loading...</div>
                }
                {
                    error && <p>{error}</p>
                }
            </div>
        </div>
    );
};

export default AdministrateLogin;