import { useState, useEffect } from "react";
import axios from "axios";
import { getRaceBackendAddress } from "../../../../../Functions";

const Users = ({}) => {

    const [userData ,setUserData] = useState({});

    useEffect(() => {
        axios.post(`${getRaceBackendAddress()}/getAllUsers`, {token: localStorage.getItem('jwt')})
        .then((response) => {
            if (response.data.status === 200) {
                setUserData({
                    server_time: response.data?.server_time,
                    users: response.data?.data
                });
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }, []);

    return (
        <div className="admin_panel_all_users">
            <header><p>All users</p></header>
            <div className="admin_panel_all_users_content">
            {
                userData?.users?.map((user, i) =>
                    <div className="admin_panel_user" key={i}>
                        <header><p>{i + 1}</p>{user?.user_data?.name}</header>
                        <span>{user?.user_data?.admin_access ? "Admin" : "User"}</span>
                        <hr />
                        <div className="admin_panel_user_valid">
                            <p>Valid until</p>
                            <span>{user?.user_data?.account_exp_date}</span>
                            <p>{}</p>
                        </div>
                        <div className="admin_panel_user_options">
                            <p>Actions</p>
                            <hr />
                            <button>Change account time</button>
                            <button>Change account type</button>
                            <button>Delete user</button>
                        </div>
                    </div>
                )
            }
            </div>
        </div>
    );
};

export default Users;