import "./Home.scss";
import {
    motion,
    useAnimation
}
from "framer-motion"
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";

// icons
import { ImConnection } from "react-icons/im";
import { IoGameController, IoImagesSharp } from "react-icons/io5";
import { FaRocket } from "react-icons/fa6";

const scrollTo = id => {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
};

const Navbar = () => {
    const navigate = useNavigate();
    return (
        <div className="rg-navbar">
            <div className="rg-navbar-inner">
                <div className="rg-navbar-logo">
                    <img src="/logo/RGlogo-new.png" alt="RG Logo" />
                </div>
                <ul>
                    <li onClick={() => scrollTo('title')}>Home</li>
                    <li onClick={() => scrollTo('about')}>About us</li>
                    <li onClick={() => scrollTo('graphicsInfo')}>Our product</li>
                </ul>
                <button onClick={() => navigate("/login")}>Sign-in</button>
                <p>Don't have an account?</p>
            </div>
        </div>
    );
};

const Title = () => {
    return (
        <div id="title" className="rg-title"
            style={{ backgroundImage: "url(/svg/lights.svg)" }}
        >
            <span>Live and Dynamic, made for your race.</span>
            <p>Advanced. Modifiable. Multifunctional.</p>
            <button>Learn more</button>
            {/* <button>Start your subscription</button> */}
        </div>
    );
};
const Video = () => {
    return (
        <div className="rg-video">
            <video autoPlay loop muted>
                <source src="/videos/video.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>

        </div>
    );
};
const About = () => {
    const animation = useAnimation();
    const [ref, inView] = useInView({
        threshold: 0.1,
    });

    useEffect(() => {
        if (inView) {
            animation.start({
                opacity: 1,
                transition: { duration: 1.5, delay: 0.2 },
            });
        } else {
            animation.start({ opacity: 0 });
        }
    }, [animation, inView]);
    return (
        <motion.div
            id="about"
            className="rg-about"
        >
            <span>About us</span>
            <motion.div
                className="rg-about-inner"
                animate={animation}
                initial={{ opacity: 0 }}
                ref={ref}
            >
                <p>A company with more than 17 years of experience.</p>
                <h2>
                    We work in providing race timing service as well as serve as the official reseller of MyLaps in technical sports.
                    We specialize in technical and IT solutions of timing systems.  Our latest solution is the development of data and graphics servers for streaming race results in video graphics - RaceGraphics.
                </h2>
            </motion.div>
        </motion.div>
    );
}

const Clients = () => {

    const clients = ["nolimits.png", "rotax.png", "rallycross.png"];

    return (
        <div className="rg-clients">
            <span>Meet our customers</span>
            <div className="carousel">
                {clients.map((client, index) => (
                    <img key={index} src={`/home/${client}`} alt={`client ${index + 1}`} className="carousel__image" />
                ))}
            </div>
        </div>
    );
}

const Transition = () => {
    return (
        <div className="rg-transition" style={{backgroundImage: "url(/svg/trans.svg)"}}/>
    );
}

const GraphicsInfo = () => {

    const controls = useAnimation();
    const { ref, inView } = useInView({
        threshold: 0.1,
    });

    useEffect(() => {
        if (inView) {
            controls.start("visible");
        }
    }, [controls, inView]);

    const cards = [
        {
            title: "Blink rendering engine",
            text: "Our graphics uses heavily optimized <b>Blink</b> open source rendering engine <h4>the same used by Google Chrome</h4> to render graphics in real-time.",
            color: "#dbd9d9",
            icon: <img src="/home/chromium.png" alt="" />
        },
        {
            title: "Real time data from Orbits",
            text: "Thanks to our data stream server, RaceGraphics pull data real time - straight from the timekeeper to ensure up to date action.",
            color: "#dbd9d9",
            icon: <ImConnection size={"100px"} color={"#5858e8"}/>
        },
        {
            title: "You have the controls",
            text: "Customize graphics to best fit your needs! Select your own colors and position the graphics however you like.",
            color: "#dbd9d9",
            icon: <IoGameController size={"100px"} color={"#df3f3f"}/>
        },
        {
            title: "Showcase competitors",
            text: "Upload your competitor images and RaceGraphics will match them with corresponding driver to be shown on screen!",
            color: "#dbd9d9",
            icon: <IoImagesSharp size={"100px"} color={"#2d443c"}/>
        },
        {
            title: "Simple to use",
            text: "Setting up your own RaceGraphics session takes just a few clicks! Simply select your race from available races and click on a graphic you want to show!",
            color: "#dbd9d9",
            icon: <FaRocket size={"100px"} color={"#000000"}/>
        }
    ];

    return (
        <div id="graphicsInfo" className="rg-graphics-info">
            <span>Our product</span>
            <p>Using latest and fastest technologies. 🚀</p>
            <motion.div
                className="rg-graphics-info-cards"
                ref={ref}
                animate={controls}
                initial="hidden"
                variants={{
                    visible: {
                        opacity: 1,
                        transition: { staggerChildren: 0.2 } // Add this line
                    },
                    hidden: { opacity: 0 }
                }}
            >
                {cards.map((card, index) => (
                    <motion.div
                        key={index}
                        className="graphicsInfo-card"
                        ref={ref}
                        animate={controls}
                        initial="hidden"
                        transition={{ duration: 0.5, delay: 1 }}
                        variants={{
                            visible: { opacity: 1, y: 0 },
                            hidden: { opacity: 0, y: 50 }
                        }}
                    >
                        <div className="graphicsInfo-card-inner"
                            style={{background: `linear-gradient(to top, #ededed 50%, ${card.color} )`}}>
                            {card.icon}
                            <p>{card.title}</p>
                            <span dangerouslySetInnerHTML={{ __html: card.text }} />
                        </div>
                    </motion.div>
                ))}
            </motion.div>
        </div>
    );
}

const Showcase = () => {

    const links = [
        "https://www.youtube.com/watch?v=0x1sdl5Z9d4&ab_channel=RallycrossLV",
        "https://www.youtube.com/watch?v=mwDNz08VePo&ab_channel=RotaxMaxLatvia",
    ];

    return (
        <div className="rg-showcase">
            <span>Showcase</span>
            <p>See our product in action! 🎬</p>
            <div className="rg-showcase-inner">
            {
                links.map((item, index) =>
                    <div className="rg-showcase-item" key={`rg_showcase_${index}`}>
                        <iframe
                            width="560"
                            height="315"
                            src={`https://www.youtube.com/embed/${item.split('=')[1].split('&')[0]}`}
                            title={`YouTube video player ${index}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        ></iframe>
                    </div>
                )
            }
            </div>
        </div>
    );
}

const Prices = () => {

    const plans = [
        {
            name: "Single race",
            cost: "450€ *",
            text: "When you need graphics for a single event.",
            popular: false,
            features: [
                {name: "One-day event", color: ""},
                {name: "All features included", color: ""}
            ]
        },
        {
            name: "Yearly subscription",
            cost: "2500€ *",
            text: "When you need graphics for whole season!",
            popular: true,
            features: [
                {name: "Unlimited events", color: ""},
                {name: "One event at a time", color: ""},
                {name: "All features included", color: ""}
            ]
        }
    ];

    return (
        <div className="rg-prices">
            <span>Pricing</span>
            <p>Choose one of our subscription plans that best suits your needs.</p>
            <div className="rg-prices-inner">
            {
                plans.map((plan, index) =>
                    <div
                        className={`rg-prices-item ${plan.popular ? "rg-prices-item-popular" : ""}`}
                        key={`rg_prices_${index}`}
                        style={{backgroundImage: "url(/home/card.svg)"}}
                    >
                        <h2>{plan.name}</h2>
                        <h3>{plan.cost}</h3>
                        <p>{plan.text}</p>
                        {
                            plan.popular &&
                            <div className="rg-pop">Most popular</div>
                        }
                        <ul>
                        {
                            plan.features.map((feature, index) =>
                                <li key={`rg_prices_feature_${index}`} style={{color: feature.color}}>
                                    {feature.name}
                                </li>
                            )
                        }
                        </ul>
                        <button>Contact us!</button>
                    </div>
                )
            }
            </div>
            <h5>* All prices are adjusted for 2024 season. For price corrections please contact us.</h5>
        </div>
    );
}

const Footer = () => {
    return (
        <div className="rg-footer">
            <p>Contact Us</p>
            <h4>Email: racegraphicseu@gmail.com</h4>
            <h6>RaceGraphics™ 2024</h6>
        </div>
    );
}
const Dots = () => {
    return (
        <div className="rg-dots">
            <ul>
                <li onClick={() => scrollTo('title')}/>
                <li onClick={() => scrollTo('about')}/>
                <li onClick={() => {}}/>
                <li onClick={() => {}}/>
            </ul>
        </div>
    );
}

const Home = () => {

    return (
        <div className="rg-home">
            <div className="rg-home-inner">
                <Navbar />
                <Dots />
                <Title />
                <Video />
                <Transition />
                <Prices />
                <GraphicsInfo />
                <Showcase />
                <About />
                <Clients />
                <Footer />
            </div>
        </div>
    );
};

export default Home;