
import { useState, useEffect } from "react";

const AvailableRacesComponent = ({ socket }) => {

    const [raceList, setRaceList] = useState(null);

    const setSelectedRace = (token) => {
        socket.emit("setApiKey", token, (callback) => {
            if (callback.status === 200) {
                raceList?.map((race) => {
                    if (race.token === token) {
                        //setSelectedEvent(race);
                    }
                });
            }
        });
    }

    const fetchData = async () => {
        let didGetResponse = false;

        const timeoutId = setTimeout(() => {
            if (!didGetResponse) {
                setRaceList([]);
            }
        }, 5000);

        setTimeout(async () => {
            await socket.emit("getActiveRaceList", (callback) => {
                didGetResponse = true;
                clearTimeout(timeoutId);

                if (callback.status === 200) {
                    setRaceList(callback.data);
                }
            });
        }, 500)
    };

    useEffect(() => {
        fetchData();
    }, []);

    const raceTypeToLetter = (type) => {
        return type || "";
    }


    if (!raceList) {
        return (
            <div className="horizontalBar-race-items-screen">
                <div className="spinner"/>
                <h4>Loading race list</h4>
            </div>
        );
    }

    if ( raceList?.length === 0) {
        return (
            <div className="horizontalBar-race-items-screen">
                <p>Looks like nothing is happening right now!</p>
                <span>Try refreshing races from the Races tab to see if any race is available</span>
            </div>
        );
    }


    return (
        <div
            className="horizontalBar-race-items-screen-data"
            style={{backgroundImage: `url(/svg/Bubbles.svg)`}}
        >
            <div className="horizontalBar-race-items-blur">
                <header>
                    <span>Available races</span>
                    <p>Browse between currently active races and pick your one</p>
                </header>
                <div className="horizontalBar-race-items">
                    <div className="horizontalBar-race-items-list">
                    {
                        raceList && raceList.map((race, index) =>
                        <div
                            className="horizontalBar-component-race-item"
                            key={`race-hor-item${index}`}
                            onClick={() => setSelectedRace(race?.token)}
                        >
                            <header><h3>{race?.championship}</h3></header>
                            <div className="race-item-type">
                                {raceTypeToLetter(race?.race_type)}
                            </div>
                            <div className="race-item-body">
                                <div className="race-item-live">LIVE</div>
                                <div className="race-item-body-item">
                                    <p>Track</p>
                                    <span>{race?.track_name}</span>
                                </div>
                                <hr/>
                                <div className="race-item-body-item">
                                    <p>Flag</p>
                                    <span>{race?.flag}</span>
                                </div>
                            </div>
                        </div>
                        )
                    }
                    </div>
                </div>
            </div>

        </div>
    );
};

export default AvailableRacesComponent;