

// What graphics are available for what design configuration.

class Graphics {
    static verticalScoreboard = "verticalScoreboard";
    static horizontalScoreboard = "horizontalScoreboard";
    static resultsByClass = "resultsByClass";
    static startGrid = "startGrid";
    static raceResults = "raceResults";
    static battles = "battles";
    static weather = "weather";
    static timer = "timer";
    static announcer = "announcer";
    static fastestLap = "fastestLap";
    static raceLeader = "raceLeader";
    static winner = "winner";
    static replay = "replay";
    static trackPlan = "trackPlan";
}


// Used in select graphics tab to add description and other data.

export const selectorGraphicsDesc = {
    [Graphics.verticalScoreboard]: {
        description: "Displays race results in vertical column format."
    },
    [Graphics.horizontalScoreboard]: {
        description: "Displays race results in horizontal row format."
    },
    [Graphics.resultsByClass]: {
        description: "Displays results based on selected class."
    },
    [Graphics.startGrid]: {
        description: "Displays how competitors are aligned at the start of the race."
    },
    [Graphics.raceResults]: {
        description: "Displays all competitors and their post race results in a table format."
    },
    [Graphics.battles]: {
        description: "Shows gap between selected competitors and position they are fighting for."
    },
    [Graphics.weather]: {
        description: "Show weather conditions on track."
    },
    [Graphics.timer]: {
        description: "Minimalistic graphic to show remaining/elapsed time/laps and race name."
    },
    [Graphics.announcer]: {
        description: "Lets you type a message to display for your audience on screen."
    },
    [Graphics.fastestLap]: {
        description: "Shows which competitor has the fastest lap of the run."
    },
    [Graphics.raceLeader]: {
        description: "Shows current leader of the race based on laps/time"
    },
    [Graphics.winner]: {
        description: "Shows the winner of the race."
    },
    [Graphics.replay]: {
        description: "A small box with 'Replay' written on it do display when you are using a replay."
    },
    [Graphics.trackPlan]: {
        description: "Displays a track plan with custom track image and selected attributes."
    },
};

// Used for buttons to determine which graphics are available
// for a set design.

export const designData = {
    'v2': {
        name: "Original",
        areColorsAvailable: true,
        graphics: [
            Graphics.verticalScoreboard,
            Graphics.resultsByClass,
            Graphics.startGrid,
            Graphics.raceResults,
            Graphics.battles,
            Graphics.weather,
            Graphics.timer,
            Graphics.fastestLap,
            Graphics.raceLeader,
            Graphics.winner,
            Graphics.replay,
            Graphics.trackPlan
        ],
        sizes: {
            [Graphics.verticalScoreboard]: {
                w: 400,
                h: 960
            },
        }
    },
    'k1': {
        name: "Karting",
        areColorsAvailable: false,
        graphics: [
            Graphics.verticalScoreboard,
            Graphics.resultsByClass,
            Graphics.startGrid,
            Graphics.raceResults,
            Graphics.battles,
            Graphics.weather,
            Graphics.timer,
            Graphics.fastestLap,
            Graphics.raceLeader,
            Graphics.winner,
            Graphics.replay,
            Graphics.announcer
        ],
        sizes: {
            [Graphics.verticalScoreboard]: {
                w: 400,
                h: 960
            },
        }
    },
    'm1': {
        name: "Motocross",
        areColorsAvailable: false,
        graphics: [
            Graphics.verticalScoreboard,
            Graphics.horizontalScoreboard,
            Graphics.resultsByClass,
            Graphics.startGrid,
            Graphics.raceResults,
            Graphics.battles,
            Graphics.timer,
            Graphics.fastestLap,
            Graphics.raceLeader
        ],
        sizes: {
            [Graphics.verticalScoreboard]: {
                w: 362,
                h: 923
            },
            [Graphics.horizontalScoreboard]: {
                w: 1920,
                h: 340
            },
            [Graphics.resultsByClass]: {
                w: 310,
                h: 480
            },
            [Graphics.startGrid]: {
                w: 768,
                h: 1033
            },
            [Graphics.raceResults]: {
                w: 900,
                h: 1033
            },
            [Graphics.timer]: {
                w: 640,
                h: 50
            }
        }
    },
};
