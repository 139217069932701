import { useEffect, useState } from "react";

const InteractiveEnabledGrid = () => {

    const [loading, setLoading] = useState(true);

    const handleResize = () => {
        const iframe = document.getElementById('interactive_main_grid_src');
        const wrapper = document.getElementById('interactive-screen-main-enabled-grid-wrapper');

        const scale = Math.min(
            wrapper.offsetWidth / iframe.offsetWidth,
            wrapper.offsetHeight / iframe.offsetHeight
        );

        iframe.style.transform = `scale(${scale})`;
    };

    useEffect(() => {

        handleResize();
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [handleResize]);

    return (
        <div
            className="interactive-screen-main-enabled-grid-wrapper"
            id="interactive-screen-main-enabled-grid-wrapper"
        >
            {
                loading &&
                <div className="interactive-screen-main-enabled-grid-wrapper-loading" />
            }
            <iframe
                className="interactive-screen-main-enabled-grid-src"
                id="interactive_main_grid_src"
                src={`https://racegraphics.eu/graphics/${encodeURIComponent(localStorage.getItem("userId"))}`}
            />
        </div>
    );
};

export default InteractiveEnabledGrid;