
import "./Joker.scss";

const Joker = ({data, socket}) => {

    const setJokerLap = (startNumber) => {
        socket?.emit("setJokerLap", startNumber);
    };

    return (
        <div className="app__joker-main">
            <div className="app__joker-selected">
                <span>Finished Joker</span>
                <div className="app__joker-finished-container">
                    {
                        data?.data?.allCompetitors?.filter(e => e.joker).map((item, index) =>
                            <div className="app__joker-finished-item" key={`${item?.nr}_joker-fin-k`}>
                                <p>{item?.position}</p>
                                <div className="app__joker-item-fin-nr">
                                    {item?.nr}
                                </div>
                                <div className="app__joker-item-fin-name">
                                    {item?.lastname?.substring(0, 3)}
                                </div>
                                <button onClick={() => {setJokerLap(item.nr)}}>-</button>
                            </div>
                        )
                    }
                </div>
            </div>
            <div className="app__joker-competitors">
                <span>Active Competitors</span>
                <div className="app__joker-competitors-container">
                    {
                        data?.data?.allCompetitors?.filter(e => !e.joker).map((item, index) =>
                            <div
                                className="app__joker-competitors-item"
                                key={`${item?.nr}__joker-comp-k`}
                            >
                                <p>{item?.position}</p>
                                <div className="app__joker-item-nr">
                                    {item?.nr}
                                </div>
                                <div className="app__joker-item-state">
                                {item.state ?
                                    <img src={`https://flagcdn.com/${item.state.toLowerCase()}.svg`} alt="-" />
                                    : <div className="img_placeholder" />
                                }
                                </div>
                                <div className="app__joker-item-name">
                                    {item?.firstname}
                                    <h4>{item?.lastname}</h4>
                                </div>
                                <button onClick={() => {setJokerLap(item.nr)}}></button>
                            </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};
export default Joker;