

import "./ControlPanel.scss";
import React, {useEffect, useState} from "react";

import ConsoleComponent from "./Console/Console";
import InteractiveButtons from "./InteractiveButtons/Buttons";
import InteractiveScreen from "./InteractiveScreen/InteractiveScreen";
import HorizontalBarComponent from "./HorizontalBar/HorizontalBar";
import PopoutManager from "../../../../managers/PopoutManager";

import { Rnd } from "react-rnd";
import { useNavigate } from "react-router-dom";
import { useAuthCheck, usePopoupCount } from "../../../../hooks/hooks";

const InteractiveComponent = ({ config, socket }) => {
    return (
        <div className="interactive-component">
            <InteractiveScreen config={config} socket={socket} />
            <InteractiveButtons config={config} socket={socket}/>
        </div>
    );
};

const Popout = ({window, index}) => {
    return (
        <Rnd
            default={{
            x: 50,
            y: 50,
            width: window?.size?.w || 900,
            height: window?.size?.h || 700,
            }}
            minHeight={400}
            minWidth={500}
            maxHeight={1200}
            maxWidth={1600}
            className="controlPanel-popoutWindow"
            dragHandleClassName="handle"
            key={`popout_indx_${index}`}
        >
            <>
                <header className="handle"><span>{window.name}</span><button onClick={() => PopoutManager.removeWindow(window.id)}>x</button></header>
                <hr />
                <div className="controlPanel-popoutWindow-content">
                    {window.el}
                </div>
            </>
        </Rnd>
    );
}

const ControlPanelComponent = React.memo(({ socket, config }) => {

    const [popupCount, setPopoupCount] = useState(PopoutManager.getWindowCount() || 0);

    useEffect(() => {
        const handleNewComponent = () => {
            setPopoupCount(PopoutManager.getWindowCount());
        };
        PopoutManager.addListener(handleNewComponent);
        return () => {
            PopoutManager.removeListener(handleNewComponent);
        };
    }, []);

    // Register auth check
    useAuthCheck(socket);

    return (
        <div className="controlPanel-wrapper">
            {
                PopoutManager.getWindows().map((window, index) =>
                    <Popout window={window} index={index} key={"popout_w_"+index} />
                )
            }
            <div className="controlPanel-component">
                <HorizontalBarComponent socket={socket} />
                <InteractiveComponent config={config} socket={socket} />
                <ConsoleComponent socket={socket} />
            </div>
        </div>
    );
});
export default ControlPanelComponent;
