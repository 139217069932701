
import "./Announcer.scss";
import { AnimatePresence, motion } from "framer-motion";

import { useState, useEffect, useRef  } from 'react';

const Announcement = ({config}) => {

    const [position, setPosition] = useState(0);
    const containerRef = useRef(null);
    const textRef = useRef(null);
    const animationRef = useRef(null);

    useEffect(() => {
        const container = containerRef.current;
        const textElement = textRef.current;

        const containerWidth = container.offsetWidth;
        const textWidth = textElement.offsetWidth;

        setPosition(containerWidth);

        const animate = () => {
          setPosition((prevPosition) => {
            const newPosition = prevPosition - 1;

            if (newPosition < -textWidth) {
              return containerWidth;
            }

            return newPosition;
          });

          animationRef.current = requestAnimationFrame(animate);
        };

        animationRef.current = requestAnimationFrame(animate);

        return () => {
          cancelAnimationFrame(animationRef.current);
        };
      }, []);


    //{config?.announcement}
    return (
            <motion.div
              className="app__graphics_v2_ann"
              initial={{scaleX: 0}}
              animate={{scaleX: 1}}
              exit={{scaleX: 0, opacity: 0}}
              transition={{bounce: false}}
              key={"announcement-graphic"}
              style={{
                background: config?.buttons?.announcer?.colors?.announcerBackground?.color,
                outline: `1px solid ${config?.buttons?.announcer?.colors?.announcerText?.color}`
              }}
            >
                <p
                  style={{color: config?.buttons?.announcer?.colors?.announcerText?.color}}
                >RACE CONTROL: </p>
                <div className="app__graphics_v2_ann-text" ref={containerRef}>
                    <span
                        ref={textRef}
                        style={{
                            transform: `translateX(${position}px)`,
                            color: config?.buttons?.announcer?.colors?.announcerFont?.color
                        }}
                    >{config?.announcerMessage}</span>
                </div>

            </motion.div>
    );
};

export default Announcement;