import { Route, Routes } from "react-router-dom";

import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Admin from "./pages/Admin/Admin";
import Graphics from "./pages/Graphics/Graphics";
import Login from "./pages/Login/Login";
import Marshall from "./pages/Marshall/Marshall";
import PageNotFound from "./pages/Admin/AdminPanelComponents/NotFound/NotFound";
import Home from "./pages/Home/Home";

function App() {
  return (
      <div className="App">
        <Routes>
          <Route path={"/login"} element={<Login />} />
          <Route path={"/graphics/:hash"} element={<Graphics/>} />
          <Route path={"/user/*"} element={
              <Admin/>
          } />
          <Route path={"/marshall/*"} element={
            <Marshall />
          } />
          <Route path={"/home"} element={<Home />} />
          <Route path={"/*"} element={<PageNotFound/>} />
        </Routes>
      </div>
  );
};
export default App;
