
import "./CustomButtonsComponents.scss";
import MessageStore from "../../../../../managers/ConsoleMessageManager";

import { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { designData } from "../../../../../config/fields";
import { colorSelectorDummyData } from "../../../../../config/const";

import { IoIosArrowBack, IoIosArrowDown, IoIosArrowForward, IoIosArrowUp, IoMdTime, IoIosSpeedometer } from "react-icons/io";
import { MdOutlineSocialDistance, MdSettings } from "react-icons/md";

import L from "leaflet";
import 'leaflet/dist/leaflet.css';

import { Wheel } from "@uiw/react-color";
import ShadeSlider from '@uiw/react-color-shade-slider';
import Alpha from '@uiw/react-color-alpha';
import { hsvaToHex, hexToHsva } from '@uiw/color-convert';

import VerticalScoreboard from "../../../../../components/VerticalScoreboard/VerticalScoreboard";
import HorizontalScoreboard from "../../../../../components/HorizontalScoreboard/HorizontalScoreboard";
import ResultsByClass from "../../../../../components/ResultsByClass/ResultsByClass";
import StartGridGraphic from "../../../../../components/StartGrid/StartGridGraphic";
import TrackPlan from "../../../../../components/TrackPlan/TrackPlan";
import RaceResults from "../../../../../components/RaceResults/RaceResults";
import Battles from "../../../../../components/Battles/Battles";
import Announcer from "../../../../../components/Announcer/Announcer";
import RaceWinner from "../../../../../components/RaceWinner/RaceWinner";
import FastestLapWidget from "../../../../../components/FastestLapWidget/FastestLapWidget";
import LeaderWidget from "../../../../../components/LeaderWidget/LeaderWidget";
import TimerWidget from "../../../../../components/TimerWidget/TimerWidget";
import WeatherWidget from "../../../../../components/WeatherWidget/WeatherWidget";

import { BsAlignBottom, BsAlignCenter, BsAlignEnd, BsAlignStart, BsAlignTop } from "react-icons/bs";
import { IoMdSave } from "react-icons/io";

const width = 1920;
const height = 1080;
const tileSize = 30;
const borderColor = "#1e2342";

const rows = Math.floor(height / tileSize);
const cols = Math.floor(width / tileSize);

const Grid = ({ buttonData }) => {
    const parsedMaxWidth = buttonData?.maxWidth;
    const parsedMaxWidthHeight = buttonData?.maxHeight;
    const parsedLeft = buttonData?.left;
    const parsedTop = buttonData?.top;

    const elementRef = useRef(null);

    useEffect(() => {
        if (elementRef.current) {
            elementRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }, [elementRef, buttonData]);

    return (
        <div className="graphicsPositionChange-body">
            <div className="graphicsPositionChange-body-inner">
                <svg width={cols * tileSize} height={rows * tileSize}>
                    {Array.from({ length: rows }, (_, rowIndex) => (
                        Array.from({ length: cols }, (_, colIndex) => (
                            <rect
                                key={`rect_${rowIndex}_${colIndex}`}
                                x={colIndex * tileSize}
                                y={rowIndex * tileSize}
                                width={tileSize}
                                height={tileSize}
                                stroke={borderColor}
                                fill="transparent"
                            />
                        ))
                    ))}
                </svg>
                {
                    buttonData &&
                    <div
                        className="graphics-element"
                        ref={elementRef}
                        style={{left: parsedLeft, top: parsedTop, width: parsedMaxWidth, height: parsedMaxWidthHeight}}
                    >
                        {`[ left: ${buttonData?.left}px, top: ${buttonData?.top}px ]`}
                    </div>
                }
            </div>
        </div>
    );
};


export const GraphicsPositionChange = ({socket}) => {

    const config = useSelector(state => state.config);
    const props = useSelector(state => state.props);

    const buttonData = config.buttons?.[props?.[0]];
    const [position, setPosition] = useState({});
    const [inc, setInc] = useState(45);

    let w;
    let h;

    if (designData[config?.style]?.sizes?.[props?.[0]]) {
        w = designData[config?.style]?.sizes?.[props?.[0]].w;
        h = designData[config?.style]?.sizes?.[props?.[0]].h;
    }

    useEffect(() => {
            setPosition({
                left: buttonData?.left, top: buttonData?.top,
                maxWidth: w || buttonData?.maxWidth, maxHeight: h || buttonData?.maxHeight
            })
    }, [props]);

    const calculateMax = (box, what) => {
        return what / inc - box / inc;
    };

    const saveElement = () => {
        socket.emit("update", {what: 2, data: [props?.[0], position.left, position.top]}, (callback) => {
            MessageStore.addMessage(callback);
        });
    };

    const centerElement = () => {
        const centerX = (1920 / inc) / 2;
        const centerY = (1080 / inc) / 2;

        const elementOffsetX = Math.round((w || buttonData?.maxWidth) / inc / 2);
        const elementOffsetY = Math.round((h || buttonData?.maxHeight) / inc / 2);

        setPosition({
            ...position,
            left: Math.round(((centerX - elementOffsetX) * inc) / inc) * inc,
            top: Math.round(((centerY - elementOffsetY) * inc) / inc) * inc,
        });
    };

    return (
        <div className="graphicsPositionChange-wrapper">
            <div className="graphicsPositionChange-position-buttons">
                <span>{props?.[1]?.name}</span>
                <hr />
                <div className="graphicsPositionChange-controls-values-left">
                    <p>Left offset</p>
                    <div className="graphicsPositionChange-controls-values-left-inputs">
                        <input type="range" orient="vertical" step={1} min={0} max={calculateMax(w || buttonData?.maxWidth, 1920)}
                            onChange={(e) => setPosition({...position, left: e.target.value * inc})}
                            value={position.left / inc}
                        />
                        <span>{`${position.left} px`}</span>
                    </div>
                </div>
                <div className="graphicsPositionChange-controls-values-left">
                    <p>Top offset</p>
                    <div className="graphicsPositionChange-controls-values-left-inputs">
                        <input type="range" step={1} min={0} max={calculateMax(h || buttonData?.maxHeight, 1080)}
                            onChange={(e) => setPosition({...position, top: e.target.value * inc})}
                            value={position.top / inc}
                        />
                        <span>{`${position.top} px`}</span>
                    </div>
                </div>
                <hr />
                <div className="graphicsPositionChange-controls-inc">
                    <p>Increment</p>
                    <input type="number" value={inc} onChange={(e) => setInc(
                        e.target.value < 0 ? 0 : e.target.value > 100 ? 100 : Number(e.target.value)
                    )}/>
                    <p>px</p>
                </div>
                <hr />
                <div className="graphicsPositionChange-save">
                    <button  style={{background: "#3446eb"}} onClick={() => saveElement()}><IoMdSave size={"25px"}/></button>
                </div>

            </div>
            <div className="graphicsPositionChange-controls-screen">
                <div className="graphicsPositionChagne-controls-sliders">
                    <button><BsAlignStart size={"18px"} onClick={() => setPosition({...position, left: 0})}/></button>
                    <button><BsAlignEnd size={"18px"} onClick={() => setPosition({...position, left: ((tileSize * cols) - position.maxWidth)})}/></button>
                    <button><BsAlignTop size={"18px"} onClick={() => setPosition({...position, top: 0})} /></button>
                    <button><BsAlignBottom size={"18px"} onClick={() => setPosition({...position, top: ((tileSize * rows) - position.maxHeight)})}/></button>
                    <button><BsAlignCenter size={"18px"} onClick={() => centerElement()}/></button>
                    <hr />
                    <button onClick={() => setPosition({...position, left: position.left <= 0 ? 0 : position.left - inc})}
                    ><IoIosArrowBack size="25px"/></button>
                    <button
                        onClick={() => setPosition({...position, top: position.top <= 0 ? 0 : position.top - inc})}
                    ><IoIosArrowUp size="25px"/></button>
                    <button
                        onClick={() => setPosition({...position, top: position.top >= (1080 - position.maxHeight) ? (1080 - position.maxHeight) : position.top + inc})}
                    ><IoIosArrowDown size="25px"/></button>
                    <button onClick={() => setPosition({...position, left: position.left >= (1920 - position.maxWidth) ? (1920 - position.maxWidth) : position.left + inc})}
                    ><IoIosArrowForward size="25px"/></button>
                </div>
                <Grid buttonData={position}/>
                <div className="graphicsPositionChange-minihud">
                    <div className="graphicsPositionChange-minihud-grid">
                        <div
                            className="graphicsPositionChange-minihud-item"
                            style={{
                                width: `${position.maxWidth / 12}px`,
                                height: `${position.maxHeight / 12}px`,
                                left: `${position.left / 12}px`,
                                top: `${position.top / 12}px`
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export const OpenWeatherSelector = ({socket}) => {
    const mapRef = useRef(null);
    const [coords, setCoords] = useState([]);

    const config = useSelector(props => props.config);

    useEffect(() => {
        const map = L.map(mapRef.current).setView([0,0], 2);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '',
        }).addTo(map);

        const onMapClick = (event) => {
            const { lat, lng } = event.latlng;
            setCoords([lat, lng]);
        };
        map.on('click', onMapClick);
        return () => {
            map.off("click", onMapClick);
            map.remove();
        }
    }, []);

    const fetchData = () => {
        if (coords.length !== 2) { return; }
        socket?.emit("update", {what: 4,
            data: [...coords, document.getElementById("city_input").value]}, (callback) => {
            MessageStore.addMessage(callback);
        });
    };

    return (
        <div className="weatherSelector-wrapper">
            <div className="weatherSelector-wrapper-body">
                <div className="weatherSelector-map" ref={mapRef} />
                <div className="weatherSelector-details">
                    <div className="weatherSelector-current-data">
                        <header>Select your location</header>
                        <span>
                            {
                                coords?.length > 0 ?
                                "Coordinates successfully selected 📍"
                                :
                                "Click anywhere in the map to get coordinates of your race location 📍"
                            }
                        </span>
                        <input id="city_input" type="text" placeholder="Name of race location..."/>
                        <button onClick={() => fetchData()}>Save location data</button>
                    </div>
                    <div className="weatherSelector-set-data">
                        <header>Current location data 🗺️</header>
                        <div className="weatherSelector-item">
                            <p>City</p><span>{config?.weatherData?.city}</span>
                        </div>
                        <div className="weatherSelector-item">
                            <p>Code</p><span>{config?.weatherData?.weatherCode}</span>
                        </div>
                        <div className="weatherSelector-item">
                            <p>Temperature</p><span>{config?.weatherData?.temperature}</span>
                        </div>
                        <div className="weatherSelector-item">
                            <p>Wind direction</p><span>{config?.weatherData?.windDirection}</span>
                        </div>
                        <div className="weatherSelector-item">
                            <p>Wind speed</p><span>{config?.weatherData?.windSpeed}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export const AnnouncerDataSelector = ({socket}) => {

    const config = useSelector(props => props.config);
    const message = config?.announcerMessage;

    const saveMessage = () => {
        socket.emit("update", {what: 5, data:
            ["announcerMessage", document.getElementById("announcerMessage-textarea").value]}, (callback) => {
            MessageStore.addMessage(callback);
        });
    }

    return (
        <div className="verticalScoreboardDataType-wrapper">
            <header>
                <span>Announcer message</span>
                <p>Type a message to be displayed on-screen in announcer graphic</p>
            </header>
            <div className="announcerMessage-wrapper-body">
                <textarea
                    type="text"
                    id="announcerMessage-textarea"
                    className="announcerMessage-input"
                    placeholder="Enter message..."
                    defaultValue={message}
                />
            </div>
            <div className="announcerMessage-buttons">
                <button onClick={() => saveMessage()}>Save message</button>
            </div>
        </div>
    );
};

export const SetResultsByClass = ({socket}) => {

    const config = useSelector(state => state.config);

    const [selectClass, setSelectClass] = useState(config?.selectedClass || "");

    useEffect(() => {

    }, [config?.competitorClasses])

    const setClass = () => {
        socket.emit("update", {what: 5, data: ["selectedClass", selectClass]}, (callback) => {
            MessageStore.addMessage(callback);
        })
    };

    return (
        <div className="verticalScoreboardDataType-wrapper">
            <header>
                <span>Class selection</span>
                <p>Select a class you want to display on-screen.</p>
            </header>
            <div className="verticalScoreboardDataType-body">
                {
                    config?.competitorClasses ?
                    config?.competitorClasses
                        .filter((item) => item)
                        .map((item, index) =>
                        <button
                            key={"res_by_class_btn" + index}
                            style={{background: config?.selectedClass === item ? "#3446eb" :
                                selectClass === item ? "#f7980a" : "#19243b"}}
                            onClick={() => setSelectClass(item)}
                        ><p>{item}</p></button>
                    )
                    :
                    <p>No classes found..</p>
                }
            </div>
            <hr className="resultsByClass-hr"/>
            <button
                className="resultsByClass-btn"
                onClick={() => setClass()}
            >Select</button>
        </div>
    );
};

export const VerticalScoreboardDataType = ({socket}) => {

    const config = useSelector(state => state.config);

    const [mode, setMode] = useState(config?.buttons?.verticalScoreboard?.mode);
    const [notify, setNotify] = useState(null);

    const buttons = [
        {
            name: "time",
            description: "Display data based on difference between competitor and the leader.",
            icon: <IoMdTime size={"35px"} />
        },
        {
            name: "speed",
            description: "Display competitors average speed during last lap.",
            icon: <IoIosSpeedometer size={"35px"}/>
        },
        {
            name: "gap",
            description: "Display data based on how much time competitors are behind each other.",
            icon: <MdOutlineSocialDistance size={"35px"}/>
        }
    ];

    useEffect(() => {
        setMode(config?.buttons?.verticalScoreboard?.mode);
    }, [config?.buttons?.verticalScoreboard?.mode])

    const handleButtonChange = (name) => {
        socket?.emit("update", {
            what: 6,
            data: ["verticalScoreboard", "mode", name]
        }, (callback) => {
            MessageStore.addMessage(callback);
            setNotify(config?.buttons?.verticalScoreboard?.mode);
            setTimeout(() => {
                setNotify(null);
            }, 3000);
        });
    };

    return (
        <div className="verticalScoreboardDataType-wrapper">
            <header>
                <span>Vertical scoreboard data</span>
                <p>Change data being shown in vertical scoreboard between multiple modes</p>
            </header>
            <div className="verticalScoreboardDataType-body">
                {
                    buttons.map((btn) =>
                        <button
                            style={{background: mode === btn.name ? "#3446eb" : "#1a273e"}}
                            onClick={() => handleButtonChange(btn.name)}
                            key={`verticalScoreboardDataType_${btn.name}`}
                        >
                            <p>{btn.icon}{btn.name.charAt(0).toUpperCase() + btn.name.slice(1)}</p>
                            <hr />
                            <span>{btn.description}</span>
                        </button>
                    )
                }
                {
                    notify &&
                    <div className="notify-msg">{`Results mode changed to ${mode}`}</div>
                }
            </div>
        </div>
    );
};


const ColorSelectorSettingsItem = ({ colorName, color, defaultValue, onChangeColor }) => {
    const [hsva, setHsva] = useState(color);
    const [hexInput, setHexInput] = useState(hsvaToHex(hsva));
    const [copySuccess, setCopySuccess] = useState(false);

    const handleCopy = () => {
        navigator.clipboard.writeText(hexInput).then(() => {
            setCopySuccess(true);
            setTimeout(() => setCopySuccess(false), 2000); // Reset after 2 seconds
        });
    };

    const handleSetColor = () => {
        onChangeColor(hsvaToHex(hsva), colorName);
    };

    const handleResetDefault = () => {
        onChangeColor(defaultValue);
        setHsva(hexToHsva(defaultValue));
        setHexInput(defaultValue);
    };

    const handleHexInputChange = (event) => {
        const newHexInput = event.target.value;
        const isValidHex = /^#([0-9A-Fa-f]{3}){1,2}$/.test(newHexInput);

        if (isValidHex) {
            setHexInput(newHexInput);
            setHsva(hexToHsva(newHexInput));
        } else {
            setHexInput(newHexInput);
        }
    };

    return (
        <div className="changeButtonColors-selector">
            <div className="changeButtonColors-selector-colors">
                <Wheel
                    style={{width: 140, height: 140, marginTop: 10}}
                    width={140}
                    height={140}
                    color={hsva}
                    onChange={(color) => setHsva({...hsva, ...color.hsva})}
                />
                <ShadeSlider
                    hsva={hsva}
                    style={{width: 30, height: 130, marginTop: 10}}
                    width={16}
                    height={130}
                    direction="vertical"
                    onChange={(newShade) => {
                        setHsva({ ...hsva, ...newShade });
                    }}
                />
            </div>
            <div className="changeButtonColors-selector-output" style={{background: hsvaToHex(hsva)}}>
                <p>{hsvaToHex(hsva)}</p>
            </div>
            <div className="changeButtonColors-selector-hex-intput">
                <p>Hex color</p>
                <input
                    type="text"
                    maxLength={7}
                    value={hexInput}
                    onChange={handleHexInputChange}
                />
            </div>
            <div className="changeButtonColors-selector-buttons">
                <button onClick={() => handleSetColor()}>Set</button>
                <button
                    onClick={handleCopy}
                    style={copySuccess ? {animation: 'buttonBlink 1s ease-in-out'} : {}}
                >
                    Copy
                </button>
                <button onClick={handleResetDefault}>Reset</button>
            </div>
            <hr/>
        </div>
    );
};



const manipulateConfig = (config, buttonName) => {
    if (!config) { return {}; }
    return {
        style: config?.style,
        selectedClass: "X2",
        announcerMessage:  ("This is a test message! ").repeat(10),
        weatherData: {
            city: "Amsterdam",
            weatherCode: 65,
            temperature: 25,
            windDirection: 195,
            windSpeed: 10
        },
        enableVerticalBattles: true,
        battleCompetitors: [3,4,5],
        buttons: {
            [buttonName]: config.buttons[buttonName]
        }
    };
};

export const GraphicsPreviewElement = ({socket}) => {

    const config = useSelector(state => state.config);
    const buttonData = useSelector(state => state.props);

    const buttonName = buttonData[0];
    const buttonConfig = buttonData[1];

    const [manipulatedConfig, setManipulatedConfig] = useState(manipulateConfig(config, buttonName));
    const [toggleSettings, setToggleSettings] = useState(false);
    const [background, setBackground] = useState({first: "#0e7ead", second: "#313abd"});

    const dummyData = colorSelectorDummyData()

    // const baseWidth = 1920;
    // const baseHeight = 1080;

    // const parentElement = document.querySelector('.graphicsPreviewElement-graphics');
    // const parentWidth = parentElement.offsetWidth;
    // const parentHeight = parentElement.offsetHeight;

    // const scaleFactorWidth = parentWidth / baseWidth;
    // const scaleFactorHeight = parentHeight / baseHeight;

    // const childElements = parentElement.children;
    // for (let i = 0; i < childElements.length; i++) {
    //     childElements[i].style.transform = `scale(${scaleFactorWidth}, ${scaleFactorHeight})`;
    // }

    const graphicsComponents = {
        "verticalScoreboard":   <VerticalScoreboard data={dummyData} config={manipulatedConfig} preview={true} />,
        "horizontalScoreboard": <HorizontalScoreboard data={dummyData} config={manipulatedConfig} preview={true} />,
        "resultsByClass":       <ResultsByClass data={dummyData} config={manipulatedConfig} preview={true} />,
        "startGrid":            <StartGridGraphic data={dummyData} config={manipulatedConfig} preview={true} />,
        "trackPlan":            <TrackPlan data={dummyData} config={manipulatedConfig} preview={true} />,
        "raceResults":          <RaceResults data={dummyData} config={manipulatedConfig} preview={true} />,
        "battles":              <Battles data={dummyData} config={manipulatedConfig} preview={true} />,
        "announcer":            <Announcer data={dummyData} config={manipulatedConfig} preview={true} />,
        "winner":               <RaceWinner winner={dummyData.topTenCompetitors?.[0]} config={manipulatedConfig} preview={true} />,
        "fastestLap":           <FastestLapWidget data={dummyData.competitorWithFastestLap} config={manipulatedConfig} preview={true} />,
        "raceLeader":           <LeaderWidget data={dummyData.competitorWithFastestLap} config={manipulatedConfig} preview={true}/>,
        "timer":                <TimerWidget data={dummyData} config={manipulatedConfig} preview={true}/>,
        "weather":              <WeatherWidget config={manipulatedConfig} preview={true}/>
    };

    const changeColor = (color, name) => {
        setManipulatedConfig(prevConfig => ({
            ...prevConfig,
            buttons: {
                ...prevConfig.buttons,
                [buttonName]: {
                    ...prevConfig.buttons[buttonName],
                    colors: {
                        ...prevConfig.buttons[buttonName].colors,
                        [name]: {
                            ...prevConfig.buttons[buttonName].colors[name],
                            color: color
                        }
                    }
                }
            }
        }));
    };
    const saveColors = () => {
        const newColors = manipulatedConfig.buttons[buttonName].colors;
        socket.emit("update", {what: 6, data: [buttonName, "colors", newColors]}, (response) => {
            MessageStore.addMessage(response);
        });
    };
    return (
        <div className="graphicsPreviewElement-wrapper">
            <div className="graphicsPreviewElement-content">
                <div className="graphicsPreviewElement-colors">
                    <div className="changeButtonColors-overflow">
                        {
                            Object.entries(buttonConfig.colors || {}).map(([key, item], index) =>
                            <div className="changeButtonColors-item" key={`changeButtonColors_${index}`}>
                                <header>{item?.name}</header>
                                <ColorSelectorSettingsItem
                                    colorName={key}
                                    color={hexToHsva(item?.color)}
                                    defaultValue={"#FFFFFF"}
                                    onChangeColor={(color, name) => changeColor(color, name)}
                                />
                            </div>
                            )
                        }
                    </div>
                </div>
                <div
                    className="graphicsPreviewElement-graphics"
                    style={{backgroundImage: `
                        linear-gradient(45deg, ${background.first} 25%, transparent 25%),
                        linear-gradient(-45deg, ${background.first} 25%, transparent 25%),
                        linear-gradient(45deg, transparent 75%, ${background.first} 75%),
                        linear-gradient(-45deg, transparent 75%, ${background.first} 75%)
                    `,
                    backgroundSize: "60px 60px", backgroundPosition: "0 0, 0 30px, 30px -30px, -30px 0px",
                    backgroundColor: background.second
                    }}
                >
                    {graphicsComponents[buttonName]}
                </div>
                {
                    toggleSettings &&
                    <div className="graphicsPreviewElement-settings">
                        <header><MdSettings size={"30px"}/></header>
                        <div className="graphicsPreviewElement-settings-content">
                            <div className="graphicsPreviewElement-settings-content-item">
                                <p>Background tile 1</p>
                                <div className="graphicsPreviewElement-settings-content-col">
                                    <Wheel
                                        style={{width: 120, height: 120, marginTop: 10}}
                                        width={120}
                                        height={120}
                                        color={hexToHsva(background.first)}
                                        onChange={(color) => setBackground({...background, first: hsvaToHex(color.hsva)})}
                                    />
                                    <ShadeSlider
                                        hsva={hexToHsva(background.first)}
                                        style={{width: 30, height: 120, marginTop: 10}}
                                        width={16}
                                        height={120}
                                        direction="vertical"
                                        onChange={(newShade) => {
                                            setBackground({...background, first: hsvaToHex({...hexToHsva(background.first), ...newShade})});
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="graphicsPreviewElement-settings-content-item">
                                <p>Background tile 2</p>
                                <div className="graphicsPreviewElement-settings-content-col">
                                    <Wheel
                                        style={{width: 120, height: 120, marginTop: 10}}
                                        width={120}
                                        height={120}
                                        color={hexToHsva(background.second)}
                                        onChange={(color) => setBackground({...background, second: hsvaToHex(color.hsva)})}
                                    />
                                    <ShadeSlider
                                        hsva={hexToHsva(background.second)}
                                        style={{width: 30, height: 120, marginTop: 10}}
                                        width={16}
                                        height={120}
                                        direction="vertical"
                                        onChange={(newShade) => {
                                            setBackground({...background, second: hsvaToHex({...hexToHsva(background.second), ...newShade})});
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="graphicsPreviewElement-buttons">
                <button onClick={() => saveColors()}>Save set colors</button>
                <button onClick={() => setToggleSettings(prev => !prev)}>Background colors</button>
            </div>
        </div>
    );
}
