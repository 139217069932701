import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const PageNotFound = () => {

    const navigate = useNavigate();
    useEffect(() => {
        navigate("/home");
    }, []);

    return (<></>);
};
export default PageNotFound;