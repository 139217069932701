import React, { useState, useMemo } from "react";

import "./InteractiveScreen.scss";
import "./InteractiveScreenComponents.scss";

import MessageStore from "../../../../../managers/ConsoleMessageManager";
import { getRaceBackendAddress } from "../../../../../Functions";
import { KeyboardPressListener } from "../HorizontalBar/HorizontalBar";
import { HorizontalAvailableRaces } from "../HorizontalBar/HorizontalBar";
import { HorizontalDataGraph } from "../HorizontalBar/HorizontalBar";
import { Switch } from "../HorizontalBar/HorizontalBar";

// Icon imports
import { SiDatabricks } from "react-icons/si";
import { SlGrid } from "react-icons/sl";
import { BsToggleOn } from "react-icons/bs";
import { PiSwordFill } from "react-icons/pi";
import { CgIfDesign } from "react-icons/cg";
import { IoIosColorPalette, IoMdWarning } from "react-icons/io";
import { IoPersonSharp } from "react-icons/io5";
import { FaLocationDot, FaListOl } from "react-icons/fa6";
import { VscError } from "react-icons/vsc";

// Component imports
import InteractiveLiveResults from "./InteractiveScreenComponents/LiveResults/LiveResults";
import InteractiveEnabledGrid from "./InteractiveScreenComponents/EnabledGrid/EnabledGrid";
import InteractiveStartGrid from "./InteractiveScreenComponents/StartGrid/StartGrid";
import InteractiveBattles from "./InteractiveScreenComponents/BattlesCreator/Battles";
import InteractiveGraphicsDesign from "./InteractiveScreenComponents/GraphicsDesigns/Designs";
import InteractiveMyDesigns from "./InteractiveScreenComponents/ColorSchemes/ColorDesigns";
import InteractiveTrackSelector from "./InteractiveScreenComponents/TrackSelector/Tracks";
import InteractiveCompetitorImages from "./InteractiveScreenComponents/CompetitorImages/Images";
import GraphicsSelector from "./InteractiveScreenComponents/GraphicsSelector/SelectGraphics";
import { GraphicsPositionChange } from "../InteractiveButtons/CustomButtonsComponents";
import { GraphicsPreviewElement } from "../InteractiveButtons/CustomButtonsComponents";

import { AnnouncerDataSelector, OpenWeatherSelector, SetResultsByClass, VerticalScoreboardDataType } from "../InteractiveButtons/CustomButtonsComponents";
import { pageNames } from "../../../../../config/namespaces";

// Extra components that are toggled via buttons
import ChangeLog from "./ChangeLog";
import HelpComponent from "./Help";
import AvailableRacesComponent from "./AvailableRaces";
import { useSelector, useDispatch } from "react-redux";

const InteractiveScreenErrors = () => {

    const errors = useSelector(state => state.error.data);

    const warningCount = errors?.filter(err => err.type === 'Warning').length;
    const errorCount = errors?.filter(err => err.type === 'Error').length;

    return (
        <div className="interactive-screen-graphics-information">
            <div className="interactive-screen-graphics-information-inner">
            {
                !errors?.length ? "" :
                errors.map((error, index) =>
                    <span key={"error" + index}>
                        {
                            error.type === "Error" ?
                                <VscError size={"20px"} color="#ed4747"/>
                                :
                                <IoMdWarning size={"20px"} color="#f7980a"/>
                        }
                        <h5>{error.name}</h5>
                    </span>
                )
            }
            </div>
            <div className="interactive-screen-graphics-information-errors">
                {errorCount ? <p style={{background: "#ed4747"}}><VscError size={"20px"}/><h5>{errorCount}</h5></p> : ""}
                {warningCount ? <p style={{background: "#f7980a"}}><IoMdWarning size={"20px"}/><h5>{warningCount}</h5></p> : ""}
            </div>
        </div>
    );
}

const Skeleton = () => {
    return (
        <div
            className="interactive-screen-main"
            style={{background: "#0c111c"}}
        >
            <div
                className="interactive-screen-main-selection-row config_load_skeleton"
                style={{width: "250px", marginRight: "10px"}}
            />
            <div className="interactive-screen-main-render-area-wrapper config_load_skeleton"/>
        </div>
    );
}


const InteractiveScreenMain = ({config, socket }) => {

    const page = useSelector(state => state.page);
    const dispatch = useDispatch();

    const tabs = useMemo(() => ({
        [pageNames.LiveFeed]: {component: InteractiveLiveResults, props: {}, icon: <SiDatabricks size={"18px"}/>, name: "Live feed"},
        [pageNames.ViewEnabled]: {component: InteractiveEnabledGrid, props: {}, icon: <BsToggleOn size={"18px"}/>, name: "View graphics"},
        [pageNames.StartGrid]: {component: InteractiveStartGrid, props: {socket}, icon: <SlGrid size={"18px"}/>, name: "Select start grid"},
        [pageNames.PositionBattles]: {component: InteractiveBattles, props: {socket}, icon: <PiSwordFill size={"21px"}/>, name: "Position battles"},
        [pageNames.GraphicsStyles]: {component: InteractiveGraphicsDesign, props: {socket}, icon: <CgIfDesign size={"23px"}/>, name: "Graphics packages"},
        [pageNames.MyDesigns]: {component: InteractiveMyDesigns, props: {socket}, icon: <IoIosColorPalette size={"18px"}/>, name: "Graphics colors"},
        [pageNames.CompetitorImages]: {component: InteractiveCompetitorImages, props: {socket}, icon: <IoPersonSharp size={"18px"}/>, name: "Upload images"},
        [pageNames.TrackSelector]: {component: InteractiveTrackSelector, props: {socket}, icon: <FaLocationDot size={"18px"}/>, name: "Select track"},
        [pageNames.GraphicsSelector]: {component: GraphicsSelector, props: {socket}, icon: <FaListOl size={"23px"}/>, name: "Graphics selector"},
    }), [config]);

    // Accessed only by redux SET_PAGE
    const extraTabs = useMemo(() => ({
        [pageNames.ChangeLog]: {component: ChangeLog, props: {}},
        [pageNames.Help]: {component: HelpComponent, props: {}},
        [pageNames.AvailableRaces]: {component: AvailableRacesComponent, props: {socket}},
        [pageNames.VerticalScoreboardDataType]: {component: VerticalScoreboardDataType, props: {socket}},
        [pageNames.ResultsByClass]: {component: SetResultsByClass, props: {socket}},
        [pageNames.AnnouncerSelector]: {component: AnnouncerDataSelector, props: {socket}},
        [pageNames.WeatherSelector]: {component: OpenWeatherSelector, props: {socket}},
        [pageNames.GraphicsSelector]: {component: GraphicsSelector, props: {socket}},
        [pageNames.GraphicsPosition]: {component: GraphicsPositionChange, props: {socket}},
        [pageNames.GraphicsColors]: {component: GraphicsPreviewElement, props: {socket}}
    }), []);

    const Component = tabs[page]?.component || extraTabs[page]?.component;
    const props = tabs[page]?.props || extraTabs[page]?.props;

    // Page config
    const InteractiveScreenMain_config = {
        skeleton: <Skeleton />,
        ret:
        <>
            <div className="interactive-screen-main">
                <div className="interactive-screen-main-selection-row">
                    <header><p>Pages</p></header>
                    <ul>
                    {
                        Object.entries(tabs).map(([key, value], index) =>
                            <li
                                style={{background: page === key ?
                                    "#1a273e" : "",
                                    color: page === key ? "white" : "#8f8f8f"
                                }}
                                onClick={() => {
                                    dispatch({type: "SET_PAGE", payload: key})
                                }}
                                key={"tabs_interactive" + index}
                            ><p>{value.icon}</p><span>{value.name}</span></li>
                        )
                    }
                    </ul>
                </div>
                <div className="interactive-screen-main-render-area-wrapper">
                    <InteractiveScreenErrors />
                    <div className="interactive-screen-main-render-area-wrapper-inner">
                        <div className="interactive-screen-main-render-area">
                            <Component {...props} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    };

    // If not config is loaded return loading skeleton untill config loads
    return config ? InteractiveScreenMain_config.ret : InteractiveScreenMain_config.skeleton;
};


const RightSidePanel = ({config, socket}) => {
    const [copyButtonText, setCopyButtonText] = useState('Copy graphics link');
    const textToCopy = `${getRaceBackendAddress().slice(0, -4)}/graphics/${encodeURIComponent(localStorage.getItem("userId"))}`;

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            setCopyButtonText('Copied!');
            setTimeout(() => setCopyButtonText('Copy graphics link'), 2000);
        } catch (err) {
            console.error('Failed to copy text: ', err);
        }
    };

    const RightSidePanel_config = {
        skeleton: <div  className="interactive-screen-right-panel config_load_skeleton" style={{width: "290px", marginLeft: "10px"}} />,
        ret: <>
                <div className="interactive-screen-right-panel">
                    <div className="interactive-screen-right-panel-item">
                        <header><p>Listeners</p></header>
                        <KeyboardPressListener config={config} socket={socket}/>
                    </div>
                    <hr/>
                    <div className="interactive-screen-right-panel-item">
                        <header><p>Competitor images</p></header>
                        <div className="keyboardPressListener-component">
                            <p>Use</p>
                            <Switch rvalue={"Enabled"} lvalue={"Disabled"} onChange={(value) => {
                                socket.emit("update", {what: 5, data: ["useCompetitorImages", value]}, (callback) => {
                                    MessageStore.addMessage(callback);
                                });
                            }} defaultValue={config?.useCompetitorImages}/>
                        </div>
                    </div>
                    <hr />
                    <div className="interactive-screen-right-panel-item">
                        <header><p>Links</p></header>
                        <button
                            className="interactive-screen-right-panel-item-link"
                            onClick={handleCopy}>{copyButtonText}</button>
                    </div>
                    <hr />
                    <div className="interactive-screen-right-panel-item">
                        <HorizontalAvailableRaces config={config} socket={socket}/>
                    </div>
                    <hr />
                    <div className="interactive-screen-right-panel-item">
                        <header><p>Datastream</p></header>
                        <HorizontalDataGraph />
                    </div>
                </div>
            </>
    };
    return config ? RightSidePanel_config.ret : RightSidePanel_config.skeleton;
};

const InteractiveScreen = ({config, socket }) => {
    return (
        <div className="interactive-screen">
            {/* <InteractiveScreenEdit config={config} socket={socket} /> */}
            <InteractiveScreenMain config={config} socket={socket} />
            <RightSidePanel config={config} socket={socket}/>
        </div>
    );
};

export default InteractiveScreen;