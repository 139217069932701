import { useEffect, useState, useCallback } from "react";
import MessageStore from "../../../../../../../managers/ConsoleMessageManager";
import { IoMdSettings } from "react-icons/io";

import { useSelector } from "react-redux";

// Configuration settings for setting up grids correctly

const gridConfigs = {
    karting: { mode: '1', columns: '2', lines: '2', side: "right" },
    moto: { mode: '5', columns: '2', lines: '3', side: "right" },
    rally_lv: { mode: '2', columns: '3', lines: '3', side: "right" },
    rally_eu_q: { mode: '3', columns: '2', lines: '2', side: "right" }
};

const gridTemplateItems = [
    {name: "Karting", img: "Karting", options: gridConfigs.karting},
    {name: "Motocross", img: "Motocross", options: gridConfigs.moto},
    {name: "Rallycross Latvia", img: "Rallycross_lv", options: gridConfigs.rally_lv},
    {name: "Rallycross EU Q", img: "Rallycross_eu_q", options: gridConfigs.rally_eu_q}
];

const InteractiveStartGrid = ({ socket }) => {

    const config = useSelector(state => state.config);

    const [gridOptions, setGridOptions] = useState(null);
    const [selectedButton, setSelectedButton] = useState(
        config?.buttons?.startGrid?.configuration?.mode
    );

    useEffect(() => {
        const configuration = config.buttons?.startGrid?.configuration;
        setGridOptions(configuration);
    },[]);


    const updateGridOptions = useCallback((newOptions) => {
        setSelectedButton(newOptions.mode);
        setGridOptions(prevOptions => ({...prevOptions, ...newOptions}));
        MessageStore.addMessage({message: `Setting new grid options: ${JSON.stringify(newOptions)}`, status: 200, parent: 2});
    }, []);

    const setSelectedSettings = useCallback(() => {
        socket.emit("update", {what: 1, data: gridOptions}, (response) => {
            MessageStore.addMessage(response);
        });
    }, [socket, gridOptions]);

    const isActive = (mode) => {
        return mode === config?.buttons?.startGrid?.configuration?.mode ? "#3446eb" : "#1a273e";
    }
    const isSelected = (mode) => {
        return mode === selectedButton;
    }

    return (
        <div className="interactive-screen-main-start-grid">
            <div className="interactive-screen-main-start-grid-setup">
                <header><p><IoMdSettings /></p><span>Start grid settings</span></header>
                <p>Available grid templates</p>
                <div className="interactive-screen-main-start-grid-setup-templates">
                    {
                        gridTemplateItems.map((item, index) =>
                            <div
                                className={
                                    `interactive-screen-main-start-grid-setup-templates-item
                                     ${isSelected(item.options.mode) ? "start-grid-btn-flash" : ""}
                                    `
                                }
                                key={`grid_template_item_${index}`}
                                style={{
                                    background: isActive(item.options.mode)
                                }}
                                onClick={() => updateGridOptions(item.options)}
                            >
                                <span>{item.name}</span>
                                <img src={`/svg/${item.img}.svg`}/>
                            </div>
                        )
                    }
                </div>
                <hr />
                <div className="interactive-screen-main-start-grid-setup-options">
                    {
                        gridOptions && [1,2,3].includes(Number(gridOptions.mode)) &&
                        <div className="interactive-screen-main-start-grid-setup-options-item">
                            <p>First turn after start</p>
                            <div className="grid-row-items-turn">
                                <button
                                    onClick={() => updateGridOptions({side: "left"})}
                                    style={{background: gridOptions?.side === "left" ? "#0099ff" : "#1a273e"}}
                                >Left</button>
                                <button
                                    onClick={() => updateGridOptions({side: "right"})}
                                    style={{background: gridOptions?.side === "right" ? "#0099ff" : "#1a273e"}}
                                >Right</button>
                            </div>
                            <hr />
                        </div>
                    }
                </div>
                <div className="interactive-screen-main-start-grid-select-button">
                    <button onClick={() => setSelectedSettings()}>Use selected settings</button>
                </div>
            </div>
        </div>
    );
};

export default InteractiveStartGrid;