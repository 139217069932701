
import "./ReplayWidget.scss";

const ReplayWidget = (props) => {
    switch (props.config?.style) {
        case "v2":
            return (
                <div
                    className="app__replay_v2_outer"
                    style={{
                        top: props?.preview ? "30px" : props?.config?.buttons?.replay?.top,
                        left: props?.preview ? "30px" : props?.config?.buttons?.replay?.left,
                        background: props?.config?.buttons?.replay?.colors?.replayBox?.color,
                        color: props?.config?.buttons?.replay?.colors?.replayBoxFont?.color,
                        borderBottom: `15px solid ${props?.config?.buttons?.replay?.colors?.replayBoxAccent?.color}`
                    }}
                >
                    <p>REPLAY</p>
                    <div className="blinking_circle" />
                    <div className="decoration" />
                </div>
            );
        case "k1":
            return (
                <div
                    className="k1_replay"
                    style={{
                        top: props?.preview ? "30px" : props?.config?.buttons?.replay?.top,
                        left: props?.preview ? "30px" : props?.config?.buttons?.replay?.left
                    }}
                >
                    <p>REPLAY</p>
                    <div className="blinking_circle" />
                </div>
            );
    }
};
export default ReplayWidget;
