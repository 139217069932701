import { pageNames } from "../../config/namespaces";

const pageReducer = (state = pageNames.LiveFeed, action) => {
    switch (action.type) {
        case 'SET_PAGE':
            return action.payload;
        default:
            return state;
    }
};

export default pageReducer;