class PopoutManager {
    constructor() {
        this.listeners = [];
        this.popoutWindows = [];
    }
    addWindow(window) {
        this.popoutWindows.push(window);
        this.notifyListeners();
    }
    removeWindow(id) {
        this.popoutWindows = this.popoutWindows.filter(window => window.id !== id);
        this.notifyListeners();
    }
    getWindows() {
        return this.popoutWindows;
    }
    getWindowCount() {
        return this.popoutWindows.length;
    }
    addListener(listener) {
        this.listeners.push(listener);
    }
    removeListener(listenerToRemove) {
        this.listeners = this.listeners.filter(listener => listener !== listenerToRemove);
    }
    notifyListeners() {
        this.listeners.forEach(listener => listener());
    }
};

export default new PopoutManager();