import { TiWeatherPartlySunny } from "react-icons/ti";
import { AiFillDatabase } from "react-icons/ai";
import { BsFillMegaphoneFill } from "react-icons/bs";
import { pageNames } from "../../../../../config/namespaces";
import {
    OpenWeatherSelector,
    AnnouncerDataSelector,
    VerticalScoreboardDataType,
    SetResultsByClass
} from "./CustomButtonsComponents";

const CustomButtonsConfig = {
    weather: [{
        key: "weather",
        icon: <TiWeatherPartlySunny size={"17px"}/>,
        el: OpenWeatherSelector,
        id: pageNames.WeatherSelector
    }],
    verticalScoreboard: [{
        key: "VerticalResultsType",
        icon: <AiFillDatabase size={"17px"}/>,
        el: VerticalScoreboardDataType,
        id: pageNames.VerticalScoreboardDataType
    }],
    announcer: [{
        key: "announcerMessage",
        icon: <BsFillMegaphoneFill size={"17px"}/>,
        el: AnnouncerDataSelector,
        id: pageNames.AnnouncerSelector
    }],
    resultsByClass: [{
        key: "resultsByClass",
        icon: <AiFillDatabase size={"17px"}/>,
        el: SetResultsByClass,
        id: pageNames.ResultsByClass
    }]
};

export default CustomButtonsConfig;