import { useState, useEffect } from "react";

import AdministrateLogin from "./Login";
import AdministrateMain from "./Main";
import { useNavigate } from "react-router-dom";
import "./Admin.scss";

const AdministrateComponent = ({ socket }) => {

    const [loggedIn, setLoggedIn] = useState(false);
    const navigate = useNavigate();

    const handleLogin = (status) => {
        if (status) {
            setLoggedIn(true);
        }
    }
    const handleLogout = () => {
        setLoggedIn(false);
        navigate("/controlPanel");
    };

    if (!loggedIn) {
        return <AdministrateLogin socket={socket} callback={handleLogin}/>
    }
    return <AdministrateMain socket={socket} />
};

export default AdministrateComponent;
