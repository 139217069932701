import { useSelector } from "react-redux";
import { useState, useEffect, useMemo } from "react";

const validationErrorLevels = {
    ERROR: "ERROR",
    WARN: "WARN",
    INFO: "INFO",
};


const validationRules = {
    resultsByClass: {
        onChange: true,
        fn: (props) => {
            if (!props.config.selectedClass) {
                return {
                    level: validationErrorLevels.WARN,
                    message: "No class selected."
                };
            }
            if (!props.config.competitorClasses.length) {
                return {
                    level: validationErrorLevels.WARN,
                    message: "No classes found in this race."
                };
            }
            if (!props.config.competitorClasses?.includes(props.config.selectedClass)) {
                return {
                    level: validationErrorLevels.WARN,
                    message: "Selected class not found in active classes."
                }
            }
        }
    },
    startGrid: {
        fn: (props) => {
            if (!props?.data?.allCompetitors) {
                return {
                    level: validationErrorLevels.ERROR,
                    message: "No competitors found."
                }
            }
            if (!["None", "Warmup"].includes(props.data?.raceDetails?.flag)) {
                return {
                    level: validationErrorLevels.WARN,
                    message: "Start grid can only be activated during warmup phase."
                }
            }
        }
    },
    battles: {
        fn: (props) => {
            if (!props.config?.battleCompetitors?.length) {
                return {
                    level: validationErrorLevels.ERROR,
                    message: "No competitors selected for battle."
                }
            }
        }
    },
    weather: {
        fn: (props) => {
            if (!props.config?.weatherData?.weatherCode) {
                return {
                    level: validationErrorLevels.ERROR,
                    message: "No weather data selected."
                }
            }
        }
    },
    raceResults: {
        fn: (props) => {
            if (!["None", "Finish"].includes(props.data?.raceDetails?.flag)) {
                return {
                    level: validationErrorLevels.WARN,
                    message: "Cannot show results during race."
                }
            }
        }
    },
    raceLeader: {
        fn: (props) => {
            if (["None", "Warmup"].includes(props.data?.raceDetails?.flag)) {
                return {
                    level: validationErrorLevels.WARN,
                    message: "Cannot show leader during warmup or after race."
                }
            }
        }
    },
    fastestLap: {
        fn: (props) => {
            if (["None", "Warmup"].includes(props.data?.raceDetails?.flag)) {
                return {
                    level: validationErrorLevels.WARN,
                    message: "Cannot show fastest lap during warmup or after race."
                }
            }
        }
    },
    horizontalScoreboard: {
        fn: (props) => {
            if (props.data?.raceDetails?.flag === "None") {
                return {
                    level: validationErrorLevels.WARN,
                    message: "Horizontal scoreboard has no data."
                }
            }
        }
    },
    verticalScoreboard: {
        fn: (props) => {
            if (props.data?.raceDetails?.flag === "None") {
                return {
                    level: validationErrorLevels.WARN,
                    message: "Vertical scoreboard has no data."
                }
            }
        }
    }
};


export const useButtonValidator = () => {
    const data = useSelector(state => state.data?.data);
    const config = useSelector(state => state.config);
    const [validationResults, setValidationResults] = useState({});

    useEffect(() => {
        const newValidationResults = {};
        for (const buttonName in validationRules) {
            const msg = validationRules[buttonName].fn({config: config, data: data});
            if (msg) {
                newValidationResults[buttonName] = msg;
            }
        }
        setValidationResults(newValidationResults);
    }, [data, config]);

    const errors = useMemo(() => {
        const newErrors = {};
        for (const buttonName in validationResults) {
            if (validationResults[buttonName]) {
                newErrors[buttonName] = validationResults[buttonName];
            }
        }
        return newErrors;
    }, [validationResults]);

    return errors;
};