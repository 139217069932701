

import "./Marshall.scss";

import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { MdLogout } from "react-icons/md";
import io from "socket.io-client";
import { getRaceBackendAddress, flagToColor } from "../../Functions";

import Joker from "./components/Joker/Joker";

let socket;
const GRAPHICS_SERVER_ADDRESS = getRaceBackendAddress();

const Marshall = () => {

    const navigate = useNavigate();
    const userID = localStorage.getItem("userID-child");

    if (!userID || userID === undefined || userID === null) {
        navigate("/login");
    }

    if (!socket) {
        socket = io.connect(GRAPHICS_SERVER_ADDRESS, {
            query: {
                userId: userID,
                userType: "Marshall"
            }
        });
    }

    const [data, setData] = useState({});
    useEffect(() => {
        socket.on("getApi", setData);

        // Notify server when Marshall is connected
        socket.on("connect", () => {
            setTimeout(() => {
                socket.emit("update", {what: 5, data: ["isMarshallConnected", true]}, (callback) => {
                    console.log(callback);
                });
            }, 1000);

        });

        // Cleanup function
        return () => {
            socket.off("getApi", setData);
            socket.off('connect');
        };
    }, [socket]);

    function logout() {
        ["jwt-child", "userID-child"].forEach(element => {
            localStorage.removeItem(element);
        });
        navigate("/login");
    }

    return (
        <div className="app__marshall-main">
            <div className="app__marshall-topbar">
                <p>.jokerLap<span>Panel</span></p>
                <div className="app__marshall-race-info">
                    <div className="app__marshall-race-info-item">
                        <h3>Race name</h3>
                        <span>{data?.data?.raceDetails?.name}</span>
                    </div>
                    <div className="app__marshall-race-info-item">
                        <h3>Flag</h3>
                        <h2 style={{background: flagToColor(data?.data?.raceDetails?.flag)}}/>
                    </div>
                </div>
                <button onClick={logout}><MdLogout size={"50%"}/></button>
            </div>
            <div className="app__marshall-content">
                <Routes>
                    <Route path={"/Joker"} element={<Joker data={data} socket={socket}/>} />
                </Routes>
            </div>
        </div>
    );
};

export default Marshall;