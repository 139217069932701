
import "./TrackPlan.scss";
import { motion } from "framer-motion";

const TrackPlan = (props) => {
    switch(props.config?.style) {
        case "moto":
            return (
                <div
                    className="app__track-plan-container"
                    style={{
                        top: props?.config?.buttons?.trackPlan?.top,
                        left: props?.config?.buttons?.trackPlan?.left,
                    }}
                >
                    <div className="app__track-plan2-title">
                        <div className="app__track-plan2-title-inner">
                            <span>{props.config?.selectedTrack[0]?.track_name}</span>
                            <span>{props.config?.selectedTrack[0]?.track_location}</span>
                        </div>
                    </div>
                    <div className="app__track-plan2-content">
                        <div className="app__track-plan2-content-inner">
                            <div className="app__track-plan-image">
                                <img src={`/tracks/${props.config?.selectedTrack[0]?.track_image}`} alt="-" />
                            </div>
                            <div className="app__track-plan-details2">
                                <div className="app__track-plan-details-inner bg-white">
                                    <div className="app__track-plan-details-field">
                                        <span>Track surface</span>
                                        <h3>{props.config?.selectedTrack[0]?.track_surface}</h3>
                                    </div>
                                    <vr />
                                    <div className="app__track-plan-details-field">
                                        <span>Track length</span>
                                        <h3>{props.config?.selectedTrack[0]?.track_len} m</h3>
                                    </div>
                                    <vr />
                                    <div className="app__track-plan-details-field">
                                        <span>Track minimum width</span>
                                        <h3>{props.config?.selectedTrack[0]?.track_min_width} m</h3>
                                    </div>
                                    <vr />
                                    <div className="app__track-plan-details-field">
                                        <span>Track maximum width</span>
                                        <h3>{props.config?.selectedTrack[0]?.track_max_width} m</h3>
                                    </div>
                                    <vr />
                                    <div className="app__track-plan-details-field">
                                        <span>Track average lap time</span>
                                        <h3>{props.config?.selectedTrack[0]?.track_avg_lap}</h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case "race":
            return (
                <div
                    className="app__track-plan-container"
                    style={{
                        top: props?.config?.buttons?.trackPlan?.top,
                        left: props?.config?.buttons?.trackPlan?.left,
                    }}
                >
                    <div className="app__track-plan-title">
                        <div className="app__track-plan-title-inner">
                            <span>{props.config?.selectedTrack[0]?.track_name}</span>
                            <span>{props.config?.selectedTrack[0]?.track_location}</span>
                        </div>

                    </div>
                    <div className="app__track-plan-content">
                        <div className="app__track-plan-image">
                            <img src={`/tracks/${props.config?.selectedTrack[0]?.track_image}`} alt="-" />
                        </div>
                        <div className="app__track-plan-details">
                            <div className="app__track-plan-details-inner">
                                <div className="app__track-plan-details-field">
                                    <span>Track surface</span>
                                    <h3>{props.config?.selectedTrack[0]?.track_surface}</h3>
                                </div>
                                <vr />
                                <div className="app__track-plan-details-field">
                                    <span>Track length</span>
                                    <h3>{props.config?.selectedTrack[0]?.track_len} m</h3>
                                </div>
                                <vr />
                                <div className="app__track-plan-details-field">
                                    <span>Track minimum width</span>
                                    <h3>{props.config?.selectedTrack[0]?.track_min_width} m</h3>
                                </div>
                                <vr />
                                <div className="app__track-plan-details-field">
                                    <span>Track maximum width</span>
                                    <h3>{props.config?.selectedTrack[0]?.track_max_width} m</h3>
                                </div>
                                <vr />
                                <div className="app__track-plan-details-field">
                                    <span>Track average lap time</span>
                                    <h3>{props.config?.selectedTrack[0]?.track_avg_lap}</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case "v2":

        const itemMap = [
            ["Track surface", "track_surface", ""],
            ["Track length", "track_len", "m"],
            ["Track minimum width", "track_min_width", "m"],
            ["Track maximum width", "track_max_width", "m"],
            ["Track average lap time", "track_avg_lap"]
        ]

            return (
                <motion.div
                    className="app__v2-track-plan-main"
                    style={{
                        top: props?.config?.buttons?.trackPlan?.top,
                        left: props?.config?.buttons?.trackPlan?.left,
                    }}
                    initial={{opacity: 0, scale: 0}}
                    animate={{opacity: 1, scale: 1, transition: {duration: 0.01}}}
                    exit={{opacity: 0, scale: 0, transition: {duration: 0.01}}}
                >
                    <div className="app__v2-title">
                            <span>{props.config?.selectedTrack[0]?.track_name}</span>
                            <p>{props.config?.selectedTrack[0]?.track_location}</p>
                    </div>
                    <div className="app__v2-img">
                        <img src={`/tracks/${props.config?.selectedTrack[0]?.track_image}`} alt="-" />
                    </div>
                    <div className="app__v2-data">
                        {
                            itemMap.map((item, index) =>
                                <div
                                    className="app__v2-data-container"
                                    index={`${index}_track-cont`}
                                >
                                    <span>{item[0]}</span>
                                    <p>{props.config?.selectedTrack[0]?.[item[1]]}{props.config?.selectedTrack[0]?.[item[1]] ? [item[2]] : "-"}</p>
                                </div>
                            )
                        }
                    </div>
                </motion.div>
            );
    }
};
export default TrackPlan;