import React, { useRef } from 'react';

const DesignItem = React.memo(({ design, currentDesign, setSelectedDesign, index, max }) => {

    const itemRef = useRef(null);

    const scrollToNext = () => {
        const nextSibling = itemRef.current.nextSibling;
        if (nextSibling) {
            nextSibling.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const scrollToPrev = () => {
        const prevSibling = itemRef.current.previousSibling;
        if (prevSibling) {
            prevSibling.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div
            ref={itemRef}
            className="graphics-design-item"
            style={{backgroundImage: `url(/images/${design.designImage}.png`}}
            id={`design_item_${index}`}
        >
            <div className="graphics-design-item-image-darkener">
                <div className="graphics-design-item-title">
                    <p>{design.name}</p>
                </div>
                <div className="graphics-design-item-select-btn">
                    {index > 0 && <button className="page-left" onClick={scrollToPrev}>←</button>}
                    <button
                        className="graphics-design-item-select"
                        onClick={() => setSelectedDesign(design.styleName)}
                        style={{background: currentDesign === design.styleName ? "#3446eb" : "#2b364a"}}
                    >
                        {currentDesign === design.styleName ? "Your current design" : "Select this design"}
                    </button>
                    {index < max - 1 && <button className="page-right" onClick={scrollToNext}>→</button>}
                </div>
                <div className="graphics-design-item-content">
                    <div className="graphics-design-item-content-desc">
                        <span>{design.description}</span>
                    </div>
                    <div className="graphics-design-item-content-tags">
                        <span>Features</span>
                        {
                            design.tags.map((tag, index2) =>
                                <div className="graphics-design-item-content-tag" key={`design_tag_${index2}`} style={{background: tag.background, color: tag.color}}>
                                    {tag.name}
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
});

export default DesignItem;